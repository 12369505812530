import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: any;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any;
  /** The `Decimal` scalar type represents a python Decimal. */
  Decimal: any;
  /**
   * The `Time` scalar type represents a Time value as
   * specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Time: any;
  /**
   * The `Float` scalar type represents signed double-precision fractional.
   * In query it is displayed as a string.
   */
  CustomFloat: any;
  /**
   * 
   *     Errors messages and codes mapped to
   *     fields or non fields errors.
   *     Example:
   *     {
   *         field_name: [
   *             {
   *                 "message": "error message",
   *                 "code": "error_code"
   *             }
   *         ],
   *         other_field: [
   *             {
   *                 "message": "error message",
   *                 "code": "error_code"
   *             }
   *         ],
   *         nonFieldErrors: [
   *             {
   *                 "message": "error message",
   *                 "code": "error_code"
   *             }
   *         ]
   *     }
   *     
   */
  ExpectedErrorType: any;
  /**
   * Create scalar that ignores normal serialization/deserialization, since
   * that will be handled by the multipart request spec
   */
  Upload: any;
  /**
   * The `GenericScalar` scalar type represents a generic
   * GraphQL scalar value that could be:
   * String, Boolean, Int, Float, List or Object.
   */
  GenericScalar: any;
};

export type Query = {
  __typename?: 'Query';
  agreement?: Maybe<AgreementType>;
  allArticles?: Maybe<ArticlesConnection>;
  allCompanies?: Maybe<CompanyConnection>;
  allContributions?: Maybe<ContributionConnection>;
  allCountries?: Maybe<CountryConnection>;
  allCourseCategories?: Maybe<CategoryConnection>;
  allCourses?: Maybe<CourseFilterTypeConnection>;
  allCvLanguages?: Maybe<LanguageConnection>;
  allCvSocialMedias?: Maybe<SocialMediaConnection>;
  allDeferredPayments?: Maybe<DeferredPaymentConnection>;
  allGroups?: Maybe<GroupConnection>;
  allGroupsForLms?: Maybe<UnionGroupConnection>;
  allNatives?: Maybe<NativeInfoConnection>;
  allStories?: Maybe<AllStoriesConnection>;
  allStudents?: Maybe<StudentConnection>;
  allStudentsAchievement?: Maybe<StudentsAchievementConnection>;
  allSuccessStories?: Maybe<SuccessStoriesConnection>;
  allTeachers?: Maybe<TeacherFilterTypeConnection>;
  allTeachersApplications?: Maybe<ApplicationFilterTypeConnection>;
  allUsersSettings?: Maybe<UsersSettingsConnection>;
  appState: AppState;
  article?: Maybe<ArticlesType>;
  articleBySlug?: Maybe<ArticlesType>;
  authState: AuthState;
  blogAllArticles?: Maybe<BlogAllArticlesType>;
  blogGraduateStory?: Maybe<BlogGraduateStoryType>;
  blogNative?: Maybe<BlogNativeType>;
  company?: Maybe<CompanyType>;
  contactsPage?: Maybe<ContactsPageType>;
  course?: Maybe<CourseType>;
  courseBySlug?: Maybe<CourseType>;
  courseCatalogPage?: Maybe<CourseCatalogPageType>;
  coursesGraduateFeedback?: Maybe<GraduateFeedbackConnection>;
  dataProcessingPolicyPage?: Maybe<DataProcessingPolicyType>;
  demoGroupsCount?: Maybe<DemoType>;
  financeBudget?: Maybe<Array<Maybe<MonthBudget>>>;
  financeMonthBudget?: Maybe<WeeksBudgetInfo>;
  financePlan?: Maybe<Array<Maybe<MonthBudget>>>;
  financeYears?: Maybe<Array<Maybe<Scalars['Int']>>>;
  group?: Maybe<GroupType>;
  groupForLms?: Maybe<UnionGroupType>;
  groupLessonAttachment?: Maybe<LessonAttachmentsType>;
  groupLessonAttachmentForLms?: Maybe<Array<Maybe<LessonAttachmentsForLmsType>>>;
  groupLessonAttendanceForLms?: Maybe<Array<Maybe<LessonAttendancesType>>>;
  journal?: Maybe<GroupLessonConnection>;
  legalEntityPublicOfferPage?: Maybe<LegalEntityPublicOfferPageType>;
  lessonForLms?: Maybe<UnionLessonType>;
  lmsJournal?: Maybe<GroupLessonType>;
  mainPageInfo?: Maybe<MainPageInfoType>;
  mainTeachersWeb?: Maybe<MainTeacherWebType>;
  me?: Maybe<UserNode>;
  metaContributions?: Maybe<MetaContribution>;
  metaCourses?: Maybe<MetaCourses>;
  metaDeferredPayments?: Maybe<MetaDeferredPaymentConnection>;
  metaGroups?: Maybe<MetaGroups>;
  metaStudents?: Maybe<MetaStudents>;
  metaTeachers?: Maybe<MetaTeachers>;
  metaTeachersApplications?: Maybe<MetaTeacherApplications>;
  metaUsersSettings?: Maybe<MetaUsersSettings>;
  native?: Maybe<NativeInfoType>;
  nativeBySlug?: Maybe<NativeInfoType>;
  paymentRulesPage?: Maybe<PaymentRulesPageType>;
  paymentsVerification?: Maybe<Array<Maybe<PaymentsVerificationType>>>;
  privateIndividualPublicOfferPage?: Maybe<PrivateIndividualPublicOfferPageType>;
  robotTxt?: Maybe<RobotTxtType>;
  student?: Maybe<StudentType>;
  studentMarks?: Maybe<StudentsMarkConnection>;
  studentResume?: Maybe<StudentResumeType>;
  studentsCompaniesInfo?: Maybe<StudentAndCompaniesType>;
  studentsForJournal?: Maybe<StudentConnection>;
  studentsMarks?: Maybe<StudentConnection>;
  studentsReviews?: Maybe<StudentsReviewsType>;
  successStoriesBlock?: Maybe<SuccessStoriesBlockType>;
  successStory?: Maybe<SuccessStoriesType>;
  successStoryBySlug?: Maybe<SuccessStoriesType>;
  teacher?: Maybe<TeacherType>;
  teacherApplication?: Maybe<ApplicationType>;
  teachersPageInfo?: Maybe<TeachersPageInfoType>;
  user?: Maybe<UserNode>;
  users?: Maybe<UserNodeConnection>;
  verifyStudent?: Maybe<VerifiedStudent>;
  verifyTeacher?: Maybe<VerifiedTeacher>;
};


export type QueryAgreementArgs = {
  agreementId: Scalars['ID'];
};


export type QueryAllArticlesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryAllCompaniesArgs = {
  query?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryAllContributionsArgs = {
  query?: Maybe<Scalars['String']>;
  contributionNum?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryAllCountriesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryAllCourseCategoriesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryAllCoursesArgs = {
  query?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  readyToPublish?: Maybe<Scalars['Boolean']>;
  educationForm?: Maybe<Scalars['String']>;
  category_Name?: Maybe<Scalars['String']>;
  startDateFilter?: Maybe<Scalars['Boolean']>;
  orderByPublishDateFilter?: Maybe<Scalars['Boolean']>;
};


export type QueryAllCvLanguagesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryAllCvSocialMediasArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryAllDeferredPaymentsArgs = {
  query?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryAllGroupsArgs = {
  query?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryAllGroupsForLmsArgs = {
  active?: Maybe<Scalars['Boolean']>;
  finished?: Maybe<Scalars['Boolean']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryAllNativesArgs = {
  excludedNatives?: Maybe<Array<Maybe<Scalars['String']>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryAllStoriesArgs = {
  excludeArticles?: Maybe<Array<Maybe<Scalars['String']>>>;
  excludeStories?: Maybe<Array<Maybe<Scalars['String']>>>;
  excludedNatives?: Maybe<Array<Maybe<Scalars['String']>>>;
  includeNatives?: Maybe<Scalars['Boolean']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryAllStudentsArgs = {
  query?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryAllStudentsAchievementArgs = {
  query?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryAllSuccessStoriesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryAllTeachersArgs = {
  query?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  siteDisplay?: Maybe<Scalars['Boolean']>;
  hasInterview?: Maybe<Scalars['Boolean']>;
  hasPhoto?: Maybe<Scalars['Boolean']>;
  hasDescription?: Maybe<Scalars['Boolean']>;
};


export type QueryAllTeachersApplicationsArgs = {
  query?: Maybe<Scalars['String']>;
  groupsFilter?: Maybe<Scalars['Boolean']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  byGroupId?: Maybe<Scalars['Decimal']>;
  isTerminated?: Maybe<Scalars['Boolean']>;
};


export type QueryAllUsersSettingsArgs = {
  query?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryArticleArgs = {
  articleId?: Maybe<Scalars['ID']>;
};


export type QueryArticleBySlugArgs = {
  slug: Scalars['String'];
};


export type QueryCompanyArgs = {
  id: Scalars['ID'];
};


export type QueryCourseArgs = {
  courseId?: Maybe<Scalars['ID']>;
};


export type QueryCourseBySlugArgs = {
  slug: Scalars['String'];
};


export type QueryCoursesGraduateFeedbackArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryFinanceBudgetArgs = {
  year: Scalars['Int'];
};


export type QueryFinanceMonthBudgetArgs = {
  year: Scalars['Int'];
  month: Scalars['Int'];
};


export type QueryFinancePlanArgs = {
  year: Scalars['Int'];
};


export type QueryGroupArgs = {
  groupId?: Maybe<Scalars['ID']>;
};


export type QueryGroupForLmsArgs = {
  groupId?: Maybe<Scalars['ID']>;
};


export type QueryGroupLessonAttachmentArgs = {
  attachmentId?: Maybe<Scalars['ID']>;
  attachmentRole?: Maybe<Scalars['String']>;
};


export type QueryGroupLessonAttachmentForLmsArgs = {
  groupLessonId?: Maybe<Scalars['ID']>;
  attachmentRole?: Maybe<Scalars['String']>;
};


export type QueryGroupLessonAttendanceForLmsArgs = {
  groupLessonId?: Maybe<Scalars['ID']>;
};


export type QueryJournalArgs = {
  groupId: Scalars['ID'];
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryLessonForLmsArgs = {
  groupLessonId?: Maybe<Scalars['ID']>;
};


export type QueryLmsJournalArgs = {
  groupLessonId: Scalars['ID'];
};


export type QueryMetaContributionsArgs = {
  query?: Maybe<Scalars['String']>;
  contributionNum?: Maybe<Scalars['Int']>;
};


export type QueryMetaCoursesArgs = {
  query?: Maybe<Scalars['String']>;
};


export type QueryMetaDeferredPaymentsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryMetaGroupsArgs = {
  query?: Maybe<Scalars['String']>;
};


export type QueryMetaStudentsArgs = {
  query?: Maybe<Scalars['String']>;
};


export type QueryMetaTeachersArgs = {
  query?: Maybe<Scalars['String']>;
};


export type QueryMetaTeachersApplicationsArgs = {
  query?: Maybe<Scalars['String']>;
};


export type QueryMetaUsersSettingsArgs = {
  query?: Maybe<Scalars['String']>;
};


export type QueryNativeArgs = {
  nativeId?: Maybe<Scalars['ID']>;
};


export type QueryNativeBySlugArgs = {
  slug: Scalars['String'];
};


export type QueryPaymentsVerificationArgs = {
  query: Array<Maybe<Scalars['String']>>;
};


export type QueryStudentArgs = {
  id: Scalars['ID'];
};


export type QueryStudentMarksArgs = {
  lessonsId: Scalars['ID'];
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryStudentResumeArgs = {
  language?: Maybe<Scalars['String']>;
};


export type QueryStudentsForJournalArgs = {
  lessonsId: Array<Maybe<Scalars['Int']>>;
  groupId: Scalars['Int'];
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryStudentsMarksArgs = {
  lessonsId: Scalars['ID'];
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QuerySuccessStoryArgs = {
  storyId?: Maybe<Scalars['ID']>;
};


export type QuerySuccessStoryBySlugArgs = {
  slug: Scalars['String'];
};


export type QueryTeacherArgs = {
  teacherId?: Maybe<Scalars['ID']>;
};


export type QueryTeacherApplicationArgs = {
  applicationId: Scalars['ID'];
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryUsersArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  email_Icontains?: Maybe<Scalars['String']>;
  email_Istartswith?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  lastName_Icontains?: Maybe<Scalars['String']>;
  lastName_Istartswith?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  firstName_Icontains?: Maybe<Scalars['String']>;
  firstName_Istartswith?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phone_Icontains?: Maybe<Scalars['String']>;
  phone_Istartswith?: Maybe<Scalars['String']>;
};


export type QueryVerifyStudentArgs = {
  email: Scalars['String'];
  phone: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
};


export type QueryVerifyTeacherArgs = {
  teacherInput: BaseTeacherInput;
};

export type BlogAllArticlesType = {
  __typename?: 'BlogAllArticlesType';
  id: Scalars['ID'];
  seoTitle: Scalars['String'];
  seoDescription: Scalars['String'];
  seoKeywords: Scalars['String'];
  seoImage: Scalars['String'];
};

export type ArticlesType = {
  __typename?: 'ArticlesType';
  id: Scalars['ID'];
  seoTitle: Scalars['String'];
  seoDescription: Scalars['String'];
  seoKeywords: Scalars['String'];
  seoImage: Scalars['String'];
  nativeTitle?: Maybe<Scalars['String']>;
  native?: Maybe<NativeInfoType>;
  adviceTitle?: Maybe<Scalars['String']>;
  adviceText?: Maybe<Scalars['String']>;
  author: AuthorsType;
  storyTime?: Maybe<Scalars['String']>;
  date: Scalars['Date'];
  banner?: Maybe<CourseBanner>;
  previewImage?: Maybe<Scalars['String']>;
  seoSlug?: Maybe<Scalars['String']>;
  published: Scalars['Boolean'];
  title: Scalars['String'];
  articleShortText?: Maybe<Scalars['String']>;
  articleImage?: Maybe<Scalars['String']>;
  storyText?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
};

export type NativeInfoType = {
  __typename?: 'NativeInfoType';
  id: Scalars['ID'];
  seoTitle: Scalars['String'];
  seoDescription: Scalars['String'];
  seoKeywords: Scalars['String'];
  seoImage: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  image: Scalars['String'];
  interviewText: Scalars['String'];
  interviewLink: Scalars['String'];
  date: Scalars['Date'];
  seoSlug?: Maybe<Scalars['String']>;
  nativeBanner?: Maybe<CourseBanner>;
  published: Scalars['Boolean'];
  successstoriesSet: Array<SuccessStoriesType>;
  articlesSet: Array<ArticlesType>;
};


export type CourseBanner = {
  __typename?: 'CourseBanner';
  id: Scalars['ID'];
  title: Scalars['String'];
  description: Scalars['String'];
  course?: Maybe<CourseFilterType>;
  native: Array<NativeInfoType>;
  successstoriesSet: Array<SuccessStoriesType>;
  articlesSet: Array<ArticlesType>;
};

export type CourseFilterType = Node & {
  __typename?: 'CourseFilterType';
  id: Scalars['ID'];
  category?: Maybe<CategoryType>;
  name: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  courseIcon?: Maybe<Scalars['String']>;
  courseDiscount?: Maybe<Scalars['Int']>;
  duration: Scalars['String'];
  educationForm?: Maybe<Scalars['String']>;
  videoTrailer?: Maybe<VideoTrailerType>;
  futureProfession?: Maybe<FutureProfessionType>;
  futureKnowledge?: Maybe<FutureKnowledgeType>;
  salary?: Maybe<SalaryInfoType>;
  curriculumVitae?: Maybe<StudentCvType>;
  courseBanner?: Maybe<CourseBanner>;
  graduationVideo?: Maybe<GraduationVideoType>;
  graduateFeedbacks: Array<GraduateFeedbackType>;
  teacherWeb?: Maybe<TeacherWebType>;
  courseRequirementsInfo?: Maybe<CourseRequirementsIntoType>;
  ceoInfo?: Maybe<CeoInfoType>;
  readyToPublish: Scalars['Boolean'];
  publishDate: Scalars['Date'];
  educationprogram?: Maybe<EducationProgramType>;
  modules: Array<CourseModuleType>;
  marketingModules: Array<CourseMarketingModuleType>;
  prices: Array<CoursePriceType>;
  contributions: Array<CourseContributionType>;
  groups: MethodistGroupTypeConnection;
  deleteAvailable?: Maybe<Scalars['Boolean']>;
  price: Scalars['CustomFloat'];
  currency?: Maybe<Scalars['String']>;
  teachers?: Maybe<TeacherFilterTypeConnection>;
};


export type CourseFilterTypeGroupsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  freePlacesGt?: Maybe<Scalars['Decimal']>;
  hideCompleted?: Maybe<Scalars['Date']>;
};


export type CourseFilterTypeTeachersArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  siteDisplay?: Maybe<Scalars['Boolean']>;
  hasInterview?: Maybe<Scalars['Boolean']>;
  hasPhoto?: Maybe<Scalars['Boolean']>;
  hasDescription?: Maybe<Scalars['Boolean']>;
};

/** An object with an ID */
export type Node = {
  /** The ID of the object. */
  id: Scalars['ID'];
};

export type CategoryType = {
  __typename?: 'CategoryType';
  id: Scalars['ID'];
  name: Scalars['String'];
  categoryColumn?: Maybe<Scalars['String']>;
  courses: CourseFilterTypeConnection;
};


export type CategoryTypeCoursesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  readyToPublish?: Maybe<Scalars['Boolean']>;
  educationForm?: Maybe<Scalars['String']>;
  category_Name?: Maybe<Scalars['String']>;
  startDateFilter?: Maybe<Scalars['Boolean']>;
  orderByPublishDateFilter?: Maybe<Scalars['Boolean']>;
};

export type CourseFilterTypeConnection = {
  __typename?: 'CourseFilterTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CourseFilterTypeEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
};

/** A Relay edge containing a `CourseFilterType` and its cursor. */
export type CourseFilterTypeEdge = {
  __typename?: 'CourseFilterTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<CourseType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CourseType = Node & {
  __typename?: 'CourseType';
  id: Scalars['ID'];
  category?: Maybe<CategoryType>;
  name: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  courseIcon?: Maybe<Scalars['String']>;
  courseDiscount?: Maybe<Scalars['Int']>;
  duration: Scalars['String'];
  educationForm?: Maybe<Scalars['String']>;
  videoTrailer?: Maybe<VideoTrailerType>;
  futureProfession?: Maybe<FutureProfessionType>;
  futureKnowledge?: Maybe<FutureKnowledgeType>;
  salary?: Maybe<SalaryInfoType>;
  curriculumVitae?: Maybe<StudentCvType>;
  courseBanner?: Maybe<CourseBanner>;
  graduationVideo?: Maybe<GraduationVideoType>;
  graduateFeedbacks: Array<GraduateFeedbackType>;
  teacherWeb?: Maybe<TeacherWebType>;
  courseRequirementsInfo?: Maybe<CourseRequirementsIntoType>;
  ceoInfo?: Maybe<CeoInfoType>;
  readyToPublish: Scalars['Boolean'];
  publishDate: Scalars['Date'];
  educationprogram?: Maybe<EducationProgramType>;
  modules: Array<CourseModuleType>;
  marketingModules: Array<CourseMarketingModuleType>;
  prices: Array<CoursePriceType>;
  contributions: Array<CourseContributionType>;
  groups: MethodistGroupTypeConnection;
  deleteAvailable?: Maybe<Scalars['Boolean']>;
  price: Scalars['CustomFloat'];
  currency?: Maybe<Scalars['String']>;
  teachers?: Maybe<TeacherFilterTypeConnection>;
};


export type CourseTypeGroupsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  freePlacesGt?: Maybe<Scalars['Decimal']>;
  hideCompleted?: Maybe<Scalars['Date']>;
};


export type CourseTypeTeachersArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  siteDisplay?: Maybe<Scalars['Boolean']>;
  hasInterview?: Maybe<Scalars['Boolean']>;
  hasPhoto?: Maybe<Scalars['Boolean']>;
  hasDescription?: Maybe<Scalars['Boolean']>;
};

export type VideoTrailerType = {
  __typename?: 'VideoTrailerType';
  id: Scalars['ID'];
  url: Scalars['String'];
  description: Scalars['String'];
  course?: Maybe<CourseFilterType>;
};

export type FutureProfessionType = {
  __typename?: 'FutureProfessionType';
  id: Scalars['ID'];
  title: Scalars['String'];
  description: Scalars['String'];
  course?: Maybe<CourseFilterType>;
};

export type FutureKnowledgeType = {
  __typename?: 'FutureKnowledgeType';
  id: Scalars['ID'];
  title: Scalars['String'];
  descriptions: Array<KnowledgeDescriptionType>;
  course?: Maybe<CourseFilterType>;
};

export type KnowledgeDescriptionType = {
  __typename?: 'KnowledgeDescriptionType';
  id: Scalars['ID'];
  futureKnowledge: FutureKnowledgeType;
  description: Scalars['String'];
};

export type SalaryInfoType = {
  __typename?: 'SalaryInfoType';
  id: Scalars['ID'];
  title: Scalars['String'];
  dataSource: Scalars['String'];
  marketInfo: Scalars['String'];
  companyCount: Scalars['String'];
  marketDemand: Scalars['String'];
  minJuniorValue: Scalars['Int'];
  minMiddleValue: Scalars['Int'];
  minSeniorValue: Scalars['Int'];
  maxSeniorValue: Scalars['Int'];
  course?: Maybe<CourseFilterType>;
};

export type StudentCvType = {
  __typename?: 'StudentCVType';
  id: Scalars['ID'];
  title: Scalars['String'];
  studentImage: Scalars['String'];
  workingPosition: Scalars['String'];
  salary: Scalars['String'];
  certificateImage: Scalars['String'];
  professionalSkills: Array<ProfessionalSkillType>;
  course?: Maybe<CourseFilterType>;
};

export type ProfessionalSkillType = {
  __typename?: 'ProfessionalSkillType';
  id: Scalars['ID'];
  studentCv: StudentCvType;
  description: Scalars['String'];
};

export type GraduationVideoType = {
  __typename?: 'GraduationVideoType';
  id: Scalars['ID'];
  title: Scalars['String'];
  description: Scalars['String'];
  url: Scalars['String'];
  course?: Maybe<CourseFilterType>;
};

export type GraduateFeedbackType = {
  __typename?: 'GraduateFeedbackType';
  id: Scalars['ID'];
  feedback: Scalars['String'];
  graduateName: Scalars['String'];
  graduatePhoto?: Maybe<Scalars['String']>;
  date: Scalars['Date'];
  resourceIcon: Scalars['String'];
  course: CourseFilterTypeConnection;
};


export type GraduateFeedbackTypeCourseArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  readyToPublish?: Maybe<Scalars['Boolean']>;
  educationForm?: Maybe<Scalars['String']>;
  category_Name?: Maybe<Scalars['String']>;
  startDateFilter?: Maybe<Scalars['Boolean']>;
  orderByPublishDateFilter?: Maybe<Scalars['Boolean']>;
};

export type TeacherWebType = Node & {
  __typename?: 'TeacherWebType';
  id: Scalars['ID'];
  title: Scalars['String'];
  description: Scalars['String'];
  teachersPreview: TeacherFilterTypeConnection;
  course?: Maybe<CourseFilterType>;
};


export type TeacherWebTypeTeachersPreviewArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  siteDisplay?: Maybe<Scalars['Boolean']>;
  hasInterview?: Maybe<Scalars['Boolean']>;
  hasPhoto?: Maybe<Scalars['Boolean']>;
  hasDescription?: Maybe<Scalars['Boolean']>;
};

export type TeacherFilterTypeConnection = {
  __typename?: 'TeacherFilterTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TeacherFilterTypeEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `TeacherFilterType` and its cursor. */
export type TeacherFilterTypeEdge = {
  __typename?: 'TeacherFilterTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<TeacherType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type TeacherType = Node & {
  __typename?: 'TeacherType';
  id: Scalars['ID'];
  user: CustomUserType;
  siteDisplay: Scalars['Boolean'];
  interviewTitle?: Maybe<Scalars['String']>;
  interview?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  accessToLms: Scalars['Boolean'];
  teacher: TeacherWebTypeConnection;
  applications?: Maybe<ApplicationConnection>;
  mainTeachers: MainTeacherWebTypeConnection;
  fullName?: Maybe<Scalars['String']>;
  deleteAvailable?: Maybe<Scalars['Boolean']>;
  isDismissed?: Maybe<Scalars['Boolean']>;
  groupsCount?: Maybe<Scalars['Int']>;
  employmentTypes?: Maybe<Array<Maybe<EmploymentType>>>;
  courses?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type TeacherTypeTeacherArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type TeacherTypeApplicationsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type TeacherTypeMainTeachersArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CustomUserType = Node & {
  __typename?: 'CustomUserType';
  id: Scalars['ID'];
  lastLogin?: Maybe<Scalars['DateTime']>;
  /** Wymagana. 150 lub mniej znaków. Jedynie litery, cyfry i @/./+/-/_. */
  username: Scalars['String'];
  /** Określa czy użytkownik może zalogować się do panelu administracyjnego. */
  isStaff: Scalars['Boolean'];
  /** Określa czy użytkownika należy uważać za aktywnego. Odznacz zamiast usuwać konto. */
  isActive: Scalars['Boolean'];
  dateJoined: Scalars['DateTime'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  roles: Array<Scalars['String']>;
  teacher?: Maybe<TeacherFilterType>;
  student?: Maybe<StudentType>;
  /** Oznacza, że ten użytkownik ma wszystkie uprawnienia bez ich jawnego przypisywania. */
  isSuperuser: Scalars['Boolean'];
};


export type TeacherFilterType = Node & {
  __typename?: 'TeacherFilterType';
  id: Scalars['ID'];
  user: CustomUserType;
  siteDisplay: Scalars['Boolean'];
  interviewTitle?: Maybe<Scalars['String']>;
  interview?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  accessToLms: Scalars['Boolean'];
  teacher: TeacherWebTypeConnection;
  applications?: Maybe<ApplicationConnection>;
  mainTeachers: MainTeacherWebTypeConnection;
  individualentrepreneur?: Maybe<IndividualEntrepreneurType>;
  selfemployed?: Maybe<SelfEmployedType>;
  naturalperson?: Maybe<NaturalPersonType>;
  legalentity?: Maybe<LegalEntityType>;
  fullName?: Maybe<Scalars['String']>;
  deleteAvailable?: Maybe<Scalars['Boolean']>;
  isDismissed?: Maybe<Scalars['Boolean']>;
  groupsCount?: Maybe<Scalars['Int']>;
  employmentTypes?: Maybe<Array<Maybe<EmploymentType>>>;
  courses?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type TeacherFilterTypeTeacherArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type TeacherFilterTypeApplicationsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type TeacherFilterTypeMainTeachersArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type TeacherWebTypeConnection = {
  __typename?: 'TeacherWebTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TeacherWebTypeEdge>>;
};

/** A Relay edge containing a `TeacherWebType` and its cursor. */
export type TeacherWebTypeEdge = {
  __typename?: 'TeacherWebTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<TeacherWebType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type ApplicationConnection = {
  __typename?: 'ApplicationConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ApplicationEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `Application` and its cursor. */
export type ApplicationEdge = {
  __typename?: 'ApplicationEdge';
  /** The item at the end of the edge */
  node?: Maybe<ApplicationType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type ApplicationType = Node & {
  __typename?: 'ApplicationType';
  id: Scalars['ID'];
  teacher: TeacherFilterType;
  title: Scalars['String'];
  date: Scalars['Date'];
  group: MethodistGroupType;
  lessonsCount: Scalars['Int'];
  country?: Maybe<CountryType>;
  servicePrice?: Maybe<Scalars['Float']>;
  riaPrice?: Maybe<Scalars['Float']>;
  currency: ApplicationCurrency;
  comment?: Maybe<Scalars['String']>;
  isTerminated: Scalars['Boolean'];
  lastLessonDate?: Maybe<Scalars['Date']>;
  lessons: GroupLessonTypeConnection;
  employmentType?: Maybe<EmploymentType>;
  isCompleted?: Maybe<Scalars['Boolean']>;
  appointedLessonsCount: Scalars['Int'];
  deleteAvailable?: Maybe<ApplicationDeleteAvailable>;
};


export type ApplicationTypeLessonsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
};

export type MethodistGroupType = Node & {
  __typename?: 'MethodistGroupType';
  id: Scalars['ID'];
  name: Scalars['String'];
  startDate: Scalars['Date'];
  maxStudentsCount: Scalars['Int'];
  course: CourseFilterType;
  publishedLms: Scalars['Boolean'];
  publishedLmsForTeacher: Scalars['Boolean'];
  publishedLmsForMethodist: Scalars['Boolean'];
  comment?: Maybe<Scalars['String']>;
  isFree: Scalars['Boolean'];
  teacherLeads: ApplicationFilterTypeConnection;
  modules: Array<GroupModuleType>;
  classesDays: Array<DayOfClassType>;
  agreements: Array<AgreementType>;
  lessons?: Maybe<Array<GroupLessonType>>;
  studentsCount?: Maybe<Scalars['Int']>;
  lessonsCount?: Maybe<Scalars['Int']>;
  passedLessonsCount?: Maybe<Scalars['Int']>;
  freeLessonsCount?: Maybe<Scalars['Int']>;
  statusFinished?: Maybe<Scalars['Boolean']>;
  datetimeNextLesson?: Maybe<Scalars['DateTime']>;
  deleteAvailable?: Maybe<Scalars['Boolean']>;
  teachers?: Maybe<Array<Maybe<TeacherType>>>;
  freePlaces?: Maybe<Scalars['Int']>;
  daysOfClass: Array<Scalars['Int']>;
  price?: Maybe<Scalars['CustomFloat']>;
  currency?: Maybe<Scalars['String']>;
  contributionsSum?: Maybe<Scalars['CustomFloat']>;
};


export type MethodistGroupTypeTeacherLeadsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  byGroupId?: Maybe<Scalars['Decimal']>;
  isTerminated?: Maybe<Scalars['Boolean']>;
};

export type ApplicationFilterTypeConnection = {
  __typename?: 'ApplicationFilterTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ApplicationFilterTypeEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `ApplicationFilterType` and its cursor. */
export type ApplicationFilterTypeEdge = {
  __typename?: 'ApplicationFilterTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<ApplicationType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};


export type GroupModuleType = {
  __typename?: 'GroupModuleType';
  id: Scalars['ID'];
  name: Scalars['String'];
  group: MethodistGroupType;
  lessons: GroupLessonTypeConnection;
};


export type GroupModuleTypeLessonsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
};

export type GroupLessonTypeConnection = {
  __typename?: 'GroupLessonTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GroupLessonTypeEdge>>;
};

/** A Relay edge containing a `GroupLessonType` and its cursor. */
export type GroupLessonTypeEdge = {
  __typename?: 'GroupLessonTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<GroupLessonType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GroupLessonType = Node & {
  __typename?: 'GroupLessonType';
  id: Scalars['ID'];
  name: Scalars['String'];
  taskTypes: Array<Scalars['String']>;
  module: GroupModuleType;
  teacherApplication?: Maybe<ApplicationType>;
  number: Scalars['Int'];
  date: Scalars['Date'];
  startTime: Scalars['Time'];
  endTime: Scalars['Time'];
  lmsDescription?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
  finished: Scalars['Boolean'];
  additional?: Maybe<Scalars['Boolean']>;
  students: StudentTypeConnection;
  marks: StudentsMarkTypeConnection;
  studentsAttachments: StudentLessonAttachmentTypeConnection;
  teachersAttachments: TeacherLessonAttachmentTypeConnection;
  attendances: AttendanceTypeConnection;
  index?: Maybe<Scalars['Int']>;
  lessonNumber?: Maybe<Scalars['Int']>;
  needToFinish?: Maybe<Scalars['Boolean']>;
  teacher?: Maybe<TeacherType>;
  hasStudentMarks?: Maybe<Scalars['Boolean']>;
};


export type GroupLessonTypeStudentsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type GroupLessonTypeMarksArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  taskType?: Maybe<Scalars['String']>;
  lessonId?: Maybe<Scalars['String']>;
};


export type GroupLessonTypeStudentsAttachmentsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type GroupLessonTypeTeachersAttachmentsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type GroupLessonTypeAttendancesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type StudentTypeConnection = {
  __typename?: 'StudentTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<StudentTypeEdge>>;
};

/** A Relay edge containing a `StudentType` and its cursor. */
export type StudentTypeEdge = {
  __typename?: 'StudentTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<StudentType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type StudentType = Node & {
  __typename?: 'StudentType';
  id: Scalars['ID'];
  user?: Maybe<CustomUserType>;
  crmLink?: Maybe<Scalars['String']>;
  lesson: GroupLessonTypeConnection;
  updatedAt: Scalars['DateTime'];
  agreements: Array<AgreementType>;
  attendances: AttendanceTypeConnection;
  marks: StudentsMarkTypeConnection;
  resumes: Array<StudentResumeType>;
  agreementTitle?: Maybe<AgreementType>;
  accountsPayables?: Maybe<Array<Maybe<AccountsPayableType>>>;
  agreementIsActive?: Maybe<Scalars['Boolean']>;
  groupsCount?: Maybe<Scalars['Int']>;
};


export type StudentTypeLessonArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
};


export type StudentTypeAttendancesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type StudentTypeMarksArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  taskType?: Maybe<Scalars['String']>;
  lessonId?: Maybe<Scalars['String']>;
};

export type AgreementType = {
  __typename?: 'AgreementType';
  id: Scalars['ID'];
  agreementTitle: Scalars['String'];
  currency: AgreementCurrency;
  student: StudentType;
  group: MethodistGroupType;
  country?: Maybe<CountryType>;
  partialAttendance: Scalars['Boolean'];
  firstLesson?: Maybe<GroupLessonType>;
  lastLesson?: Maybe<GroupLessonType>;
  terminated: Scalars['Boolean'];
  paymentByCompany: Scalars['Boolean'];
  resident: Scalars['Boolean'];
  vat: Scalars['Boolean'];
  company?: Maybe<CompanyType>;
  date: Scalars['Date'];
  terminatedDate?: Maybe<Scalars['Date']>;
  promocode?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  contributions: ContributionTypeConnection;
  payments?: Maybe<Array<Maybe<PaymentType>>>;
  chargeback?: Maybe<ChargebackType>;
  accountsPayable: Array<AccountsPayableType>;
  startAccountsPayable?: Maybe<AccountsPayableType>;
  budgetAmount?: Maybe<Scalars['CustomFloat']>;
  paidAmount?: Maybe<Scalars['CustomFloat']>;
  attendancesInfo?: Maybe<Scalars['Float']>;
  courseWorksAverage?: Maybe<Scalars['Float']>;
  hwSummary?: Maybe<Scalars['Float']>;
  graduationProjectMark?: Maybe<Scalars['Float']>;
};


export type AgreementTypeContributionsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['Date']>;
  orderByDateFilter?: Maybe<Scalars['Boolean']>;
};

/** An enumeration. */
export enum AgreementCurrency {
  /** USD */
  Usd = 'USD',
  /** BYN */
  Byn = 'BYN',
  /** RUB */
  Rub = 'RUB',
  /** EUR */
  Eur = 'EUR',
  /** PLN */
  Pln = 'PLN'
}

export type CountryType = {
  __typename?: 'CountryType';
  id: Scalars['ID'];
  title: Scalars['String'];
  agreements: Array<AgreementType>;
};

export type CompanyType = Node & {
  __typename?: 'CompanyType';
  id: Scalars['ID'];
  name: Scalars['String'];
  responsiblePerson: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  registrationNumber: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
  agreements: Array<AgreementType>;
};

export type ContributionTypeConnection = {
  __typename?: 'ContributionTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ContributionTypeEdge>>;
};

/** A Relay edge containing a `ContributionType` and its cursor. */
export type ContributionTypeEdge = {
  __typename?: 'ContributionTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<ContributionType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type ContributionType = Node & {
  __typename?: 'ContributionType';
  id: Scalars['ID'];
  agreement: AgreementType;
  date: Scalars['Date'];
  amountForeign?: Maybe<Scalars['CustomFloat']>;
  amountByn?: Maybe<Scalars['CustomFloat']>;
  complete: Scalars['Boolean'];
  remainder?: Maybe<Scalars['CustomFloat']>;
  group?: Maybe<GroupsType>;
  delay?: Maybe<Scalars['Int']>;
  agreementTitle?: Maybe<Scalars['String']>;
  person?: Maybe<Person>;
};


export type GroupsType = {
  __typename?: 'GroupsType';
  id: Scalars['ID'];
  name: Scalars['String'];
  startDate: Scalars['Date'];
  maxStudentsCount: Scalars['Int'];
  course: CourseFilterType;
  publishedLms: Scalars['Boolean'];
  publishedLmsForTeacher: Scalars['Boolean'];
  publishedLmsForMethodist: Scalars['Boolean'];
  comment?: Maybe<Scalars['String']>;
  isFree: Scalars['Boolean'];
  teacherLeads: ApplicationFilterTypeConnection;
  modules: Array<GroupModuleType>;
  classesDays: Array<DayOfClassType>;
  agreements: Array<AgreementType>;
};


export type GroupsTypeTeacherLeadsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  byGroupId?: Maybe<Scalars['Decimal']>;
  isTerminated?: Maybe<Scalars['Boolean']>;
};

export type DayOfClassType = {
  __typename?: 'DayOfClassType';
  id: Scalars['ID'];
  group: MethodistGroupType;
  weekDay: Scalars['Int'];
  startTime: Scalars['Time'];
  endTime: Scalars['Time'];
};

export type Person = {
  __typename?: 'Person';
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
};

export type PaymentType = {
  __typename?: 'PaymentType';
  id: Scalars['ID'];
  date: Scalars['Date'];
  amountByn?: Maybe<Scalars['CustomFloat']>;
  amountForeign?: Maybe<Scalars['CustomFloat']>;
  currency: PaymentCurrency;
  agreement: AgreementType;
  amountAgreement: Scalars['Float'];
};

/** An enumeration. */
export enum PaymentCurrency {
  /** USD */
  Usd = 'USD',
  /** BYN */
  Byn = 'BYN',
  /** RUB */
  Rub = 'RUB',
  /** EUR */
  Eur = 'EUR',
  /** PLN */
  Pln = 'PLN'
}

export type ChargebackType = {
  __typename?: 'ChargebackType';
  id: Scalars['ID'];
  date: Scalars['Date'];
  amountByn?: Maybe<Scalars['CustomFloat']>;
  amountForeign?: Maybe<Scalars['CustomFloat']>;
  currency: ChargebackCurrency;
  agreement: AgreementType;
};

/** An enumeration. */
export enum ChargebackCurrency {
  /** USD */
  Usd = 'USD',
  /** BYN */
  Byn = 'BYN',
  /** RUB */
  Rub = 'RUB',
  /** EUR */
  Eur = 'EUR',
  /** PLN */
  Pln = 'PLN'
}

export type AccountsPayableType = {
  __typename?: 'AccountsPayableType';
  id: Scalars['ID'];
  date: Scalars['Date'];
  amountByn?: Maybe<Scalars['CustomFloat']>;
  amountForeign?: Maybe<Scalars['CustomFloat']>;
  currency: AccountsPayableCurrency;
  agreement?: Maybe<AgreementType>;
  startedAgreement: AgreementType;
  repaid: Scalars['Boolean'];
};

/** An enumeration. */
export enum AccountsPayableCurrency {
  /** USD */
  Usd = 'USD',
  /** BYN */
  Byn = 'BYN',
  /** RUB */
  Rub = 'RUB',
  /** EUR */
  Eur = 'EUR',
  /** PLN */
  Pln = 'PLN'
}

export type AttendanceTypeConnection = {
  __typename?: 'AttendanceTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AttendanceTypeEdge>>;
};

/** A Relay edge containing a `AttendanceType` and its cursor. */
export type AttendanceTypeEdge = {
  __typename?: 'AttendanceTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<AttendanceType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type AttendanceType = Node & {
  __typename?: 'AttendanceType';
  id: Scalars['ID'];
  lesson: GroupLessonType;
  student: StudentType;
  attendance?: Maybe<Scalars['Boolean']>;
};

export type StudentsMarkTypeConnection = {
  __typename?: 'StudentsMarkTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<StudentsMarkTypeEdge>>;
};

/** A Relay edge containing a `StudentsMarkType` and its cursor. */
export type StudentsMarkTypeEdge = {
  __typename?: 'StudentsMarkTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<StudentsMarkType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type StudentsMarkType = Node & {
  __typename?: 'StudentsMarkType';
  id: Scalars['ID'];
  lesson: GroupLessonType;
  student: StudentType;
  taskType?: Maybe<StudentsMarkTaskType>;
  date: Scalars['Date'];
  /** Od 1 do 10 */
  mark?: Maybe<Scalars['Int']>;
  hwMark?: Maybe<StudentsMarkHwMark>;
};

/** An enumeration. */
export enum StudentsMarkTaskType {
  /** Домашнее задание */
  Homework = 'HOMEWORK',
  /** Курсовая работа */
  Coursework = 'COURSEWORK',
  /** Дипломный проект */
  GraduationProject = 'GRADUATION_PROJECT'
}

/** An enumeration. */
export enum StudentsMarkHwMark {
  /** Выполнено */
  Done = 'DONE',
  /** Содержит ошибки */
  ContainsErrors = 'CONTAINS_ERRORS',
  /** Не выполнено */
  NotDone = 'NOT_DONE'
}

export type StudentResumeType = {
  __typename?: 'StudentResumeType';
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  student: StudentType;
  jobTitle?: Maybe<Scalars['String']>;
  additionalInfo?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  language: StudentCvLanguage;
  tools: Array<ToolsInCvType>;
  skills: Array<SkillsInCvType>;
  cvMedias: Array<SocialMediaInCvType>;
  educations: Array<EducationInCvType>;
  cvExperience: Array<WorkExperienceType>;
  cvLanguages: Array<LanguagesInCvType>;
};

/** An enumeration. */
export enum StudentCvLanguage {
  /** Rus */
  Rus = 'RUS',
  /** Eng */
  Eng = 'ENG'
}

export type ToolsInCvType = {
  __typename?: 'ToolsInCVType';
  id: Scalars['ID'];
  toolsName: Scalars['String'];
  student: StudentResumeType;
};

export type SkillsInCvType = {
  __typename?: 'SkillsInCVType';
  id: Scalars['ID'];
  skillName: Scalars['String'];
  student: StudentResumeType;
};

export type SocialMediaInCvType = {
  __typename?: 'SocialMediaInCVType';
  id: Scalars['ID'];
  socialLink: Scalars['String'];
  student: StudentResumeType;
  media?: Maybe<SocialMediaType>;
};

export type SocialMediaType = {
  __typename?: 'SocialMediaType';
  id: Scalars['ID'];
  mediaName: Scalars['String'];
  medias: Array<SocialMediaInCvType>;
};

export type EducationInCvType = {
  __typename?: 'EducationInCVType';
  id: Scalars['ID'];
  edInstitution?: Maybe<Scalars['String']>;
  degree?: Maybe<Scalars['String']>;
  specialization?: Maybe<Scalars['String']>;
  startDateYear?: Maybe<Scalars['Int']>;
  startDateMonth?: Maybe<Scalars['String']>;
  endDateYear?: Maybe<Scalars['Int']>;
  endDateMonth?: Maybe<Scalars['String']>;
  studentCv: StudentResumeType;
};

export type WorkExperienceType = {
  __typename?: 'WorkExperienceType';
  id: Scalars['ID'];
  studentCv: StudentResumeType;
  company?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  startDateYear?: Maybe<Scalars['Int']>;
  startDateMonth?: Maybe<Scalars['String']>;
  endDateYear?: Maybe<Scalars['Int']>;
  endDateMonth?: Maybe<Scalars['String']>;
  projects: Array<ProjectType>;
};

export type ProjectType = {
  __typename?: 'ProjectType';
  id: Scalars['ID'];
  projectName: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  experience: WorkExperienceType;
  technologies?: Maybe<Array<Scalars['String']>>;
};

export type LanguagesInCvType = {
  __typename?: 'LanguagesInCVType';
  id: Scalars['ID'];
  studentCv: StudentResumeType;
  languageLevel: LanguagesInCvLanguageLevel;
  language: LanguageType;
};

/** An enumeration. */
export enum LanguagesInCvLanguageLevel {
  /** A1 */
  A1Elementary = 'A1_ELEMENTARY',
  /** A2 */
  A2PreIntermediate = 'A2_PRE_INTERMEDIATE',
  /** B1 */
  B1Intermediate = 'B1_INTERMEDIATE',
  /** B2 */
  B2UpperIntermediate = 'B2_UPPER_INTERMEDIATE',
  /** C1 */
  C1Advanced = 'C1_ADVANCED',
  /** C2 */
  C2Proficiency = 'C2_PROFICIENCY',
  /** Native */
  Native = 'NATIVE'
}

export type LanguageType = {
  __typename?: 'LanguageType';
  id: Scalars['ID'];
  language: Scalars['String'];
  languagesincvSet: Array<LanguagesInCvType>;
};

export type StudentLessonAttachmentTypeConnection = {
  __typename?: 'StudentLessonAttachmentTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<StudentLessonAttachmentTypeEdge>>;
};

/** A Relay edge containing a `StudentLessonAttachmentType` and its cursor. */
export type StudentLessonAttachmentTypeEdge = {
  __typename?: 'StudentLessonAttachmentTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<StudentLessonAttachmentType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type StudentLessonAttachmentType = Node & {
  __typename?: 'StudentLessonAttachmentType';
  id: Scalars['ID'];
  title: Scalars['String'];
  file: Scalars['String'];
  lesson?: Maybe<GroupLessonType>;
};

export type TeacherLessonAttachmentTypeConnection = {
  __typename?: 'TeacherLessonAttachmentTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TeacherLessonAttachmentTypeEdge>>;
};

/** A Relay edge containing a `TeacherLessonAttachmentType` and its cursor. */
export type TeacherLessonAttachmentTypeEdge = {
  __typename?: 'TeacherLessonAttachmentTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<TeacherLessonAttachmentType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type TeacherLessonAttachmentType = Node & {
  __typename?: 'TeacherLessonAttachmentType';
  id: Scalars['ID'];
  title: Scalars['String'];
  file: Scalars['String'];
  lesson?: Maybe<GroupLessonType>;
};

/** An enumeration. */
export enum ApplicationCurrency {
  /** USD */
  Usd = 'USD',
  /** BYN */
  Byn = 'BYN',
  /** RUB */
  Rub = 'RUB',
  /** EUR */
  Eur = 'EUR',
  /** PLN */
  Pln = 'PLN'
}

export type EmploymentType = IndividualEntrepreneurType | LegalEntityType | SelfEmployedType | NaturalPersonType;

export type IndividualEntrepreneurType = Node & {
  __typename?: 'IndividualEntrepreneurType';
  id: Scalars['ID'];
  registrationAddress: Scalars['String'];
  accountNumber: Scalars['String'];
  bankTitle: Scalars['String'];
  bic: Scalars['String'];
  inn: Scalars['String'];
};

export type LegalEntityType = Node & {
  __typename?: 'LegalEntityType';
  id: Scalars['ID'];
  registrationAddress: Scalars['String'];
  accountNumber: Scalars['String'];
  bankTitle: Scalars['String'];
  bic: Scalars['String'];
  tittle: Scalars['String'];
  inn: Scalars['String'];
};

export type SelfEmployedType = Node & {
  __typename?: 'SelfEmployedType';
  id: Scalars['ID'];
  registrationAddress: Scalars['String'];
  accountNumber: Scalars['String'];
  bankTitle: Scalars['String'];
  bic: Scalars['String'];
  nationality: CountryType;
  passportNumber: Scalars['String'];
  inn: Scalars['String'];
};

export type NaturalPersonType = Node & {
  __typename?: 'NaturalPersonType';
  id: Scalars['ID'];
  registrationAddress: Scalars['String'];
  accountNumber: Scalars['String'];
  bankTitle: Scalars['String'];
  bic: Scalars['String'];
  nationality: CountryType;
  passportNumber: Scalars['String'];
};

/** An enumeration. */
export enum ApplicationDeleteAvailable {
  DeleteAvailable = 'delete_available',
  DeleteNotAvailable = 'delete_not_available',
  DeleteWithTeacher = 'delete_with_teacher'
}

export type MainTeacherWebTypeConnection = {
  __typename?: 'MainTeacherWebTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MainTeacherWebTypeEdge>>;
};

/** A Relay edge containing a `MainTeacherWebType` and its cursor. */
export type MainTeacherWebTypeEdge = {
  __typename?: 'MainTeacherWebTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<MainTeacherWebType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type MainTeacherWebType = Node & {
  __typename?: 'MainTeacherWebType';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  mainTeachers: TeacherFilterTypeConnection;
};


export type MainTeacherWebTypeMainTeachersArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  siteDisplay?: Maybe<Scalars['Boolean']>;
  hasInterview?: Maybe<Scalars['Boolean']>;
  hasPhoto?: Maybe<Scalars['Boolean']>;
  hasDescription?: Maybe<Scalars['Boolean']>;
};

export type CourseRequirementsIntoType = {
  __typename?: 'CourseRequirementsIntoType';
  id: Scalars['ID'];
  title: Scalars['String'];
  description: Scalars['String'];
  courseRequirements: Array<RequirementsType>;
  course?: Maybe<CourseFilterType>;
};

export type RequirementsType = {
  __typename?: 'RequirementsType';
  id: Scalars['ID'];
  title: Scalars['String'];
  description: Scalars['String'];
  courseRequirements: CourseRequirementsIntoType;
};

export type CeoInfoType = {
  __typename?: 'CEOInfoType';
  id: Scalars['ID'];
  title: Scalars['String'];
  description: Scalars['String'];
  keywords: Scalars['String'];
  image: Scalars['String'];
  course?: Maybe<CourseFilterType>;
};

export type EducationProgramType = {
  __typename?: 'EducationProgramType';
  id: Scalars['ID'];
  title: Scalars['String'];
  numberOfHours: Scalars['String'];
  description: Scalars['String'];
  course: CourseFilterType;
  lessons: CourseLessonTypeConnection;
};


export type EducationProgramTypeLessonsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};

export type CourseLessonTypeConnection = {
  __typename?: 'CourseLessonTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CourseLessonTypeEdge>>;
};

/** A Relay edge containing a `CourseLessonType` and its cursor. */
export type CourseLessonTypeEdge = {
  __typename?: 'CourseLessonTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<CourseLessonType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CourseLessonType = Node & {
  __typename?: 'CourseLessonType';
  id: Scalars['ID'];
  name: Scalars['String'];
  taskTypes: Array<Scalars['String']>;
  module: CourseModuleType;
  number: Scalars['Int'];
  marketingModule?: Maybe<CourseMarketingModuleType>;
  description?: Maybe<Scalars['String']>;
  lmsDescription: Scalars['String'];
  courseProgram?: Maybe<EducationProgramType>;
  studentAttachments: StudentLessonAttachmentsTypeConnection;
  teacherAttachments: TeacherLessonAttachmentsTypeConnection;
  index?: Maybe<Scalars['Int']>;
};


export type CourseLessonTypeStudentAttachmentsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type CourseLessonTypeTeacherAttachmentsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CourseModuleType = {
  __typename?: 'CourseModuleType';
  id: Scalars['ID'];
  name: Scalars['String'];
  course: CourseFilterType;
  number: Scalars['Int'];
  lessons: CourseLessonTypeConnection;
};


export type CourseModuleTypeLessonsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};

export type CourseMarketingModuleType = {
  __typename?: 'CourseMarketingModuleType';
  id: Scalars['ID'];
  name: Scalars['String'];
  course: CourseFilterType;
  description: Scalars['String'];
  lessons: CourseLessonTypeConnection;
};


export type CourseMarketingModuleTypeLessonsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};

export type StudentLessonAttachmentsTypeConnection = {
  __typename?: 'StudentLessonAttachmentsTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<StudentLessonAttachmentsTypeEdge>>;
};

/** A Relay edge containing a `StudentLessonAttachmentsType` and its cursor. */
export type StudentLessonAttachmentsTypeEdge = {
  __typename?: 'StudentLessonAttachmentsTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<StudentLessonAttachmentsType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type StudentLessonAttachmentsType = Node & {
  __typename?: 'StudentLessonAttachmentsType';
  id: Scalars['ID'];
  title: Scalars['String'];
  file: Scalars['String'];
  courseLesson?: Maybe<CourseLessonType>;
};

export type TeacherLessonAttachmentsTypeConnection = {
  __typename?: 'TeacherLessonAttachmentsTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TeacherLessonAttachmentsTypeEdge>>;
};

/** A Relay edge containing a `TeacherLessonAttachmentsType` and its cursor. */
export type TeacherLessonAttachmentsTypeEdge = {
  __typename?: 'TeacherLessonAttachmentsTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<TeacherLessonAttachmentsType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type TeacherLessonAttachmentsType = Node & {
  __typename?: 'TeacherLessonAttachmentsType';
  id: Scalars['ID'];
  title: Scalars['String'];
  file: Scalars['String'];
  courseLesson?: Maybe<CourseLessonType>;
};

export type CoursePriceType = {
  __typename?: 'CoursePriceType';
  id: Scalars['ID'];
  amount: Scalars['Float'];
  currency?: Maybe<CurrencyType>;
  course: CourseFilterType;
  currencyTicker: Scalars['String'];
};

export type CurrencyType = {
  __typename?: 'CurrencyType';
  id: Scalars['ID'];
  ticker: Scalars['String'];
  coursepriceSet: Array<CoursePriceType>;
  coursecontributionpriceSet: Array<CourseContributionPriceType>;
};

export type CourseContributionPriceType = {
  __typename?: 'CourseContributionPriceType';
  id: Scalars['ID'];
  amount: Scalars['Float'];
  currency?: Maybe<CurrencyType>;
  contribution: CourseContributionType;
  currencyTicker: Scalars['String'];
};

export type CourseContributionType = {
  __typename?: 'CourseContributionType';
  id: Scalars['ID'];
  course: CourseFilterType;
  description: Scalars['String'];
  prices: Array<CourseContributionPriceType>;
};

export type MethodistGroupTypeConnection = {
  __typename?: 'MethodistGroupTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MethodistGroupTypeEdge>>;
};

/** A Relay edge containing a `MethodistGroupType` and its cursor. */
export type MethodistGroupTypeEdge = {
  __typename?: 'MethodistGroupTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<MethodistGroupType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type SuccessStoriesType = {
  __typename?: 'SuccessStoriesType';
  id: Scalars['ID'];
  seoTitle: Scalars['String'];
  seoDescription: Scalars['String'];
  seoKeywords: Scalars['String'];
  seoImage: Scalars['String'];
  nativeTitle?: Maybe<Scalars['String']>;
  native?: Maybe<NativeInfoType>;
  adviceTitle?: Maybe<Scalars['String']>;
  adviceText?: Maybe<Scalars['String']>;
  author: AuthorsType;
  storyTime?: Maybe<Scalars['String']>;
  date: Scalars['Date'];
  banner?: Maybe<CourseBanner>;
  previewImage?: Maybe<Scalars['String']>;
  seoSlug?: Maybe<Scalars['String']>;
  published: Scalars['Boolean'];
  title: Scalars['String'];
  studentName?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  interview?: Maybe<Scalars['String']>;
  interviewText?: Maybe<Scalars['String']>;
  storyShortText?: Maybe<Scalars['String']>;
  storyText?: Maybe<Scalars['String']>;
  storyImage?: Maybe<Scalars['String']>;
  studentImage?: Maybe<Scalars['String']>;
  thesis: Array<ThesesType>;
  storyBlock: Array<SuccessStoriesBlockType>;
};

export type AuthorsType = {
  __typename?: 'AuthorsType';
  id: Scalars['ID'];
  authorName: Scalars['String'];
  authorPosition: Scalars['String'];
  authorImage: Scalars['String'];
  successstoriesSet: Array<SuccessStoriesType>;
  articlesSet: Array<ArticlesType>;
};

export type ThesesType = {
  __typename?: 'ThesesType';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  successStory?: Maybe<SuccessStoriesType>;
};

export type SuccessStoriesBlockType = {
  __typename?: 'SuccessStoriesBlockType';
  id: Scalars['ID'];
  title: Scalars['String'];
  stories: Array<SuccessStoriesType>;
};

export type ArticlesConnection = {
  __typename?: 'ArticlesConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ArticlesEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `Articles` and its cursor. */
export type ArticlesEdge = {
  __typename?: 'ArticlesEdge';
  /** The item at the end of the edge */
  node?: Maybe<ArticlesType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type SuccessStoriesConnection = {
  __typename?: 'SuccessStoriesConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SuccessStoriesEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `SuccessStories` and its cursor. */
export type SuccessStoriesEdge = {
  __typename?: 'SuccessStoriesEdge';
  /** The item at the end of the edge */
  node?: Maybe<SuccessStoriesType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type BlogNativeType = {
  __typename?: 'BlogNativeType';
  id: Scalars['ID'];
  seoTitle: Scalars['String'];
  seoDescription: Scalars['String'];
  seoKeywords: Scalars['String'];
  seoImage: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
};

export type NativeInfoConnection = {
  __typename?: 'NativeInfoConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<NativeInfoEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `NativeInfo` and its cursor. */
export type NativeInfoEdge = {
  __typename?: 'NativeInfoEdge';
  /** The item at the end of the edge */
  node?: Maybe<NativeInfoType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CourseCatalogPageType = {
  __typename?: 'CourseCatalogPageType';
  id: Scalars['ID'];
  seoTitle: Scalars['String'];
  seoDescription: Scalars['String'];
  seoKeywords: Scalars['String'];
  seoImage: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
};

export type StudentAndCompaniesType = {
  __typename?: 'StudentAndCompaniesType';
  id: Scalars['ID'];
  studentsCount: Scalars['Int'];
  studentsDescription: Scalars['String'];
  companiesDescription: Scalars['String'];
  companyImages: Array<CompanyImageType>;
};

export type CompanyImageType = {
  __typename?: 'CompanyImageType';
  id: Scalars['ID'];
  studentAndCompanies: StudentAndCompaniesType;
  image: Scalars['String'];
};

export type MainPageInfoType = {
  __typename?: 'MainPageInfoType';
  id: Scalars['ID'];
  seoTitle: Scalars['String'];
  seoDescription: Scalars['String'];
  seoKeywords: Scalars['String'];
  seoImage: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  image: Scalars['String'];
};

export type StudentsReviewsType = {
  __typename?: 'StudentsReviewsType';
  id: Scalars['ID'];
  seoTitle: Scalars['String'];
  seoDescription: Scalars['String'];
  seoKeywords: Scalars['String'];
  seoImage: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  marks: Scalars['Float'];
  marksCount: Scalars['Int'];
  markText: Scalars['String'];
  markCountText: Scalars['String'];
};

export type TeachersPageInfoType = {
  __typename?: 'TeachersPageInfoType';
  id: Scalars['ID'];
  seoTitle: Scalars['String'];
  seoDescription: Scalars['String'];
  seoKeywords: Scalars['String'];
  seoImage: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
};

export type BlogGraduateStoryType = {
  __typename?: 'BlogGraduateStoryType';
  id: Scalars['ID'];
  seoTitle: Scalars['String'];
  seoDescription: Scalars['String'];
  seoKeywords: Scalars['String'];
  seoImage: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  image: Scalars['String'];
};

export type ContactsPageType = {
  __typename?: 'ContactsPageType';
  id: Scalars['ID'];
  seoTitle: Scalars['String'];
  seoDescription: Scalars['String'];
  seoKeywords: Scalars['String'];
  seoImage: Scalars['String'];
  address: Scalars['String'];
  registrationData: Scalars['String'];
  mainEmail?: Maybe<ContactEmailType>;
  paymentsLogo: Array<PaymentLogoType>;
  emails: Array<ContactEmailType>;
  phones: Array<ContactPhoneType>;
};

export type ContactEmailType = {
  __typename?: 'ContactEmailType';
  id: Scalars['ID'];
  contactsPage: ContactsPageType;
  title: Scalars['String'];
  email: Scalars['String'];
  contactspage?: Maybe<ContactsPageType>;
};

export type PaymentLogoType = {
  __typename?: 'PaymentLogoType';
  id: Scalars['ID'];
  contactsPage: ContactsPageType;
  logo: Scalars['String'];
};

export type ContactPhoneType = {
  __typename?: 'ContactPhoneType';
  id: Scalars['ID'];
  contactsPage: ContactsPageType;
  title: Scalars['String'];
  phone: Scalars['String'];
};

export type PaymentRulesPageType = {
  __typename?: 'PaymentRulesPageType';
  id: Scalars['ID'];
  seoTitle: Scalars['String'];
  seoDescription: Scalars['String'];
  seoKeywords: Scalars['String'];
  seoImage: Scalars['String'];
  title: Scalars['String'];
  rules: Scalars['String'];
};

export type DataProcessingPolicyType = {
  __typename?: 'DataProcessingPolicyType';
  id: Scalars['ID'];
  seoTitle: Scalars['String'];
  seoDescription: Scalars['String'];
  seoKeywords: Scalars['String'];
  seoImage: Scalars['String'];
  title: Scalars['String'];
  publicOfferAgreements: DataProcessingPolicyAgreementTypeConnection;
  actualPublicOfferAgreement?: Maybe<DataProcessingPolicyAgreementType>;
};


export type DataProcessingPolicyTypePublicOfferAgreementsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DataProcessingPolicyAgreementTypeConnection = {
  __typename?: 'DataProcessingPolicyAgreementTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DataProcessingPolicyAgreementTypeEdge>>;
};

/** A Relay edge containing a `DataProcessingPolicyAgreementType` and its cursor. */
export type DataProcessingPolicyAgreementTypeEdge = {
  __typename?: 'DataProcessingPolicyAgreementTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<DataProcessingPolicyAgreementType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type DataProcessingPolicyAgreementType = Node & {
  __typename?: 'DataProcessingPolicyAgreementType';
  /** The ID of the object. */
  id: Scalars['ID'];
  agreementDate: Scalars['Date'];
  content: Scalars['String'];
  file: Scalars['String'];
  displayPage: DataProcessingPolicyType;
};

export type PrivateIndividualPublicOfferPageType = {
  __typename?: 'PrivateIndividualPublicOfferPageType';
  id: Scalars['ID'];
  seoTitle: Scalars['String'];
  seoDescription: Scalars['String'];
  seoKeywords: Scalars['String'];
  seoImage: Scalars['String'];
  title: Scalars['String'];
  publicOfferAgreements: PrivateIndividualPublicOfferAgreementTypeConnection;
  actualPublicOfferAgreement?: Maybe<PrivateIndividualPublicOfferAgreementType>;
};


export type PrivateIndividualPublicOfferPageTypePublicOfferAgreementsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type PrivateIndividualPublicOfferAgreementTypeConnection = {
  __typename?: 'PrivateIndividualPublicOfferAgreementTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PrivateIndividualPublicOfferAgreementTypeEdge>>;
};

/** A Relay edge containing a `PrivateIndividualPublicOfferAgreementType` and its cursor. */
export type PrivateIndividualPublicOfferAgreementTypeEdge = {
  __typename?: 'PrivateIndividualPublicOfferAgreementTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<PrivateIndividualPublicOfferAgreementType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type PrivateIndividualPublicOfferAgreementType = Node & {
  __typename?: 'PrivateIndividualPublicOfferAgreementType';
  /** The ID of the object. */
  id: Scalars['ID'];
  agreementDate: Scalars['Date'];
  content: Scalars['String'];
  file: Scalars['String'];
  displayPage: PrivateIndividualPublicOfferPageType;
};

export type LegalEntityPublicOfferPageType = {
  __typename?: 'LegalEntityPublicOfferPageType';
  id: Scalars['ID'];
  seoTitle: Scalars['String'];
  seoDescription: Scalars['String'];
  seoKeywords: Scalars['String'];
  seoImage: Scalars['String'];
  title: Scalars['String'];
  publicOfferAgreements: LegalEntityPublicOfferAgreementTypeConnection;
  actualPublicOfferAgreement?: Maybe<LegalEntityPublicOfferAgreementType>;
};


export type LegalEntityPublicOfferPageTypePublicOfferAgreementsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type LegalEntityPublicOfferAgreementTypeConnection = {
  __typename?: 'LegalEntityPublicOfferAgreementTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<LegalEntityPublicOfferAgreementTypeEdge>>;
};

/** A Relay edge containing a `LegalEntityPublicOfferAgreementType` and its cursor. */
export type LegalEntityPublicOfferAgreementTypeEdge = {
  __typename?: 'LegalEntityPublicOfferAgreementTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<LegalEntityPublicOfferAgreementType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type LegalEntityPublicOfferAgreementType = Node & {
  __typename?: 'LegalEntityPublicOfferAgreementType';
  /** The ID of the object. */
  id: Scalars['ID'];
  agreementDate: Scalars['Date'];
  content: Scalars['String'];
  file: Scalars['String'];
  displayPage: LegalEntityPublicOfferPageType;
};

export type AllStoriesConnection = {
  __typename?: 'AllStoriesConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AllStoriesEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `AllStories` and its cursor. */
export type AllStoriesEdge = {
  __typename?: 'AllStoriesEdge';
  /** The item at the end of the edge */
  node?: Maybe<AllStoriesType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type AllStoriesType = ArticlesType | SuccessStoriesType | NativeInfoType;

export type RobotTxtType = {
  __typename?: 'RobotTxtType';
  id: Scalars['ID'];
  robotContent: Scalars['String'];
};

export type UsersSettingsConnection = {
  __typename?: 'UsersSettingsConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UsersSettingsEdge>>;
};

/** A Relay edge containing a `UsersSettings` and its cursor. */
export type UsersSettingsEdge = {
  __typename?: 'UsersSettingsEdge';
  /** The item at the end of the edge */
  node?: Maybe<UserNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type UserNode = Node & {
  __typename?: 'UserNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  lastLogin?: Maybe<Scalars['DateTime']>;
  /** Wymagana. 150 lub mniej znaków. Jedynie litery, cyfry i @/./+/-/_. */
  username: Scalars['String'];
  /** Określa czy użytkownik może zalogować się do panelu administracyjnego. */
  isStaff: Scalars['Boolean'];
  /** Określa czy użytkownika należy uważać za aktywnego. Odznacz zamiast usuwać konto. */
  isActive: Scalars['Boolean'];
  dateJoined: Scalars['DateTime'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  roles: Array<Scalars['String']>;
  teacher?: Maybe<TeacherFilterType>;
  student?: Maybe<StudentType>;
  pk?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  verified?: Maybe<Scalars['Boolean']>;
  secondaryEmail?: Maybe<Scalars['String']>;
  activeRole?: Maybe<Scalars['String']>;
  methodistGroupsCount?: Maybe<Scalars['Int']>;
};

export type MetaUsersSettings = {
  __typename?: 'MetaUsersSettings';
  totalCount?: Maybe<Scalars['Int']>;
};

export type UserNodeConnection = {
  __typename?: 'UserNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserNodeEdge>>;
};

/** A Relay edge containing a `UserNode` and its cursor. */
export type UserNodeEdge = {
  __typename?: 'UserNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<UserNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type MonthBudget = {
  __typename?: 'MonthBudget';
  USD?: Maybe<Scalars['CustomFloat']>;
  EUR?: Maybe<Scalars['CustomFloat']>;
  BYN?: Maybe<Scalars['CustomFloat']>;
  RUB?: Maybe<Scalars['CustomFloat']>;
  PLN?: Maybe<Scalars['CustomFloat']>;
  month?: Maybe<Scalars['Int']>;
};

export type WeeksBudgetInfo = {
  __typename?: 'WeeksBudgetInfo';
  budget?: Maybe<Array<Maybe<WeeksBudget>>>;
  month: Scalars['Int'];
  plan?: Maybe<Array<Maybe<WeeksBudget>>>;
  year: Scalars['Int'];
};

export type WeeksBudget = {
  __typename?: 'WeeksBudget';
  USD?: Maybe<Scalars['CustomFloat']>;
  EUR?: Maybe<Scalars['CustomFloat']>;
  BYN?: Maybe<Scalars['CustomFloat']>;
  RUB?: Maybe<Scalars['CustomFloat']>;
  PLN?: Maybe<Scalars['CustomFloat']>;
  week?: Maybe<Scalars['Int']>;
};

export type ContributionConnection = {
  __typename?: 'ContributionConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ContributionEdge>>;
};

/** A Relay edge containing a `Contribution` and its cursor. */
export type ContributionEdge = {
  __typename?: 'ContributionEdge';
  /** The item at the end of the edge */
  node?: Maybe<ContributionType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type MetaContribution = {
  __typename?: 'MetaContribution';
  contributionsNums: Array<Maybe<Scalars['Int']>>;
  /** Deprecated from 1.3.0 version. */
  contributionsCount?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PaymentsVerificationType = {
  __typename?: 'PaymentsVerificationType';
  agreement_id?: Maybe<Scalars['ID']>;
  agreementTitle?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  studentId?: Maybe<Scalars['ID']>;
  fullname?: Maybe<Scalars['String']>;
};

export type DeferredPaymentConnection = {
  __typename?: 'DeferredPaymentConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DeferredPaymentEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `DeferredPayment` and its cursor. */
export type DeferredPaymentEdge = {
  __typename?: 'DeferredPaymentEdge';
  /** The item at the end of the edge */
  node?: Maybe<DeferredPaymentType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type DeferredPaymentType = Node & {
  __typename?: 'DeferredPaymentType';
  id: Scalars['ID'];
  userInput: Scalars['String'];
  agreementTitle?: Maybe<Scalars['String']>;
  sum?: Maybe<Scalars['CustomFloat']>;
  currency?: Maybe<DeferredPaymentCurrency>;
  studentFullname?: Maybe<Scalars['String']>;
  agreementId?: Maybe<Scalars['Int']>;
  date: Scalars['Date'];
  agreementCurrency?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum DeferredPaymentCurrency {
  /** USD */
  Usd = 'USD',
  /** BYN */
  Byn = 'BYN',
  /** RUB */
  Rub = 'RUB',
  /** EUR */
  Eur = 'EUR',
  /** PLN */
  Pln = 'PLN'
}

export type MetaDeferredPaymentConnection = {
  __typename?: 'MetaDeferredPaymentConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MetaDeferredPaymentEdge>>;
};

/** A Relay edge containing a `MetaDeferredPayment` and its cursor. */
export type MetaDeferredPaymentEdge = {
  __typename?: 'MetaDeferredPaymentEdge';
  /** The item at the end of the edge */
  node?: Maybe<MetaDeferredPayment>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type MetaDeferredPayment = Node & {
  __typename?: 'MetaDeferredPayment';
  /** The ID of the object. */
  id: Scalars['ID'];
  deferredPaymentsDate?: Maybe<Scalars['Date']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type StudentConnection = {
  __typename?: 'StudentConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<StudentEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `Student` and its cursor. */
export type StudentEdge = {
  __typename?: 'StudentEdge';
  /** The item at the end of the edge */
  node?: Maybe<StudentType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type MetaStudents = {
  __typename?: 'MetaStudents';
  totalCount?: Maybe<Scalars['Int']>;
  agreementsCount?: Maybe<Scalars['Int']>;
};

export type VerifiedStudent = {
  __typename?: 'VerifiedStudent';
  student?: Maybe<StudentType>;
  user?: Maybe<CustomUserType>;
  message?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
};

export type CustomErrorType = {
  __typename?: 'CustomErrorType';
  field?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type MetaTeachers = {
  __typename?: 'MetaTeachers';
  /** Returns teachers count */
  totalCount?: Maybe<Scalars['Int']>;
  /** Returns applications count */
  applicationsCount?: Maybe<Scalars['Int']>;
};

export type VerifiedTeacher = {
  __typename?: 'VerifiedTeacher';
  teacher?: Maybe<TeacherType>;
  user?: Maybe<CustomUserType>;
  message?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
};

export type BaseTeacherInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  phone: Scalars['String'];
};

export type MetaTeacherApplications = {
  __typename?: 'MetaTeacherApplications';
  totalCount?: Maybe<Scalars['Int']>;
};

export type CountryConnection = {
  __typename?: 'CountryConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CountryEdge>>;
};

/** A Relay edge containing a `Country` and its cursor. */
export type CountryEdge = {
  __typename?: 'CountryEdge';
  /** The item at the end of the edge */
  node?: Maybe<CountryType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CompanyConnection = {
  __typename?: 'CompanyConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CompanyEdge>>;
};

/** A Relay edge containing a `Company` and its cursor. */
export type CompanyEdge = {
  __typename?: 'CompanyEdge';
  /** The item at the end of the edge */
  node?: Maybe<CompanyType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GroupLessonConnection = {
  __typename?: 'GroupLessonConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GroupLessonEdge>>;
  id?: Maybe<Scalars['ID']>;
  groupName?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
};

/** A Relay edge containing a `GroupLesson` and its cursor. */
export type GroupLessonEdge = {
  __typename?: 'GroupLessonEdge';
  /** The item at the end of the edge */
  node?: Maybe<GroupLessonType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type StudentsMarkConnection = {
  __typename?: 'StudentsMarkConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<StudentsMarkEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `StudentsMark` and its cursor. */
export type StudentsMarkEdge = {
  __typename?: 'StudentsMarkEdge';
  /** The item at the end of the edge */
  node?: Maybe<StudentsMarkType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type StudentsAchievementConnection = {
  __typename?: 'StudentsAchievementConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<StudentsAchievementEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `StudentsAchievement` and its cursor. */
export type StudentsAchievementEdge = {
  __typename?: 'StudentsAchievementEdge';
  /** The item at the end of the edge */
  node?: Maybe<AgreementType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type LanguageConnection = {
  __typename?: 'LanguageConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<LanguageEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `Language` and its cursor. */
export type LanguageEdge = {
  __typename?: 'LanguageEdge';
  /** The item at the end of the edge */
  node?: Maybe<LanguageType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type SocialMediaConnection = {
  __typename?: 'SocialMediaConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SocialMediaEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `SocialMedia` and its cursor. */
export type SocialMediaEdge = {
  __typename?: 'SocialMediaEdge';
  /** The item at the end of the edge */
  node?: Maybe<SocialMediaType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CategoryConnection = {
  __typename?: 'CategoryConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CategoryEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `Category` and its cursor. */
export type CategoryEdge = {
  __typename?: 'CategoryEdge';
  /** The item at the end of the edge */
  node?: Maybe<CategoryType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type MetaCourses = {
  __typename?: 'MetaCourses';
  totalCount?: Maybe<Scalars['Int']>;
};

export type GraduateFeedbackConnection = {
  __typename?: 'GraduateFeedbackConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GraduateFeedbackEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `GraduateFeedback` and its cursor. */
export type GraduateFeedbackEdge = {
  __typename?: 'GraduateFeedbackEdge';
  /** The item at the end of the edge */
  node?: Maybe<GraduateFeedbackType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type DemoType = {
  __typename?: 'DemoType';
  totalCount?: Maybe<Scalars['Int']>;
};

export type GroupConnection = {
  __typename?: 'GroupConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GroupEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `Group` and its cursor. */
export type GroupEdge = {
  __typename?: 'GroupEdge';
  /** The item at the end of the edge */
  node?: Maybe<GroupType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GroupType = Node & {
  __typename?: 'GroupType';
  id: Scalars['ID'];
  name: Scalars['String'];
  startDate: Scalars['Date'];
  maxStudentsCount: Scalars['Int'];
  course: CourseFilterType;
  publishedLms: Scalars['Boolean'];
  publishedLmsForTeacher: Scalars['Boolean'];
  publishedLmsForMethodist: Scalars['Boolean'];
  comment?: Maybe<Scalars['String']>;
  isFree: Scalars['Boolean'];
  teacherLeads: ApplicationFilterTypeConnection;
  modules: Array<GroupModuleType>;
  classesDays: Array<DayOfClassType>;
  agreements: Array<AgreementType>;
  lessons?: Maybe<Array<GroupLessonType>>;
  studentsCount?: Maybe<Scalars['Int']>;
  lessonsCount?: Maybe<Scalars['Int']>;
  passedLessonsCount?: Maybe<Scalars['Int']>;
  freeLessonsCount?: Maybe<Scalars['Int']>;
  statusFinished?: Maybe<Scalars['Boolean']>;
  datetimeNextLesson?: Maybe<Scalars['DateTime']>;
  deleteAvailable?: Maybe<Scalars['Boolean']>;
  teachers?: Maybe<Array<Maybe<TeacherType>>>;
  freePlaces?: Maybe<Scalars['Int']>;
  daysOfClass: Array<Scalars['Int']>;
  price?: Maybe<Scalars['CustomFloat']>;
  currency?: Maybe<Scalars['String']>;
  contributionsSum?: Maybe<Scalars['CustomFloat']>;
};


export type GroupTypeTeacherLeadsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  byGroupId?: Maybe<Scalars['Decimal']>;
  isTerminated?: Maybe<Scalars['Boolean']>;
};

export type UnionGroupConnection = {
  __typename?: 'UnionGroupConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UnionGroupEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UnionGroup` and its cursor. */
export type UnionGroupEdge = {
  __typename?: 'UnionGroupEdge';
  /** The item at the end of the edge */
  node?: Maybe<UnionGroupType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type UnionGroupType = GroupType | StudentGroupType | MethodistGroupType;

export type StudentGroupType = Node & {
  __typename?: 'StudentGroupType';
  id: Scalars['ID'];
  name: Scalars['String'];
  startDate: Scalars['Date'];
  maxStudentsCount: Scalars['Int'];
  course: CourseFilterType;
  publishedLms: Scalars['Boolean'];
  publishedLmsForTeacher: Scalars['Boolean'];
  publishedLmsForMethodist: Scalars['Boolean'];
  comment?: Maybe<Scalars['String']>;
  isFree: Scalars['Boolean'];
  teacherLeads: ApplicationFilterTypeConnection;
  modules: Array<GroupModuleType>;
  classesDays: Array<DayOfClassType>;
  agreements: Array<AgreementType>;
  lessons?: Maybe<Array<GroupLessonType>>;
  studentsCount?: Maybe<Scalars['Int']>;
  lessonsCount?: Maybe<Scalars['Int']>;
  passedLessonsCount?: Maybe<Scalars['Int']>;
  freeLessonsCount?: Maybe<Scalars['Int']>;
  statusFinished?: Maybe<Scalars['Boolean']>;
  datetimeNextLesson?: Maybe<Scalars['DateTime']>;
  deleteAvailable?: Maybe<Scalars['Boolean']>;
  teachers?: Maybe<Array<Maybe<TeacherType>>>;
  freePlaces?: Maybe<Scalars['Int']>;
  daysOfClass: Array<Scalars['Int']>;
  price?: Maybe<Scalars['CustomFloat']>;
  currency?: Maybe<Scalars['String']>;
  contributionsSum?: Maybe<Scalars['CustomFloat']>;
};


export type StudentGroupTypeTeacherLeadsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  byGroupId?: Maybe<Scalars['Decimal']>;
  isTerminated?: Maybe<Scalars['Boolean']>;
};

export type MetaGroups = {
  __typename?: 'MetaGroups';
  totalCount?: Maybe<Scalars['Int']>;
};

export type UnionLessonType = GroupLessonForLmsType | StudentLessonType | MethodistLessonType;

export type GroupLessonForLmsType = Node & {
  __typename?: 'GroupLessonForLMSType';
  id: Scalars['ID'];
  name: Scalars['String'];
  taskTypes: Array<Scalars['String']>;
  module: GroupModuleType;
  teacherApplication?: Maybe<ApplicationType>;
  number: Scalars['Int'];
  date: Scalars['Date'];
  startTime: Scalars['Time'];
  endTime: Scalars['Time'];
  lmsDescription?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
  finished: Scalars['Boolean'];
  additional?: Maybe<Scalars['Boolean']>;
  students: StudentTypeConnection;
  marks: StudentsMarkTypeConnection;
  index?: Maybe<Scalars['Int']>;
  lessonNumber?: Maybe<Scalars['Int']>;
  needToFinish?: Maybe<Scalars['Boolean']>;
  teacher?: Maybe<TeacherType>;
  hasStudentMarks?: Maybe<Scalars['Boolean']>;
  hasStudentAttachment?: Maybe<Scalars['Boolean']>;
  hasTeacherAttachment?: Maybe<Scalars['Boolean']>;
};


export type GroupLessonForLmsTypeStudentsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type GroupLessonForLmsTypeMarksArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  taskType?: Maybe<Scalars['String']>;
  lessonId?: Maybe<Scalars['String']>;
};

export type StudentLessonType = Node & {
  __typename?: 'StudentLessonType';
  id: Scalars['ID'];
  name: Scalars['String'];
  taskTypes: Array<Scalars['String']>;
  module: GroupModuleType;
  teacherApplication?: Maybe<ApplicationType>;
  number: Scalars['Int'];
  date: Scalars['Date'];
  startTime: Scalars['Time'];
  endTime: Scalars['Time'];
  lmsDescription?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
  finished: Scalars['Boolean'];
  additional?: Maybe<Scalars['Boolean']>;
  students: StudentTypeConnection;
  marks: StudentsMarkTypeConnection;
  studentsAttachments: StudentLessonAttachmentTypeConnection;
  teachersAttachments: TeacherLessonAttachmentTypeConnection;
  firstLessonAgreements: Array<AgreementType>;
  lastLessonAgreements: Array<AgreementType>;
  attendances: AttendanceTypeConnection;
  index?: Maybe<Scalars['Int']>;
  lessonNumber?: Maybe<Scalars['Int']>;
  needToFinish?: Maybe<Scalars['Boolean']>;
  teacher?: Maybe<TeacherType>;
  hasStudentMarks?: Maybe<Scalars['Boolean']>;
  hasStudentAttachment?: Maybe<Scalars['Boolean']>;
  hasTeacherAttachment?: Maybe<Scalars['Boolean']>;
};


export type StudentLessonTypeStudentsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type StudentLessonTypeMarksArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  taskType?: Maybe<Scalars['String']>;
  lessonId?: Maybe<Scalars['String']>;
};


export type StudentLessonTypeStudentsAttachmentsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type StudentLessonTypeTeachersAttachmentsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type StudentLessonTypeAttendancesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type MethodistLessonType = Node & {
  __typename?: 'MethodistLessonType';
  id: Scalars['ID'];
  name: Scalars['String'];
  taskTypes: Array<Scalars['String']>;
  module: GroupModuleType;
  teacherApplication?: Maybe<ApplicationType>;
  number: Scalars['Int'];
  date: Scalars['Date'];
  startTime: Scalars['Time'];
  endTime: Scalars['Time'];
  lmsDescription?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
  finished: Scalars['Boolean'];
  additional?: Maybe<Scalars['Boolean']>;
  students: StudentTypeConnection;
  marks: StudentsMarkTypeConnection;
  studentsAttachments: StudentLessonAttachmentTypeConnection;
  teachersAttachments: TeacherLessonAttachmentTypeConnection;
  firstLessonAgreements: Array<AgreementType>;
  lastLessonAgreements: Array<AgreementType>;
  attendances: AttendanceTypeConnection;
  index?: Maybe<Scalars['Int']>;
  lessonNumber?: Maybe<Scalars['Int']>;
  needToFinish?: Maybe<Scalars['Boolean']>;
  teacher?: Maybe<TeacherType>;
  hasStudentMarks?: Maybe<Scalars['Boolean']>;
  hasStudentAttachment?: Maybe<Scalars['Boolean']>;
  hasTeacherAttachment?: Maybe<Scalars['Boolean']>;
};


export type MethodistLessonTypeStudentsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type MethodistLessonTypeMarksArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  taskType?: Maybe<Scalars['String']>;
  lessonId?: Maybe<Scalars['String']>;
};


export type MethodistLessonTypeStudentsAttachmentsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type MethodistLessonTypeTeachersAttachmentsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type MethodistLessonTypeAttendancesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type LessonAttachmentsType = {
  __typename?: 'LessonAttachmentsType';
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['String']>;
};

export type LessonAttachmentsForLmsType = {
  __typename?: 'LessonAttachmentsForLMSType';
  id: Scalars['ID'];
  title: Scalars['String'];
  file: Scalars['String'];
};

export type LessonAttendancesType = Node & {
  __typename?: 'LessonAttendancesType';
  id: Scalars['ID'];
  lesson: GroupLessonType;
  student: StudentType;
  attendance?: Maybe<Scalars['Boolean']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createGroup?: Maybe<CreateGroupMutation>;
  updateGroup?: Maybe<UpdateGroupMutation>;
  deleteGroup?: Maybe<DeleteGroupMutation>;
  publishGroup?: Maybe<PublishGroupToLms>;
  changeLessonDates?: Maybe<ChangeLessonDate>;
  uploadLessonVideo?: Maybe<UploadLessonVideo>;
  cancelVideoUpload?: Maybe<CancelVideoUpload>;
  createLead?: Maybe<CreateLeadMutation>;
  createBlackFridayLead?: Maybe<CreateBlackFridayLeadMutation>;
  createNewYearLead?: Maybe<CreateNewYearLeadMutation>;
  updateJournal?: Maybe<UpdateJournalsMutation>;
  updateAttendance?: Maybe<UpdateAttendanceMutation>;
  finishLmsLesson?: Maybe<FinishLmsLessonMutation>;
  setStudentsMarks?: Maybe<SetStudentsMarksMutation>;
  createCourse?: Maybe<CreateCourseMutation>;
  updateCourse?: Maybe<UpdateCourseMutation>;
  deleteCourse?: Maybe<DeleteCourseMutation>;
  createUniqueTeacher?: Maybe<CreateUniqueTeacher>;
  addTeacherRole?: Maybe<AddNewRoleTeacher>;
  addTeacherExist?: Maybe<AddTeacherExist>;
  addTeacherApplication?: Maybe<AddTeacherApplication>;
  updateTeacherApplication?: Maybe<UpdateTeacherApplication>;
  deleteTeacherApplication?: Maybe<DeleteTeacherApplication>;
  updateTeacherWithType?: Maybe<UpdateTeacherWithTypeMutation>;
  uploadResume?: Maybe<UploadResume>;
  createPayments?: Maybe<CreatePaymentsMutation>;
  createDeferredPayments?: Maybe<CreateDeferredPaymentsMutation>;
  checkStudentDebt?: Maybe<CheckStudentsDebtMutation>;
  updateStudentWithAgreement?: Maybe<StudentWithAgreementUpdateMutation>;
  createStudentWithAgreement?: Maybe<StudentWithAgreementCreateMutation>;
  appendStudentRoleAndAgreement?: Maybe<AddRoleStudentAndAgreement>;
  lmsStudentSignUp?: Maybe<StudentSignUpLmsMutation>;
  createAgreement?: Maybe<CreateAgreementMutation>;
  createCompany?: Maybe<CreateCompanyMutation>;
  updateCompany?: Maybe<UpdateCompanyMutation>;
  createUser?: Maybe<RegisterMutation>;
  sendPasswordResetEmail?: Maybe<CustomSendPasswordResetEmail>;
  sendLmsAuthEmail?: Maybe<SendAuthenticationCode>;
  /**
   * Change user password without old password.
   * 
   * Receive the token that was sent by email.
   * 
   * If token and new passwords are valid, update
   * user password and in case of using refresh
   * tokens, revoke all of them.
   * 
   * Also, if user has not been verified yet, verify it.
   */
  passwordReset?: Maybe<PasswordReset>;
  /**
   * Set user password - for passwordless registration
   * 
   * Receive the token that was sent by email.
   * 
   * If token and new passwords are valid, set
   * user password and in case of using refresh
   * tokens, revoke all of them.
   * 
   * Also, if user has not been verified yet, verify it.
   */
  passwordSet?: Maybe<PasswordSet>;
  /**
   * Obtain JSON web token for given user.
   * 
   * Allow to perform login with different fields,
   * and secondary email if set. The fields are
   * defined on settings.
   * 
   * Not verified users can login by default. This
   * can be changes on settings.
   * 
   * If user is archived, make it unarchive and
   * return `unarchiving=True` on output.
   */
  customLogin?: Maybe<CustomObtainJsonWebToken>;
  /** Same as `grapgql_jwt` implementation, with standard output. */
  verifyToken?: Maybe<VerifyToken>;
  /** Same as `grapgql_jwt` implementation, with standard output. */
  refreshToken?: Maybe<CustomRefreshToken>;
  /** Same as `grapgql_jwt` implementation, with standard output. */
  revokeToken?: Maybe<RevokeToken>;
  /**
   * Obtain JSON web token for given user.
   * 
   * Allow to perform login with different fields,
   * and secondary email if set. The fields are
   * defined on settings.
   * 
   * Not verified users can login by default. This
   * can be changes on settings.
   * 
   * If user is archived, make it unarchive and
   * return `unarchiving=True` on output.
   */
  demoCustomLogin?: Maybe<CustomObtainJsonWebToken>;
  updateUserSettings?: Maybe<UserSettingsUpdateMutation>;
  deleteUserSettings?: Maybe<UserSettingsDeleteMutation>;
  appendRolesUserSettings?: Maybe<UserSettingsRolesAddMutation>;
};


export type MutationCreateGroupArgs = {
  groupInput: GroupInput;
  moduleInput: Array<Maybe<ModuleInput>>;
};


export type MutationUpdateGroupArgs = {
  groupId: Scalars['Int'];
  groupInput: UpdateGroupInput;
};


export type MutationDeleteGroupArgs = {
  groupId: Scalars['Int'];
};


export type MutationPublishGroupArgs = {
  groupId: Scalars['ID'];
  publishedForTeachers?: Maybe<Scalars['Boolean']>;
  publishedLms: Scalars['Boolean'];
  publishedLmsForMethodist?: Maybe<Scalars['Boolean']>;
};


export type MutationChangeLessonDatesArgs = {
  groupId: Scalars['Int'];
  newDate: Scalars['Date'];
  oldDate: Scalars['Date'];
};


export type MutationUploadLessonVideoArgs = {
  lessonId: Scalars['Int'];
  videoUrl: Scalars['String'];
};


export type MutationCancelVideoUploadArgs = {
  lessonId: Scalars['Int'];
};


export type MutationCreateLeadArgs = {
  leadInput: LeadInput;
};


export type MutationCreateBlackFridayLeadArgs = {
  bfLeadInput: BlackFridayLeadInput;
};


export type MutationCreateNewYearLeadArgs = {
  nyLeadInput: NewYearLeadInput;
};


export type MutationUpdateJournalArgs = {
  comment: Scalars['String'];
  daysOfClass: Array<DayOfClassInput>;
  groupId: Scalars['Int'];
  modules: Array<Maybe<UpdateModuleInput>>;
};


export type MutationUpdateAttendanceArgs = {
  attendance: Scalars['Boolean'];
  attendanceId: Scalars['Int'];
};


export type MutationFinishLmsLessonArgs = {
  attendances: Array<LmsAttendanceType>;
  comment: Scalars['String'];
  groupLessonId: Scalars['Int'];
};


export type MutationSetStudentsMarksArgs = {
  date: Scalars['Date'];
  lessonId: Scalars['Int'];
  studentsMarks: Array<Maybe<LessonMarkInput>>;
  taskType: LessonTaskTypeEnum;
};


export type MutationCreateCourseArgs = {
  name?: Maybe<Scalars['String']>;
};


export type MutationUpdateCourseArgs = {
  courseId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};


export type MutationDeleteCourseArgs = {
  courseId?: Maybe<Scalars['ID']>;
};


export type MutationCreateUniqueTeacherArgs = {
  application: ApplicationInputType;
  employmentTypes: EmploymentInputType;
  teacher: NewTeacherInput;
};


export type MutationAddTeacherRoleArgs = {
  application: ApplicationInputType;
  employmentTypes: EmploymentInputType;
  siteDisplay?: Maybe<Scalars['Boolean']>;
  userId: Scalars['ID'];
};


export type MutationAddTeacherExistArgs = {
  application: ApplicationInputType;
  employmentTypes: ExistEmploymentInputType;
  teacherId: Scalars['ID'];
};


export type MutationAddTeacherApplicationArgs = {
  application: ApplicationInputType;
  teacherId: Scalars['ID'];
};


export type MutationUpdateTeacherApplicationArgs = {
  application: UpdateApplicationInputType;
};


export type MutationDeleteTeacherApplicationArgs = {
  applicationId: Scalars['ID'];
};


export type MutationUpdateTeacherWithTypeArgs = {
  employmentInput: ExistEmploymentInputType;
  teacherId?: Maybe<Scalars['ID']>;
  teacherInput: TeacherWithTypeInput;
};


export type MutationUploadResumeArgs = {
  photo?: Maybe<Scalars['Upload']>;
  resumeInput: ResumeInput;
};


export type MutationCreatePaymentsArgs = {
  paymentsInput?: Maybe<Array<Maybe<PaymentInput>>>;
};


export type MutationCreateDeferredPaymentsArgs = {
  deferredPaymentsInput?: Maybe<Array<Maybe<DeferredPaymentInput>>>;
};


export type MutationCheckStudentDebtArgs = {
  agreementsInput: Array<Maybe<Scalars['String']>>;
};


export type MutationUpdateStudentWithAgreementArgs = {
  agreementId?: Maybe<Scalars['ID']>;
  updateStudentInput: UpdateStudentInput;
};


export type MutationCreateStudentWithAgreementArgs = {
  agreementInput: CreateAgreementInputBase;
  studentInput: StudentInput;
};


export type MutationAppendStudentRoleAndAgreementArgs = {
  agreementInput: CreateAgreementInputBase;
  userId: Scalars['ID'];
};


export type MutationLmsStudentSignUpArgs = {
  studentInput: StudentSignUpInput;
};


export type MutationCreateAgreementArgs = {
  agreementInput: CreateAgreementInput;
};


export type MutationCreateCompanyArgs = {
  companyInput: CompanyInput;
};


export type MutationUpdateCompanyArgs = {
  companyId: Scalars['ID'];
  companyInput: UpdateCompanyInput;
};


export type MutationCreateUserArgs = {
  middleName?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  roles: Scalars['String'];
  phone: Scalars['String'];
};


export type MutationSendPasswordResetEmailArgs = {
  email: Scalars['String'];
};


export type MutationSendLmsAuthEmailArgs = {
  authenticationInput: LmsAuthenticationInput;
};


export type MutationPasswordResetArgs = {
  token: Scalars['String'];
  newPassword1: Scalars['String'];
  newPassword2: Scalars['String'];
};


export type MutationPasswordSetArgs = {
  token: Scalars['String'];
  newPassword1: Scalars['String'];
  newPassword2: Scalars['String'];
};


export type MutationCustomLoginArgs = {
  password?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  scope: Scalars['String'];
  authenticationCode?: Maybe<Scalars['String']>;
};


export type MutationVerifyTokenArgs = {
  token: Scalars['String'];
};


export type MutationRefreshTokenArgs = {
  activeRole?: Maybe<LmsRoles>;
  refreshToken: Scalars['String'];
  scope?: Maybe<Scalars['String']>;
};


export type MutationRevokeTokenArgs = {
  refreshToken: Scalars['String'];
};


export type MutationDemoCustomLoginArgs = {
  password?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  scope: Scalars['String'];
  authenticationCode?: Maybe<Scalars['String']>;
};


export type MutationUpdateUserSettingsArgs = {
  userId?: Maybe<Scalars['ID']>;
  userSettingsInput: CustomUserInput;
};


export type MutationDeleteUserSettingsArgs = {
  userId?: Maybe<Scalars['ID']>;
};


export type MutationAppendRolesUserSettingsArgs = {
  roles: Scalars['String'];
  userId: Scalars['ID'];
};

export type CreateGroupMutation = {
  __typename?: 'CreateGroupMutation';
  success?: Maybe<Scalars['Boolean']>;
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  group?: Maybe<GroupType>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
};


export type GroupInput = {
  name: Scalars['String'];
  price: Scalars['Float'];
  currency: Scalars['String'];
  startDate: Scalars['Date'];
  course_id: Scalars['Int'];
  daysOfClass: Array<DayOfClassInput>;
};

export type DayOfClassInput = {
  weekDay: Scalars['Int'];
  startTime?: Maybe<Scalars['Time']>;
  endTime?: Maybe<Scalars['Time']>;
};

export type ModuleInput = {
  name: Scalars['String'];
  lessons: Array<Maybe<LessonInput>>;
};

export type LessonInput = {
  name: Scalars['String'];
  lmsDescription?: Maybe<Scalars['String']>;
  studentsAttachments?: Maybe<Array<Scalars['Int']>>;
  teachersAttachments?: Maybe<Array<Scalars['Int']>>;
  taskTypes?: Maybe<Array<Scalars['String']>>;
  additional?: Maybe<Scalars['Boolean']>;
};

export type UpdateGroupMutation = {
  __typename?: 'UpdateGroupMutation';
  success?: Maybe<Scalars['Boolean']>;
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  group?: Maybe<GroupType>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
};

export type UpdateGroupInput = {
  name: Scalars['String'];
  price: Scalars['Float'];
  currency: Scalars['String'];
  startDate: Scalars['Date'];
  course_id: Scalars['Int'];
  daysOfClass: Array<DayOfClassInput>;
  modules: Array<Maybe<UpdateModuleInput>>;
};

export type UpdateModuleInput = {
  module_id: Scalars['Int'];
  lessons: Array<Maybe<UpdateLessonInput>>;
};

export type UpdateLessonInput = {
  lesson_id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  teacher_id?: Maybe<Scalars['Int']>;
  application_id?: Maybe<Scalars['ID']>;
  additional?: Maybe<Scalars['Boolean']>;
};

export type DeleteGroupMutation = {
  __typename?: 'DeleteGroupMutation';
  success?: Maybe<Scalars['Boolean']>;
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
};

export type PublishGroupToLms = {
  __typename?: 'PublishGroupToLMS';
  success?: Maybe<Scalars['Boolean']>;
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  group?: Maybe<GroupType>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
};

export type ChangeLessonDate = {
  __typename?: 'ChangeLessonDate';
  success?: Maybe<Scalars['Boolean']>;
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
};

export type UploadLessonVideo = {
  __typename?: 'UploadLessonVideo';
  success?: Maybe<Scalars['Boolean']>;
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
};

export type CancelVideoUpload = {
  __typename?: 'CancelVideoUpload';
  success?: Maybe<Scalars['Boolean']>;
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
};

export type CreateLeadMutation = {
  __typename?: 'CreateLeadMutation';
  success?: Maybe<Scalars['Boolean']>;
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
};

export type LeadInput = {
  phone: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  course_id?: Maybe<Scalars['Int']>;
  tittle: Scalars['String'];
  referer?: Maybe<Scalars['String']>;
  formName: Scalars['String'];
  utmSource?: Maybe<Scalars['String']>;
  utmMedium?: Maybe<Scalars['String']>;
  utmCampaign?: Maybe<Scalars['String']>;
  utmContent?: Maybe<Scalars['String']>;
  utmTerm?: Maybe<Scalars['String']>;
  roistatId: Scalars['String'];
  promocode?: Maybe<Scalars['String']>;
};

export type CreateBlackFridayLeadMutation = {
  __typename?: 'CreateBlackFridayLeadMutation';
  success?: Maybe<Scalars['Boolean']>;
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
};

export type BlackFridayLeadInput = {
  phone: Scalars['String'];
  name: Scalars['String'];
  email: Scalars['String'];
  course_id?: Maybe<Scalars['Int']>;
  tittle: Scalars['String'];
  referer?: Maybe<Scalars['String']>;
  formName: Scalars['String'];
  utmSource?: Maybe<Scalars['String']>;
  utmMedium?: Maybe<Scalars['String']>;
  utmCampaign?: Maybe<Scalars['String']>;
  utmContent?: Maybe<Scalars['String']>;
  utmTerm?: Maybe<Scalars['String']>;
  roistatId: Scalars['String'];
  promocode?: Maybe<Scalars['String']>;
};

export type CreateNewYearLeadMutation = {
  __typename?: 'CreateNewYearLeadMutation';
  success?: Maybe<Scalars['Boolean']>;
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
};

export type NewYearLeadInput = {
  phone: Scalars['String'];
  name: Scalars['String'];
  email: Scalars['String'];
  course_id?: Maybe<Scalars['Int']>;
  tittle: Scalars['String'];
  referer?: Maybe<Scalars['String']>;
  formName: Scalars['String'];
  utmSource?: Maybe<Scalars['String']>;
  utmMedium?: Maybe<Scalars['String']>;
  utmCampaign?: Maybe<Scalars['String']>;
  utmContent?: Maybe<Scalars['String']>;
  utmTerm?: Maybe<Scalars['String']>;
  roistatId: Scalars['String'];
  promocode?: Maybe<Scalars['String']>;
  age: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  message: Scalars['String'];
};

export type UpdateJournalsMutation = {
  __typename?: 'UpdateJournalsMutation';
  success?: Maybe<Scalars['Boolean']>;
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
};

export type UpdateAttendanceMutation = {
  __typename?: 'UpdateAttendanceMutation';
  success?: Maybe<Scalars['Boolean']>;
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  attendance?: Maybe<AttendanceType>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
};

export type FinishLmsLessonMutation = {
  __typename?: 'FinishLmsLessonMutation';
  success?: Maybe<Scalars['Boolean']>;
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
};

export type LmsAttendanceType = {
  id: Scalars['Int'];
  attendance: Scalars['Boolean'];
};

export type SetStudentsMarksMutation = {
  __typename?: 'SetStudentsMarksMutation';
  success?: Maybe<Scalars['Boolean']>;
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  studentsMarks?: Maybe<StudentsMarkConnection>;
};


export type SetStudentsMarksMutationStudentsMarksArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type LessonMarkInput = {
  student_id: Scalars['Int'];
  mark?: Maybe<Scalars['Int']>;
  hwMark?: Maybe<StudentHwTypeEnum>;
};

/** An enumeration. */
export enum StudentHwTypeEnum {
  Done = 'DONE',
  ContainsErrors = 'CONTAINS_ERRORS',
  NotDone = 'NOT_DONE'
}

/** An enumeration. */
export enum LessonTaskTypeEnum {
  /** homework */
  Homework = 'homework',
  /** coursework */
  Coursework = 'coursework',
  /** graduation_project */
  GraduationProject = 'graduation_project'
}

export type CreateCourseMutation = {
  __typename?: 'CreateCourseMutation';
  course?: Maybe<CourseType>;
};

export type UpdateCourseMutation = {
  __typename?: 'UpdateCourseMutation';
  course?: Maybe<CourseType>;
};

export type DeleteCourseMutation = {
  __typename?: 'DeleteCourseMutation';
  course?: Maybe<CourseType>;
};

export type CreateUniqueTeacher = {
  __typename?: 'CreateUniqueTeacher';
  success?: Maybe<Scalars['Boolean']>;
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  teacher?: Maybe<TeacherType>;
};

export type ApplicationInputType = {
  teacherType: EmploymentTypes;
  title: Scalars['String'];
  date: Scalars['Date'];
  group: Scalars['ID'];
  lessonsCount: Scalars['Int'];
  country?: Maybe<Scalars['ID']>;
  servicePrice: Scalars['Float'];
  riaPrice?: Maybe<Scalars['Float']>;
  currency: Currencies;
  comment?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum EmploymentTypes {
  /** individual_entrepreneur */
  Ie = 'IE',
  /** self_employed */
  Se = 'SE',
  /** natural_person */
  Np = 'NP',
  /** legal_entity */
  Le = 'LE'
}

/** An enumeration. */
export enum Currencies {
  Byn = 'BYN',
  Usd = 'USD',
  Eur = 'EUR',
  Rub = 'RUB',
  Pln = 'PLN'
}

export type EmploymentInputType = {
  individualEntrepreneur?: Maybe<IndividualEntrepreneurInput>;
  selfEmployed?: Maybe<SelfEmployedInput>;
  naturalPerson?: Maybe<NaturalPersonInput>;
  legalEntity?: Maybe<LegalEntityInput>;
};

export type IndividualEntrepreneurInput = {
  registrationAddress: Scalars['String'];
  accountNumber: Scalars['String'];
  bankTitle: Scalars['String'];
  bic: Scalars['String'];
  inn: Scalars['String'];
};

export type SelfEmployedInput = {
  registrationAddress: Scalars['String'];
  accountNumber: Scalars['String'];
  bankTitle: Scalars['String'];
  bic: Scalars['String'];
  nationality: Scalars['ID'];
  passportNumber: Scalars['String'];
  inn: Scalars['String'];
};

export type NaturalPersonInput = {
  registrationAddress: Scalars['String'];
  accountNumber: Scalars['String'];
  bankTitle: Scalars['String'];
  bic: Scalars['String'];
  nationality: Scalars['ID'];
  passportNumber: Scalars['String'];
};

export type LegalEntityInput = {
  registrationAddress: Scalars['String'];
  accountNumber: Scalars['String'];
  bankTitle: Scalars['String'];
  bic: Scalars['String'];
  tittle: Scalars['String'];
  inn: Scalars['String'];
};

export type NewTeacherInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  phone: Scalars['String'];
  siteDisplay?: Maybe<Scalars['Boolean']>;
};

export type AddNewRoleTeacher = {
  __typename?: 'AddNewRoleTeacher';
  success?: Maybe<Scalars['Boolean']>;
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  teacher?: Maybe<TeacherType>;
};

export type AddTeacherExist = {
  __typename?: 'AddTeacherExist';
  success?: Maybe<Scalars['Boolean']>;
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  application?: Maybe<ApplicationType>;
};

export type ExistEmploymentInputType = {
  individualEntrepreneur?: Maybe<ExistIndividualEntrepreneurInput>;
  selfEmployed?: Maybe<ExistSelfEmployedInput>;
  naturalPerson?: Maybe<ExistNaturalPersonInput>;
  legalEntity?: Maybe<ExistLegalEntityInput>;
};

export type ExistIndividualEntrepreneurInput = {
  registrationAddress: Scalars['String'];
  accountNumber: Scalars['String'];
  bankTitle: Scalars['String'];
  bic: Scalars['String'];
  inn: Scalars['String'];
  existId?: Maybe<Scalars['ID']>;
};

export type ExistSelfEmployedInput = {
  registrationAddress: Scalars['String'];
  accountNumber: Scalars['String'];
  bankTitle: Scalars['String'];
  bic: Scalars['String'];
  nationality: Scalars['ID'];
  passportNumber: Scalars['String'];
  inn: Scalars['String'];
  existId?: Maybe<Scalars['ID']>;
};

export type ExistNaturalPersonInput = {
  registrationAddress: Scalars['String'];
  accountNumber: Scalars['String'];
  bankTitle: Scalars['String'];
  bic: Scalars['String'];
  nationality: Scalars['ID'];
  passportNumber: Scalars['String'];
  existId?: Maybe<Scalars['ID']>;
};

export type ExistLegalEntityInput = {
  registrationAddress: Scalars['String'];
  accountNumber: Scalars['String'];
  bankTitle: Scalars['String'];
  bic: Scalars['String'];
  tittle: Scalars['String'];
  inn: Scalars['String'];
  existId?: Maybe<Scalars['ID']>;
};

export type AddTeacherApplication = {
  __typename?: 'AddTeacherApplication';
  success?: Maybe<Scalars['Boolean']>;
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  application?: Maybe<ApplicationType>;
};

export type UpdateTeacherApplication = {
  __typename?: 'UpdateTeacherApplication';
  success?: Maybe<Scalars['Boolean']>;
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  application?: Maybe<ApplicationType>;
};

export type UpdateApplicationInputType = {
  teacherType: EmploymentTypes;
  title: Scalars['String'];
  date: Scalars['Date'];
  group: Scalars['ID'];
  lessonsCount: Scalars['Int'];
  country?: Maybe<Scalars['ID']>;
  servicePrice: Scalars['Float'];
  riaPrice?: Maybe<Scalars['Float']>;
  currency: Currencies;
  comment?: Maybe<Scalars['String']>;
  applicationId: Scalars['ID'];
  isTerminated: Scalars['Boolean'];
  lastLessonDate?: Maybe<Scalars['Date']>;
};

export type DeleteTeacherApplication = {
  __typename?: 'DeleteTeacherApplication';
  success?: Maybe<Scalars['Boolean']>;
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
};

export type UpdateTeacherWithTypeMutation = {
  __typename?: 'UpdateTeacherWithTypeMutation';
  success?: Maybe<Scalars['Boolean']>;
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  teacher?: Maybe<TeacherType>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
};

export type TeacherWithTypeInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  phone: Scalars['String'];
  siteDisplay?: Maybe<Scalars['Boolean']>;
  accessToLms?: Maybe<Scalars['Boolean']>;
};

export type UploadResume = {
  __typename?: 'UploadResume';
  success?: Maybe<Scalars['Boolean']>;
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  resume?: Maybe<StudentResumeType>;
};


export type ResumeInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  additionalInfo?: Maybe<Scalars['String']>;
  language: LanguageEnum;
  tools?: Maybe<Array<Maybe<Scalars['String']>>>;
  skills?: Maybe<Array<Maybe<Scalars['String']>>>;
  cvLanguages?: Maybe<Array<Maybe<LanguagesInput>>>;
  education?: Maybe<Array<Maybe<EducationInput>>>;
  socialMedia?: Maybe<Array<Maybe<SocialMediaInput>>>;
  experience?: Maybe<Array<Maybe<WorkExperienceInput>>>;
};

/** An enumeration. */
export enum LanguageEnum {
  /** ENG */
  Eng = 'ENG',
  /** RUS */
  Rus = 'RUS'
}

export type LanguagesInput = {
  language: Scalars['String'];
  languageLevel: LanguagesLevelEnum;
};

/** An enumeration. */
export enum LanguagesLevelEnum {
  /** A1 Elementary */
  A1 = 'A1',
  /** A2 Pre-Intermediate */
  A2 = 'A2',
  /** B1 Intermediate */
  B1 = 'B1',
  /** B2 Upper-Intermediate */
  B2 = 'B2',
  /** C1 Advanced */
  C1 = 'C1',
  /** C2 Proficiency */
  C2 = 'C2',
  /** Native */
  Native = 'NATIVE'
}

export type EducationInput = {
  institution?: Maybe<Scalars['String']>;
  degree?: Maybe<Scalars['String']>;
  specialization?: Maybe<Scalars['String']>;
  startDate?: Maybe<CvDateInput>;
  endDate?: Maybe<CvDateInput>;
};

export type CvDateInput = {
  year: Scalars['String'];
  month?: Maybe<Scalars['String']>;
};

export type SocialMediaInput = {
  media: Scalars['ID'];
  socialLink: Scalars['String'];
};

export type WorkExperienceInput = {
  company?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  startDate?: Maybe<CvDateInput>;
  endDate?: Maybe<CvDateInput>;
  projects?: Maybe<Array<Maybe<ProjectsInput>>>;
};

export type ProjectsInput = {
  projectName: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  technologies?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CreatePaymentsMutation = {
  __typename?: 'CreatePaymentsMutation';
  success?: Maybe<Scalars['Boolean']>;
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  payments?: Maybe<Array<Maybe<PaymentType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
};

export type PaymentInput = {
  date: Scalars['Date'];
  sum: Scalars['Float'];
  currency: Scalars['String'];
  agreement_id: Scalars['ID'];
  deferredPaymentId?: Maybe<Scalars['ID']>;
};

export type CreateDeferredPaymentsMutation = {
  __typename?: 'CreateDeferredPaymentsMutation';
  success?: Maybe<Scalars['Boolean']>;
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  deferredPayments?: Maybe<Array<Maybe<DeferredPaymentType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
};

export type DeferredPaymentInput = {
  userInput: Scalars['String'];
  agreementTitle?: Maybe<Scalars['String']>;
  sum?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  studentFullname?: Maybe<Scalars['String']>;
  date: Scalars['Date'];
  deferredPaymentId?: Maybe<Scalars['ID']>;
};

export type CheckStudentsDebtMutation = {
  __typename?: 'CheckStudentsDebtMutation';
  success?: Maybe<Scalars['Boolean']>;
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
};

export type StudentWithAgreementUpdateMutation = {
  __typename?: 'StudentWithAgreementUpdateMutation';
  success?: Maybe<Scalars['Boolean']>;
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  student?: Maybe<StudentType>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
};

export type UpdateStudentInput = {
  student: StudentInput;
  agreement: AgreementInput;
  company?: Maybe<SetCompanyInput>;
  contributions?: Maybe<Array<Maybe<ContributionInput>>>;
  chargeback?: Maybe<ChargebackInput>;
  accountsPayable?: Maybe<AccountsPayableInput>;
  usedPayables?: Maybe<Array<Scalars['ID']>>;
  studentLessons?: Maybe<AgreementLessonsInput>;
};

export type StudentInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  phone: Scalars['String'];
};

export type AgreementInput = {
  agreementTitle: Scalars['String'];
  currency: Scalars['String'];
  paymentByCompany?: Maybe<Scalars['Boolean']>;
  partialAttendance?: Maybe<Scalars['Boolean']>;
  startAgreementBudget?: Maybe<Scalars['Float']>;
  countryId?: Maybe<Scalars['ID']>;
  promocode?: Maybe<Scalars['String']>;
  group_id: Scalars['ID'];
  vat?: Maybe<Scalars['Boolean']>;
  resident?: Maybe<Scalars['Boolean']>;
  terminated?: Maybe<Scalars['Boolean']>;
  date?: Maybe<Scalars['Date']>;
  comment?: Maybe<Scalars['String']>;
  terminatedDate?: Maybe<Scalars['Date']>;
};

export type SetCompanyInput = {
  companyId?: Maybe<Scalars['ID']>;
  companyComment?: Maybe<Scalars['String']>;
};

export type ContributionInput = {
  date: Scalars['Date'];
  sum: Scalars['Float'];
};

export type ChargebackInput = {
  date: Scalars['Date'];
  sum: Scalars['Float'];
  currency: Scalars['String'];
};

export type AccountsPayableInput = {
  date: Scalars['Date'];
  accountsPayable?: Maybe<Scalars['Float']>;
};

export type AgreementLessonsInput = {
  startLessonId: Scalars['Int'];
  lastLessonId: Scalars['Int'];
};

export type StudentWithAgreementCreateMutation = {
  __typename?: 'StudentWithAgreementCreateMutation';
  success?: Maybe<Scalars['Boolean']>;
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  agreement?: Maybe<AgreementType>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
};

export type CreateAgreementInputBase = {
  agreementTitle: Scalars['String'];
  currency: Scalars['String'];
  paymentByCompany?: Maybe<Scalars['Boolean']>;
  partialAttendance?: Maybe<Scalars['Boolean']>;
  startAgreementBudget?: Maybe<Scalars['Float']>;
  countryId?: Maybe<Scalars['ID']>;
  promocode?: Maybe<Scalars['String']>;
  date: Scalars['Date'];
  groupId: Scalars['ID'];
  vat?: Maybe<Scalars['Boolean']>;
  resident?: Maybe<Scalars['Boolean']>;
  companyId?: Maybe<Scalars['ID']>;
  companyComment?: Maybe<Scalars['String']>;
  contributions?: Maybe<Array<Maybe<ContributionInput>>>;
  firstLesson?: Maybe<Scalars['ID']>;
  lastLesson?: Maybe<Scalars['ID']>;
  usedPayables?: Maybe<Array<Scalars['ID']>>;
};

export type AddRoleStudentAndAgreement = {
  __typename?: 'AddRoleStudentAndAgreement';
  success?: Maybe<Scalars['Boolean']>;
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  agreement?: Maybe<AgreementType>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
};

export type StudentSignUpLmsMutation = {
  __typename?: 'StudentSignUpLMSMutation';
  success?: Maybe<Scalars['Boolean']>;
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  student?: Maybe<StudentType>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
};

export type StudentSignUpInput = {
  firstName: Scalars['String'];
  phone: Scalars['String'];
  email: Scalars['String'];
  recaptchaToken: Scalars['String'];
};

export type CreateAgreementMutation = {
  __typename?: 'CreateAgreementMutation';
  success?: Maybe<Scalars['Boolean']>;
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  agreement?: Maybe<AgreementType>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
};

export type CreateAgreementInput = {
  agreementTitle: Scalars['String'];
  currency: Scalars['String'];
  paymentByCompany?: Maybe<Scalars['Boolean']>;
  partialAttendance?: Maybe<Scalars['Boolean']>;
  startAgreementBudget?: Maybe<Scalars['Float']>;
  countryId?: Maybe<Scalars['ID']>;
  promocode?: Maybe<Scalars['String']>;
  date: Scalars['Date'];
  groupId: Scalars['ID'];
  vat?: Maybe<Scalars['Boolean']>;
  resident?: Maybe<Scalars['Boolean']>;
  companyId?: Maybe<Scalars['ID']>;
  companyComment?: Maybe<Scalars['String']>;
  contributions?: Maybe<Array<Maybe<ContributionInput>>>;
  firstLesson?: Maybe<Scalars['ID']>;
  lastLesson?: Maybe<Scalars['ID']>;
  usedPayables?: Maybe<Array<Scalars['ID']>>;
  studentId: Scalars['ID'];
};

export type CreateCompanyMutation = {
  __typename?: 'CreateCompanyMutation';
  success?: Maybe<Scalars['Boolean']>;
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  company?: Maybe<CompanyType>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
};

export type CompanyInput = {
  name: Scalars['String'];
  responsiblePerson: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  registrationNumber: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
};

export type UpdateCompanyMutation = {
  __typename?: 'UpdateCompanyMutation';
  success?: Maybe<Scalars['Boolean']>;
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  company?: Maybe<CompanyType>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
};

export type UpdateCompanyInput = {
  name: Scalars['String'];
  responsiblePerson: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  comment: Scalars['String'];
};

export type RegisterMutation = {
  __typename?: 'RegisterMutation';
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
};

export type CustomSendPasswordResetEmail = {
  __typename?: 'CustomSendPasswordResetEmail';
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
};

export type SendAuthenticationCode = {
  __typename?: 'SendAuthenticationCode';
  success?: Maybe<Scalars['Boolean']>;
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type LmsAuthenticationInput = {
  email: Scalars['String'];
  scope: Scalars['String'];
};

/**
 * Change user password without old password.
 * 
 * Receive the token that was sent by email.
 * 
 * If token and new passwords are valid, update
 * user password and in case of using refresh
 * tokens, revoke all of them.
 * 
 * Also, if user has not been verified yet, verify it.
 */
export type PasswordReset = {
  __typename?: 'PasswordReset';
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
};

/**
 * Set user password - for passwordless registration
 * 
 * Receive the token that was sent by email.
 * 
 * If token and new passwords are valid, set
 * user password and in case of using refresh
 * tokens, revoke all of them.
 * 
 * Also, if user has not been verified yet, verify it.
 */
export type PasswordSet = {
  __typename?: 'PasswordSet';
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
};

/**
 * Obtain JSON web token for given user.
 * 
 * Allow to perform login with different fields,
 * and secondary email if set. The fields are
 * defined on settings.
 * 
 * Not verified users can login by default. This
 * can be changes on settings.
 * 
 * If user is archived, make it unarchive and
 * return `unarchiving=True` on output.
 */
export type CustomObtainJsonWebToken = {
  __typename?: 'CustomObtainJSONWebToken';
  token?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  user?: Maybe<UserNode>;
  unarchiving?: Maybe<Scalars['Boolean']>;
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  refreshToken?: Maybe<Scalars['String']>;
};

/** Same as `grapgql_jwt` implementation, with standard output. */
export type VerifyToken = {
  __typename?: 'VerifyToken';
  payload?: Maybe<Scalars['GenericScalar']>;
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
};


/** Same as `grapgql_jwt` implementation, with standard output. */
export type CustomRefreshToken = {
  __typename?: 'CustomRefreshToken';
  token?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['GenericScalar']>;
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  refreshToken?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum LmsRoles {
  /** Teacher */
  Teacher = 'TEACHER',
  /** Student */
  Student = 'STUDENT',
  /** Methodist */
  Methodist = 'METHODIST'
}

/** Same as `grapgql_jwt` implementation, with standard output. */
export type RevokeToken = {
  __typename?: 'RevokeToken';
  revoked?: Maybe<Scalars['Int']>;
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
};

export type UserSettingsUpdateMutation = {
  __typename?: 'UserSettingsUpdateMutation';
  success?: Maybe<Scalars['Boolean']>;
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  user?: Maybe<UserNode>;
};

export type CustomUserInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  phone: Scalars['String'];
  roles: Scalars['String'];
};

export type UserSettingsDeleteMutation = {
  __typename?: 'UserSettingsDeleteMutation';
  success?: Maybe<Scalars['Boolean']>;
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
};

export type UserSettingsRolesAddMutation = {
  __typename?: 'UserSettingsRolesAddMutation';
  success?: Maybe<Scalars['Boolean']>;
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  user?: Maybe<CustomUserType>;
};

export type AppState = {
  __typename?: 'appState';
  bootstrapped: Scalars['Boolean'];
};

export type AuthState = {
  __typename?: 'authState';
  isLoggedIn: Scalars['Boolean'];
  access_token: Scalars['String'];
};

export type UserFieldsFragment = (
  { __typename?: 'UserNode' }
  & Pick<UserNode, 'id' | 'username' | 'isActive' | 'firstName' | 'middleName' | 'lastName' | 'email' | 'roles' | 'verified' | 'isStaff' | 'dateJoined' | 'pk' | 'archived' | 'secondaryEmail' | 'lastLogin' | 'phone'>
);

export type AppendTeacherApplicationMutationVariables = Exact<{
  application: ApplicationInputType;
  teacherId: Scalars['ID'];
}>;


export type AppendTeacherApplicationMutation = (
  { __typename?: 'Mutation' }
  & { addTeacherApplication?: Maybe<(
    { __typename?: 'AddTeacherApplication' }
    & Pick<AddTeacherApplication, 'success'>
    & { customErrors?: Maybe<Array<Maybe<(
      { __typename?: 'CustomErrorType' }
      & Pick<CustomErrorType, 'field' | 'message' | 'code'>
    )>>> }
  )> }
);

export type UpdateTeacherApplicationMutationVariables = Exact<{
  application: UpdateApplicationInputType;
}>;


export type UpdateTeacherApplicationMutation = (
  { __typename?: 'Mutation' }
  & { updateTeacherApplication?: Maybe<(
    { __typename?: 'UpdateTeacherApplication' }
    & Pick<UpdateTeacherApplication, 'success'>
    & { customErrors?: Maybe<Array<Maybe<(
      { __typename?: 'CustomErrorType' }
      & Pick<CustomErrorType, 'field' | 'message' | 'code'>
    )>>> }
  )> }
);

export type RemoveApplicationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveApplicationMutation = (
  { __typename?: 'Mutation' }
  & { deleteTeacherApplication?: Maybe<(
    { __typename?: 'DeleteTeacherApplication' }
    & Pick<DeleteTeacherApplication, 'success' | 'errors'>
  )> }
);

export type RefreshTokenMutationVariables = Exact<{
  refresh_token: Scalars['String'];
  scope: Scalars['String'];
}>;


export type RefreshTokenMutation = (
  { __typename?: 'Mutation' }
  & { refreshToken?: Maybe<(
    { __typename?: 'CustomRefreshToken' }
    & Pick<CustomRefreshToken, 'token' | 'refreshToken' | 'payload'>
  )> }
);

export type SendPasswordResetEmailMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type SendPasswordResetEmailMutation = (
  { __typename?: 'Mutation' }
  & { sendPasswordResetEmail?: Maybe<(
    { __typename?: 'CustomSendPasswordResetEmail' }
    & Pick<CustomSendPasswordResetEmail, 'success' | 'errors'>
  )> }
);

export type PasswordSetMutationVariables = Exact<{
  newPassword1: Scalars['String'];
  newPassword2: Scalars['String'];
  token: Scalars['String'];
}>;


export type PasswordSetMutation = (
  { __typename?: 'Mutation' }
  & { passwordSet?: Maybe<(
    { __typename?: 'PasswordSet' }
    & Pick<PasswordSet, 'success' | 'errors'>
  )> }
);

export type PasswordResetMutationVariables = Exact<{
  newPassword1: Scalars['String'];
  newPassword2: Scalars['String'];
  token: Scalars['String'];
}>;


export type PasswordResetMutation = (
  { __typename?: 'Mutation' }
  & { passwordReset?: Maybe<(
    { __typename?: 'PasswordReset' }
    & Pick<PasswordReset, 'success' | 'errors'>
  )> }
);

export type VerifyTokenMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type VerifyTokenMutation = (
  { __typename?: 'Mutation' }
  & { verifyToken?: Maybe<(
    { __typename?: 'VerifyToken' }
    & Pick<VerifyToken, 'success' | 'errors'>
  )> }
);

export type LoginMutationVariables = Exact<{
  password: Scalars['String'];
  email: Scalars['String'];
  scope: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { customLogin?: Maybe<(
    { __typename?: 'CustomObtainJSONWebToken' }
    & Pick<CustomObtainJsonWebToken, 'success' | 'errors' | 'token' | 'refreshToken'>
    & { user?: Maybe<(
      { __typename?: 'UserNode' }
      & UserFieldsFragment
    )> }
  )> }
);

export type AddCompanyMutationVariables = Exact<{
  companyInput: CompanyInput;
}>;


export type AddCompanyMutation = (
  { __typename?: 'Mutation' }
  & { createCompany?: Maybe<(
    { __typename?: 'CreateCompanyMutation' }
    & Pick<CreateCompanyMutation, 'success' | 'errors'>
    & { customErrors?: Maybe<Array<Maybe<(
      { __typename?: 'CustomErrorType' }
      & Pick<CustomErrorType, 'field' | 'message' | 'code'>
    )>>> }
  )> }
);

export type EditCompanyMutationVariables = Exact<{
  companyId: Scalars['ID'];
  companyInput: UpdateCompanyInput;
}>;


export type EditCompanyMutation = (
  { __typename?: 'Mutation' }
  & { updateCompany?: Maybe<(
    { __typename?: 'UpdateCompanyMutation' }
    & Pick<UpdateCompanyMutation, 'success'>
    & { company?: Maybe<(
      { __typename?: 'CompanyType' }
      & Pick<CompanyType, 'id' | 'name' | 'responsiblePerson' | 'email' | 'phone' | 'registrationNumber' | 'comment'>
    )>, customErrors?: Maybe<Array<Maybe<(
      { __typename?: 'CustomErrorType' }
      & Pick<CustomErrorType, 'field' | 'message' | 'code'>
    )>>> }
  )> }
);

export type AddPaymentsMutationVariables = Exact<{
  payments?: Maybe<Array<Maybe<PaymentInput>>>;
}>;


export type AddPaymentsMutation = (
  { __typename?: 'Mutation' }
  & { createPayments?: Maybe<(
    { __typename?: 'CreatePaymentsMutation' }
    & Pick<CreatePaymentsMutation, 'success' | 'errors'>
  )> }
);

export type AddDeferredPaymentsMutationVariables = Exact<{
  payments?: Maybe<Array<Maybe<DeferredPaymentInput>>>;
}>;


export type AddDeferredPaymentsMutation = (
  { __typename?: 'Mutation' }
  & { createDeferredPayments?: Maybe<(
    { __typename?: 'CreateDeferredPaymentsMutation' }
    & Pick<CreateDeferredPaymentsMutation, 'success'>
    & { customErrors?: Maybe<Array<Maybe<(
      { __typename?: 'CustomErrorType' }
      & Pick<CustomErrorType, 'field' | 'message' | 'code'>
    )>>> }
  )> }
);

export type CheckPaymentsDebtMutationVariables = Exact<{
  agreements: Array<Maybe<Scalars['String']>>;
}>;


export type CheckPaymentsDebtMutation = (
  { __typename?: 'Mutation' }
  & { checkStudentDebt?: Maybe<(
    { __typename?: 'CheckStudentsDebtMutation' }
    & Pick<CheckStudentsDebtMutation, 'success'>
    & { customErrors?: Maybe<Array<Maybe<(
      { __typename?: 'CustomErrorType' }
      & Pick<CustomErrorType, 'code' | 'field' | 'message'>
    )>>> }
  )> }
);

export type RemoveGroupMutationVariables = Exact<{
  groupId: Scalars['Int'];
}>;


export type RemoveGroupMutation = (
  { __typename?: 'Mutation' }
  & { deleteGroup?: Maybe<(
    { __typename?: 'DeleteGroupMutation' }
    & Pick<DeleteGroupMutation, 'success' | 'errors'>
  )> }
);

export type PublishToLmsMutationVariables = Exact<{
  groupId: Scalars['ID'];
  publishedLms: Scalars['Boolean'];
  publishedForTeachers?: Maybe<Scalars['Boolean']>;
  publishedLmsForMethodist?: Maybe<Scalars['Boolean']>;
}>;


export type PublishToLmsMutation = (
  { __typename?: 'Mutation' }
  & { publishGroup?: Maybe<(
    { __typename?: 'PublishGroupToLMS' }
    & Pick<PublishGroupToLms, 'success' | 'errors'>
  )> }
);

export type AddGroupMutationVariables = Exact<{
  groupInput: GroupInput;
  moduleInput: Array<Maybe<ModuleInput>>;
}>;


export type AddGroupMutation = (
  { __typename?: 'Mutation' }
  & { createGroup?: Maybe<(
    { __typename?: 'CreateGroupMutation' }
    & Pick<CreateGroupMutation, 'success' | 'errors'>
    & { customErrors?: Maybe<Array<Maybe<(
      { __typename?: 'CustomErrorType' }
      & Pick<CustomErrorType, 'field' | 'message' | 'code'>
    )>>> }
  )> }
);

export type ChangeGroupMutationVariables = Exact<{
  groupId: Scalars['Int'];
  groupInput: UpdateGroupInput;
}>;


export type ChangeGroupMutation = (
  { __typename?: 'Mutation' }
  & { updateGroup?: Maybe<(
    { __typename?: 'UpdateGroupMutation' }
    & Pick<UpdateGroupMutation, 'success' | 'errors'>
    & { customErrors?: Maybe<Array<Maybe<(
      { __typename?: 'CustomErrorType' }
      & Pick<CustomErrorType, 'field' | 'message' | 'code'>
    )>>> }
  )> }
);

export type ChangeAttendanceMutationVariables = Exact<{
  attendanceId: Scalars['Int'];
  attendance: Scalars['Boolean'];
}>;


export type ChangeAttendanceMutation = (
  { __typename?: 'Mutation' }
  & { updateAttendance?: Maybe<(
    { __typename?: 'UpdateAttendanceMutation' }
    & Pick<UpdateAttendanceMutation, 'success'>
    & { customErrors?: Maybe<Array<Maybe<(
      { __typename?: 'CustomErrorType' }
      & Pick<CustomErrorType, 'field' | 'message' | 'code'>
    )>>> }
  )> }
);

export type ChangeDateOfLessonMutationVariables = Exact<{
  groupId: Scalars['Int'];
  newDate: Scalars['Date'];
  oldDate: Scalars['Date'];
}>;


export type ChangeDateOfLessonMutation = (
  { __typename?: 'Mutation' }
  & { changeLessonDates?: Maybe<(
    { __typename?: 'ChangeLessonDate' }
    & Pick<ChangeLessonDate, 'success'>
    & { customErrors?: Maybe<Array<Maybe<(
      { __typename?: 'CustomErrorType' }
      & Pick<CustomErrorType, 'field' | 'message' | 'code'>
    )>>> }
  )> }
);

export type ChangeJournalMutationVariables = Exact<{
  groupId: Scalars['Int'];
  daysOfClass: Array<DayOfClassInput>;
  modules: Array<Maybe<UpdateModuleInput>>;
  comment: Scalars['String'];
}>;


export type ChangeJournalMutation = (
  { __typename?: 'Mutation' }
  & { updateJournal?: Maybe<(
    { __typename?: 'UpdateJournalsMutation' }
    & Pick<UpdateJournalsMutation, 'success'>
    & { customErrors?: Maybe<Array<Maybe<(
      { __typename?: 'CustomErrorType' }
      & Pick<CustomErrorType, 'field' | 'message' | 'code'>
    )>>> }
  )> }
);

export type AddAgreementMutationVariables = Exact<{
  agreementInput: CreateAgreementInput;
}>;


export type AddAgreementMutation = (
  { __typename?: 'Mutation' }
  & { createAgreement?: Maybe<(
    { __typename?: 'CreateAgreementMutation' }
    & Pick<CreateAgreementMutation, 'success'>
    & { customErrors?: Maybe<Array<Maybe<(
      { __typename?: 'CustomErrorType' }
      & Pick<CustomErrorType, 'field' | 'message' | 'code'>
    )>>> }
  )> }
);

export type EditStudentWithAgreementMutationVariables = Exact<{
  agreementId: Scalars['ID'];
  updateStudentInput: UpdateStudentInput;
}>;


export type EditStudentWithAgreementMutation = (
  { __typename?: 'Mutation' }
  & { updateStudentWithAgreement?: Maybe<(
    { __typename?: 'StudentWithAgreementUpdateMutation' }
    & Pick<StudentWithAgreementUpdateMutation, 'success'>
    & { customErrors?: Maybe<Array<Maybe<(
      { __typename?: 'CustomErrorType' }
      & Pick<CustomErrorType, 'field' | 'message' | 'code'>
    )>>> }
  )> }
);

export type AddRoleAndAgreementMutationVariables = Exact<{
  agreementInput: CreateAgreementInputBase;
  userId: Scalars['ID'];
}>;


export type AddRoleAndAgreementMutation = (
  { __typename?: 'Mutation' }
  & { appendStudentRoleAndAgreement?: Maybe<(
    { __typename?: 'AddRoleStudentAndAgreement' }
    & Pick<AddRoleStudentAndAgreement, 'success'>
    & { customErrors?: Maybe<Array<Maybe<(
      { __typename?: 'CustomErrorType' }
      & Pick<CustomErrorType, 'field' | 'message' | 'code'>
    )>>> }
  )> }
);

export type AddStudentWithAgreementMutationVariables = Exact<{
  agreementInput: CreateAgreementInputBase;
  studentInput: StudentInput;
}>;


export type AddStudentWithAgreementMutation = (
  { __typename?: 'Mutation' }
  & { createStudentWithAgreement?: Maybe<(
    { __typename?: 'StudentWithAgreementCreateMutation' }
    & Pick<StudentWithAgreementCreateMutation, 'success'>
    & { customErrors?: Maybe<Array<Maybe<(
      { __typename?: 'CustomErrorType' }
      & Pick<CustomErrorType, 'field' | 'message' | 'code'>
    )>>> }
  )> }
);

export type AddUniqueTeacherMutationVariables = Exact<{
  application: ApplicationInputType;
  employmentTypes: EmploymentInputType;
  teacher: NewTeacherInput;
}>;


export type AddUniqueTeacherMutation = (
  { __typename?: 'Mutation' }
  & { createUniqueTeacher?: Maybe<(
    { __typename?: 'CreateUniqueTeacher' }
    & Pick<CreateUniqueTeacher, 'success'>
    & { customErrors?: Maybe<Array<Maybe<(
      { __typename?: 'CustomErrorType' }
      & Pick<CustomErrorType, 'field' | 'message' | 'code'>
    )>>> }
  )> }
);

export type AddNewTeacherRoleMutationVariables = Exact<{
  application: ApplicationInputType;
  employmentTypes: EmploymentInputType;
  siteDisplay?: Maybe<Scalars['Boolean']>;
  userId: Scalars['ID'];
}>;


export type AddNewTeacherRoleMutation = (
  { __typename?: 'Mutation' }
  & { addTeacherRole?: Maybe<(
    { __typename?: 'AddNewRoleTeacher' }
    & Pick<AddNewRoleTeacher, 'success'>
    & { customErrors?: Maybe<Array<Maybe<(
      { __typename?: 'CustomErrorType' }
      & Pick<CustomErrorType, 'field' | 'message' | 'code'>
    )>>> }
  )> }
);

export type AddTeacherExistMutationVariables = Exact<{
  application: ApplicationInputType;
  employmentTypes: ExistEmploymentInputType;
  teacherId: Scalars['ID'];
}>;


export type AddTeacherExistMutation = (
  { __typename?: 'Mutation' }
  & { addTeacherExist?: Maybe<(
    { __typename?: 'AddTeacherExist' }
    & Pick<AddTeacherExist, 'success'>
    & { customErrors?: Maybe<Array<Maybe<(
      { __typename?: 'CustomErrorType' }
      & Pick<CustomErrorType, 'field' | 'message' | 'code'>
    )>>> }
  )> }
);

export type EditTeacherWithTypeMutationVariables = Exact<{
  employmentInput: ExistEmploymentInputType;
  teacherId?: Maybe<Scalars['ID']>;
  teacherInput: TeacherWithTypeInput;
}>;


export type EditTeacherWithTypeMutation = (
  { __typename?: 'Mutation' }
  & { updateTeacherWithType?: Maybe<(
    { __typename?: 'UpdateTeacherWithTypeMutation' }
    & Pick<UpdateTeacherWithTypeMutation, 'success' | 'errors'>
    & { customErrors?: Maybe<Array<Maybe<(
      { __typename?: 'CustomErrorType' }
      & Pick<CustomErrorType, 'field' | 'message' | 'code'>
    )>>> }
  )> }
);

export type CreateUserMutationVariables = Exact<{
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName: Scalars['String'];
  email: Scalars['String'];
  roles: Scalars['String'];
  phone: Scalars['String'];
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser?: Maybe<(
    { __typename?: 'RegisterMutation' }
    & Pick<RegisterMutation, 'success' | 'errors'>
    & { customErrors?: Maybe<Array<Maybe<(
      { __typename?: 'CustomErrorType' }
      & Pick<CustomErrorType, 'field' | 'message' | 'code'>
    )>>> }
  )> }
);

export type UpdateUserSettingsMutationVariables = Exact<{
  pk: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName: Scalars['String'];
  email: Scalars['String'];
  roles: Scalars['String'];
  phone: Scalars['String'];
}>;


export type UpdateUserSettingsMutation = (
  { __typename?: 'Mutation' }
  & { updateUserSettings?: Maybe<(
    { __typename?: 'UserSettingsUpdateMutation' }
    & { user?: Maybe<(
      { __typename?: 'UserNode' }
      & Pick<UserNode, 'id'>
    )>, customErrors?: Maybe<Array<Maybe<(
      { __typename?: 'CustomErrorType' }
      & Pick<CustomErrorType, 'field' | 'message' | 'code'>
    )>>> }
  )> }
);

export type DeleteUserMutationVariables = Exact<{
  pk: Scalars['ID'];
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & { deleteUserSettings?: Maybe<(
    { __typename?: 'UserSettingsDeleteMutation' }
    & Pick<UserSettingsDeleteMutation, 'success' | 'errors'>
  )> }
);

export type AddUserRolesMutationVariables = Exact<{
  roles: Scalars['String'];
  userId: Scalars['ID'];
}>;


export type AddUserRolesMutation = (
  { __typename?: 'Mutation' }
  & { appendRolesUserSettings?: Maybe<(
    { __typename?: 'UserSettingsRolesAddMutation' }
    & { user?: Maybe<(
      { __typename?: 'CustomUserType' }
      & Pick<CustomUserType, 'id'>
    )> }
  )> }
);

export type PaginatedTeachersApplicationsQueryVariables = Exact<{
  query?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}>;


export type PaginatedTeachersApplicationsQuery = (
  { __typename?: 'Query' }
  & { allTeachersApplications?: Maybe<(
    { __typename?: 'ApplicationFilterTypeConnection' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ), edges: Array<Maybe<(
      { __typename?: 'ApplicationFilterTypeEdge' }
      & { node?: Maybe<(
        { __typename?: 'ApplicationType' }
        & Pick<ApplicationType, 'id' | 'title' | 'lessonsCount' | 'servicePrice' | 'currency' | 'isTerminated' | 'isCompleted' | 'appointedLessonsCount'>
        & { teacher: (
          { __typename?: 'TeacherFilterType' }
          & Pick<TeacherFilterType, 'fullName'>
        ), group: (
          { __typename?: 'MethodistGroupType' }
          & Pick<MethodistGroupType, 'name'>
        ), employmentType?: Maybe<{ __typename: 'IndividualEntrepreneurType' } | { __typename: 'LegalEntityType' } | { __typename: 'SelfEmployedType' } | { __typename: 'NaturalPersonType' }> }
      )> }
    )>> }
  )> }
);

export type GetApplicationsForGroupQueryVariables = Exact<{
  byGroupId?: Maybe<Scalars['Decimal']>;
  isTerminated?: Maybe<Scalars['Boolean']>;
  groupsFilter?: Maybe<Scalars['Boolean']>;
}>;


export type GetApplicationsForGroupQuery = (
  { __typename?: 'Query' }
  & { allTeachersApplications?: Maybe<(
    { __typename?: 'ApplicationFilterTypeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'ApplicationFilterTypeEdge' }
      & { node?: Maybe<(
        { __typename?: 'ApplicationType' }
        & Pick<ApplicationType, 'id' | 'title' | 'lessonsCount' | 'appointedLessonsCount'>
        & { teacher: (
          { __typename?: 'TeacherFilterType' }
          & Pick<TeacherFilterType, 'fullName'>
        ) }
      )> }
    )>> }
  )> }
);

export type LoadEditingApplicationQueryVariables = Exact<{
  applicationId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
}>;


export type LoadEditingApplicationQuery = (
  { __typename?: 'Query' }
  & { teacherApplication?: Maybe<(
    { __typename?: 'ApplicationType' }
    & Pick<ApplicationType, 'id' | 'title' | 'date' | 'lessonsCount' | 'servicePrice' | 'riaPrice' | 'currency' | 'comment' | 'isTerminated' | 'lastLessonDate' | 'isCompleted' | 'deleteAvailable' | 'appointedLessonsCount'>
    & { country?: Maybe<(
      { __typename?: 'CountryType' }
      & Pick<CountryType, 'id' | 'title'>
    )>, teacher: (
      { __typename?: 'TeacherFilterType' }
      & Pick<TeacherFilterType, 'fullName'>
      & { employmentTypes?: Maybe<Array<Maybe<{ __typename: 'IndividualEntrepreneurType' } | { __typename: 'LegalEntityType' } | { __typename: 'SelfEmployedType' } | { __typename: 'NaturalPersonType' }>>> }
    ), group: (
      { __typename?: 'MethodistGroupType' }
      & Pick<MethodistGroupType, 'id' | 'name' | 'freeLessonsCount' | 'startDate'>
      & { course: (
        { __typename?: 'CourseFilterType' }
        & Pick<CourseFilterType, 'id' | 'name'>
      ) }
    ), lessons: (
      { __typename?: 'GroupLessonTypeConnection' }
      & { edges: Array<Maybe<(
        { __typename?: 'GroupLessonTypeEdge' }
        & { node?: Maybe<(
          { __typename?: 'GroupLessonType' }
          & Pick<GroupLessonType, 'date'>
        )> }
      )>> }
    ), employmentType?: Maybe<{ __typename: 'IndividualEntrepreneurType' } | { __typename: 'LegalEntityType' } | { __typename: 'SelfEmployedType' } | { __typename: 'NaturalPersonType' }> }
  )> }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'UserNode' }
    & UserFieldsFragment
  )> }
);

export type GetAllCompaniesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllCompaniesQuery = (
  { __typename?: 'Query' }
  & { allCompanies?: Maybe<(
    { __typename?: 'CompanyConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'CompanyEdge' }
      & { node?: Maybe<(
        { __typename?: 'CompanyType' }
        & Pick<CompanyType, 'id' | 'name' | 'comment'>
      )> }
    )>> }
  )> }
);

export type GetCompanyByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetCompanyByIdQuery = (
  { __typename?: 'Query' }
  & { company?: Maybe<(
    { __typename?: 'CompanyType' }
    & Pick<CompanyType, 'id' | 'name' | 'responsiblePerson' | 'email' | 'phone' | 'registrationNumber' | 'comment'>
  )> }
);

export type GetCountriesForTeachersQueryVariables = Exact<{
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}>;


export type GetCountriesForTeachersQuery = (
  { __typename?: 'Query' }
  & { allCountries?: Maybe<(
    { __typename?: 'CountryConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'CountryEdge' }
      & { node?: Maybe<(
        { __typename?: 'CountryType' }
        & Pick<CountryType, 'id' | 'title'>
      )> }
    )>> }
  )> }
);

export type PaginatedCoursesQueryVariables = Exact<{
  query?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
}>;


export type PaginatedCoursesQuery = (
  { __typename?: 'Query' }
  & { allCourses?: Maybe<(
    { __typename?: 'CourseFilterTypeConnection' }
    & Pick<CourseFilterTypeConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ), edges: Array<Maybe<(
      { __typename?: 'CourseFilterTypeEdge' }
      & Pick<CourseFilterTypeEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'CourseType' }
        & Pick<CourseType, 'id' | 'name' | 'educationForm'>
        & { category?: Maybe<(
          { __typename?: 'CategoryType' }
          & Pick<CategoryType, 'name'>
        )> }
      )> }
    )>> }
  )> }
);

export type GetCoursesForOptionsQueryVariables = Exact<{
  agreementDate: Scalars['Date'];
}>;


export type GetCoursesForOptionsQuery = (
  { __typename?: 'Query' }
  & { allCourses?: Maybe<(
    { __typename?: 'CourseFilterTypeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'CourseFilterTypeEdge' }
      & { node?: Maybe<(
        { __typename?: 'CourseType' }
        & Pick<CourseType, 'id' | 'name'>
        & { groups: (
          { __typename?: 'MethodistGroupTypeConnection' }
          & { edges: Array<Maybe<(
            { __typename?: 'MethodistGroupTypeEdge' }
            & { node?: Maybe<(
              { __typename?: 'MethodistGroupType' }
              & Pick<MethodistGroupType, 'id' | 'name' | 'price' | 'currency'>
            )> }
          )>> }
        ) }
      )> }
    )>> }
  )> }
);

export type GetCoursesForTeacherQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
}>;


export type GetCoursesForTeacherQuery = (
  { __typename?: 'Query' }
  & { allCourses?: Maybe<(
    { __typename?: 'CourseFilterTypeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'CourseFilterTypeEdge' }
      & { node?: Maybe<(
        { __typename?: 'CourseType' }
        & Pick<CourseType, 'id' | 'name'>
      )> }
    )>> }
  )> }
);

export type GetCourseDataForGroupQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetCourseDataForGroupQuery = (
  { __typename?: 'Query' }
  & { course?: Maybe<(
    { __typename?: 'CourseType' }
    & Pick<CourseType, 'id' | 'name' | 'price' | 'currency'>
    & { teachers?: Maybe<(
      { __typename?: 'TeacherFilterTypeConnection' }
      & { edges: Array<Maybe<(
        { __typename?: 'TeacherFilterTypeEdge' }
        & { node?: Maybe<(
          { __typename?: 'TeacherType' }
          & Pick<TeacherType, 'id' | 'fullName'>
        )> }
      )>> }
    )>, modules: Array<(
      { __typename?: 'CourseModuleType' }
      & Pick<CourseModuleType, 'id' | 'name'>
      & { lessons: (
        { __typename?: 'CourseLessonTypeConnection' }
        & { edges: Array<Maybe<(
          { __typename?: 'CourseLessonTypeEdge' }
          & { node?: Maybe<(
            { __typename?: 'CourseLessonType' }
            & Pick<CourseLessonType, 'id' | 'index' | 'name' | 'lmsDescription' | 'taskTypes'>
            & { teacherAttachments: (
              { __typename?: 'TeacherLessonAttachmentsTypeConnection' }
              & { edges: Array<Maybe<(
                { __typename?: 'TeacherLessonAttachmentsTypeEdge' }
                & { node?: Maybe<(
                  { __typename?: 'TeacherLessonAttachmentsType' }
                  & Pick<TeacherLessonAttachmentsType, 'id'>
                )> }
              )>> }
            ), studentAttachments: (
              { __typename?: 'StudentLessonAttachmentsTypeConnection' }
              & { edges: Array<Maybe<(
                { __typename?: 'StudentLessonAttachmentsTypeEdge' }
                & { node?: Maybe<(
                  { __typename?: 'StudentLessonAttachmentsType' }
                  & Pick<StudentLessonAttachmentsType, 'id'>
                )> }
              )>> }
            ) }
          )> }
        )>> }
      ) }
    )> }
  )> }
);

export type GetCourseDataForApplicationQueryVariables = Exact<{
  id: Scalars['ID'];
  groupsDate: Scalars['Date'];
}>;


export type GetCourseDataForApplicationQuery = (
  { __typename?: 'Query' }
  & { course?: Maybe<(
    { __typename?: 'CourseType' }
    & { groups: (
      { __typename?: 'MethodistGroupTypeConnection' }
      & { edges: Array<Maybe<(
        { __typename?: 'MethodistGroupTypeEdge' }
        & { node?: Maybe<(
          { __typename?: 'MethodistGroupType' }
          & Pick<MethodistGroupType, 'id' | 'name' | 'freeLessonsCount'>
        )> }
      )>> }
    ) }
  )> }
);

export type PaginatedContributionsQueryVariables = Exact<{
  contributionNum?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
}>;


export type PaginatedContributionsQuery = (
  { __typename?: 'Query' }
  & { allContributions?: Maybe<(
    { __typename?: 'ContributionConnection' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ), edges: Array<Maybe<(
      { __typename?: 'ContributionEdge' }
      & Pick<ContributionEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'ContributionType' }
        & Pick<ContributionType, 'id' | 'date' | 'complete' | 'delay' | 'remainder' | 'amountByn' | 'amountForeign' | 'agreementTitle'>
        & { person?: Maybe<(
          { __typename?: 'Person' }
          & Pick<Person, 'firstName' | 'lastName' | 'middleName' | 'phone'>
        )>, agreement: (
          { __typename?: 'AgreementType' }
          & Pick<AgreementType, 'currency'>
        ), group?: Maybe<(
          { __typename?: 'GroupsType' }
          & Pick<GroupsType, 'id' | 'name'>
        )> }
      )> }
    )>> }
  )> }
);

export type DebtorsMetaQueryVariables = Exact<{
  contributionNum?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
}>;


export type DebtorsMetaQuery = (
  { __typename?: 'Query' }
  & { metaContributions?: Maybe<(
    { __typename?: 'MetaContribution' }
    & Pick<MetaContribution, 'totalCount'>
  )> }
);

export type GetContributionsMetaQueryVariables = Exact<{ [key: string]: never; }>;


export type GetContributionsMetaQuery = (
  { __typename?: 'Query' }
  & { metaContributions?: Maybe<(
    { __typename?: 'MetaContribution' }
    & Pick<MetaContribution, 'contributionsNums'>
  )> }
);

export type PaymentsVerificationQueryVariables = Exact<{
  contracts: Array<Maybe<Scalars['String']>>;
}>;


export type PaymentsVerificationQuery = (
  { __typename?: 'Query' }
  & { paymentsVerification?: Maybe<Array<Maybe<(
    { __typename?: 'PaymentsVerificationType' }
    & Pick<PaymentsVerificationType, 'fullname' | 'agreement_id' | 'agreementTitle' | 'currency'>
  )>>> }
);

export type PaymentsDeferredQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
}>;


export type PaymentsDeferredQuery = (
  { __typename?: 'Query' }
  & { allDeferredPayments?: Maybe<(
    { __typename?: 'DeferredPaymentConnection' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
    ), edges: Array<Maybe<(
      { __typename?: 'DeferredPaymentEdge' }
      & { node?: Maybe<(
        { __typename?: 'DeferredPaymentType' }
        & Pick<DeferredPaymentType, 'id' | 'date' | 'studentFullname' | 'agreementTitle' | 'currency' | 'sum' | 'userInput' | 'agreementCurrency'>
      )> }
    )>> }
  )> }
);

export type PlannedYearContributionsQueryVariables = Exact<{
  year: Scalars['Int'];
}>;


export type PlannedYearContributionsQuery = (
  { __typename?: 'Query' }
  & { financePlan?: Maybe<Array<Maybe<(
    { __typename?: 'MonthBudget' }
    & Pick<MonthBudget, 'month' | 'USD' | 'EUR' | 'BYN' | 'RUB' | 'PLN'>
  )>>>, financeBudget?: Maybe<Array<Maybe<(
    { __typename?: 'MonthBudget' }
    & Pick<MonthBudget, 'month' | 'USD' | 'EUR' | 'BYN' | 'RUB' | 'PLN'>
  )>>> }
);

export type PlannedMonthContributionsQueryVariables = Exact<{
  year: Scalars['Int'];
  month: Scalars['Int'];
}>;


export type PlannedMonthContributionsQuery = (
  { __typename?: 'Query' }
  & { financeMonthBudget?: Maybe<(
    { __typename?: 'WeeksBudgetInfo' }
    & { plan?: Maybe<Array<Maybe<(
      { __typename?: 'WeeksBudget' }
      & Pick<WeeksBudget, 'week' | 'USD' | 'EUR' | 'BYN' | 'RUB' | 'PLN'>
    )>>>, budget?: Maybe<Array<Maybe<(
      { __typename?: 'WeeksBudget' }
      & Pick<WeeksBudget, 'week' | 'USD' | 'EUR' | 'BYN' | 'RUB' | 'PLN'>
    )>>> }
  )> }
);

export type FinanceYearsQueryVariables = Exact<{ [key: string]: never; }>;


export type FinanceYearsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'financeYears'>
);

export type PaginatedGroupsQueryVariables = Exact<{
  query?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
}>;


export type PaginatedGroupsQuery = (
  { __typename?: 'Query' }
  & { allGroups?: Maybe<(
    { __typename?: 'GroupConnection' }
    & Pick<GroupConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ), edges: Array<Maybe<(
      { __typename?: 'GroupEdge' }
      & Pick<GroupEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'GroupType' }
        & Pick<GroupType, 'id' | 'name' | 'startDate' | 'lessonsCount' | 'studentsCount' | 'publishedLms' | 'publishedLmsForTeacher' | 'publishedLmsForMethodist' | 'price' | 'currency' | 'contributionsSum'>
        & { classesDays: Array<(
          { __typename?: 'DayOfClassType' }
          & Pick<DayOfClassType, 'weekDay'>
        )> }
      )> }
    )>> }
  )> }
);

export type LoadEditingGroupQueryVariables = Exact<{
  groupId: Scalars['ID'];
}>;


export type LoadEditingGroupQuery = (
  { __typename?: 'Query' }
  & { group?: Maybe<(
    { __typename?: 'GroupType' }
    & Pick<GroupType, 'id' | 'name' | 'price' | 'currency' | 'startDate' | 'deleteAvailable'>
    & { course: (
      { __typename?: 'CourseFilterType' }
      & Pick<CourseFilterType, 'id' | 'name'>
    ), classesDays: Array<(
      { __typename?: 'DayOfClassType' }
      & Pick<DayOfClassType, 'id' | 'weekDay' | 'startTime' | 'endTime'>
    )>, modules: Array<(
      { __typename?: 'GroupModuleType' }
      & Pick<GroupModuleType, 'id' | 'name'>
      & { lessons: (
        { __typename?: 'GroupLessonTypeConnection' }
        & { edges: Array<Maybe<(
          { __typename?: 'GroupLessonTypeEdge' }
          & { node?: Maybe<(
            { __typename?: 'GroupLessonType' }
            & Pick<GroupLessonType, 'id' | 'name' | 'lessonNumber' | 'additional' | 'date' | 'lmsDescription'>
            & { teacherApplication?: Maybe<(
              { __typename?: 'ApplicationType' }
              & Pick<ApplicationType, 'id'>
              & { teacher: (
                { __typename?: 'TeacherFilterType' }
                & Pick<TeacherFilterType, 'fullName'>
              ) }
            )> }
          )> }
        )>> }
      ) }
    )> }
  )> }
);

export type PaginatedJournalsQueryVariables = Exact<{
  query?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
}>;


export type PaginatedJournalsQuery = (
  { __typename?: 'Query' }
  & { allGroups?: Maybe<(
    { __typename?: 'GroupConnection' }
    & Pick<GroupConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ), edges: Array<Maybe<(
      { __typename?: 'GroupEdge' }
      & Pick<GroupEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'GroupType' }
        & Pick<GroupType, 'id' | 'name'>
        & { course: (
          { __typename?: 'CourseFilterType' }
          & Pick<CourseFilterType, 'name'>
        ), teachers?: Maybe<Array<Maybe<(
          { __typename?: 'TeacherType' }
          & Pick<TeacherType, 'fullName'>
        )>>> }
      )> }
    )>> }
  )> }
);

export type PaginatedJournalQueryVariables = Exact<{
  groupId: Scalars['ID'];
}>;


export type PaginatedJournalQuery = (
  { __typename?: 'Query' }
  & { journal?: Maybe<(
    { __typename?: 'GroupLessonConnection' }
    & Pick<GroupLessonConnection, 'id' | 'groupName' | 'comment'>
    & { edges: Array<Maybe<(
      { __typename?: 'GroupLessonEdge' }
      & { node?: Maybe<(
        { __typename?: 'GroupLessonType' }
        & Pick<GroupLessonType, 'id' | 'date' | 'lessonNumber'>
      )> }
    )>> }
  )> }
);

export type StudentsForJournalQueryVariables = Exact<{
  lessonsId: Array<Maybe<Scalars['Int']>>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  groupId: Scalars['Int'];
}>;


export type StudentsForJournalQuery = (
  { __typename?: 'Query' }
  & { studentsForJournal?: Maybe<(
    { __typename?: 'StudentConnection' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
    ), edges: Array<Maybe<(
      { __typename?: 'StudentEdge' }
      & Pick<StudentEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'StudentType' }
        & Pick<StudentType, 'id' | 'agreementIsActive'>
        & { user?: Maybe<(
          { __typename?: 'CustomUserType' }
          & Pick<CustomUserType, 'id' | 'firstName' | 'lastName' | 'middleName'>
        )>, attendances: (
          { __typename?: 'AttendanceTypeConnection' }
          & { edges: Array<Maybe<(
            { __typename?: 'AttendanceTypeEdge' }
            & { node?: Maybe<(
              { __typename?: 'AttendanceType' }
              & Pick<AttendanceType, 'id' | 'attendance'>
              & { lesson: (
                { __typename?: 'GroupLessonType' }
                & Pick<GroupLessonType, 'id'>
              ) }
            )> }
          )>> }
        ) }
      )> }
    )>> }
  )> }
);

export type LoadEditingJournalQueryVariables = Exact<{
  groupId: Scalars['ID'];
}>;


export type LoadEditingJournalQuery = (
  { __typename?: 'Query' }
  & { group?: Maybe<(
    { __typename?: 'GroupType' }
    & Pick<GroupType, 'id' | 'comment'>
    & { classesDays: Array<(
      { __typename?: 'DayOfClassType' }
      & Pick<DayOfClassType, 'id' | 'weekDay' | 'startTime' | 'endTime'>
    )>, modules: Array<(
      { __typename?: 'GroupModuleType' }
      & Pick<GroupModuleType, 'id' | 'name'>
      & { lessons: (
        { __typename?: 'GroupLessonTypeConnection' }
        & { edges: Array<Maybe<(
          { __typename?: 'GroupLessonTypeEdge' }
          & { node?: Maybe<(
            { __typename?: 'GroupLessonType' }
            & Pick<GroupLessonType, 'id' | 'name' | 'lessonNumber' | 'additional' | 'date' | 'lmsDescription'>
            & { teacherApplication?: Maybe<(
              { __typename?: 'ApplicationType' }
              & Pick<ApplicationType, 'id'>
              & { teacher: (
                { __typename?: 'TeacherFilterType' }
                & Pick<TeacherFilterType, 'fullName'>
              ) }
            )> }
          )> }
        )>> }
      ) }
    )> }
  )> }
);

export type PaginatedPerformancesQueryVariables = Exact<{
  query?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}>;


export type PaginatedPerformancesQuery = (
  { __typename?: 'Query' }
  & { allStudentsAchievement?: Maybe<(
    { __typename?: 'StudentsAchievementConnection' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ), edges: Array<Maybe<(
      { __typename?: 'StudentsAchievementEdge' }
      & Pick<StudentsAchievementEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'AgreementType' }
        & Pick<AgreementType, 'id' | 'attendancesInfo' | 'courseWorksAverage' | 'hwSummary' | 'graduationProjectMark'>
        & { student: (
          { __typename?: 'StudentType' }
          & { user?: Maybe<(
            { __typename?: 'CustomUserType' }
            & Pick<CustomUserType, 'firstName' | 'lastName' | 'middleName'>
          )> }
        ), group: (
          { __typename?: 'MethodistGroupType' }
          & Pick<MethodistGroupType, 'id' | 'name'>
          & { course: (
            { __typename?: 'CourseFilterType' }
            & Pick<CourseFilterType, 'educationForm'>
          ) }
        ) }
      )> }
    )>> }
  )> }
);

export type SettingsMetaQueryVariables = Exact<{ [key: string]: never; }>;


export type SettingsMetaQuery = (
  { __typename?: 'Query' }
  & { metaCourses?: Maybe<(
    { __typename?: 'MetaCourses' }
    & Pick<MetaCourses, 'totalCount'>
  )>, metaGroups?: Maybe<(
    { __typename?: 'MetaGroups' }
    & Pick<MetaGroups, 'totalCount'>
  )> }
);

export type GetAppStateQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAppStateQuery = (
  { __typename?: 'Query' }
  & { appState: (
    { __typename?: 'appState' }
    & Pick<AppState, 'bootstrapped'>
  ) }
);

export type GetAuthStateQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAuthStateQuery = (
  { __typename?: 'Query' }
  & { authState: (
    { __typename?: 'authState' }
    & Pick<AuthState, 'isLoggedIn' | 'access_token'>
  ) }
);

export type PaginatedStudentsQueryVariables = Exact<{
  query?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
}>;


export type PaginatedStudentsQuery = (
  { __typename?: 'Query' }
  & { allStudents?: Maybe<(
    { __typename?: 'StudentConnection' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ), edges: Array<Maybe<(
      { __typename?: 'StudentEdge' }
      & Pick<StudentEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'StudentType' }
        & Pick<StudentType, 'id'>
        & { user?: Maybe<(
          { __typename?: 'CustomUserType' }
          & Pick<CustomUserType, 'lastName' | 'firstName' | 'middleName' | 'phone' | 'email'>
        )>, agreements: Array<(
          { __typename?: 'AgreementType' }
          & Pick<AgreementType, 'id' | 'agreementTitle' | 'currency' | 'budgetAmount' | 'paidAmount' | 'terminated'>
          & { group: (
            { __typename?: 'MethodistGroupType' }
            & Pick<MethodistGroupType, 'id' | 'name'>
          ) }
        )> }
      )> }
    )>> }
  )> }
);

export type LoadAgreementByIdQueryVariables = Exact<{
  agreementId: Scalars['ID'];
}>;


export type LoadAgreementByIdQuery = (
  { __typename?: 'Query' }
  & { agreement?: Maybe<(
    { __typename?: 'AgreementType' }
    & Pick<AgreementType, 'id' | 'agreementTitle' | 'currency' | 'terminated' | 'paymentByCompany' | 'vat' | 'date' | 'comment' | 'budgetAmount' | 'paidAmount' | 'partialAttendance' | 'terminatedDate' | 'promocode' | 'resident'>
    & { firstLesson?: Maybe<(
      { __typename?: 'GroupLessonType' }
      & Pick<GroupLessonType, 'id' | 'name' | 'lessonNumber'>
    )>, lastLesson?: Maybe<(
      { __typename?: 'GroupLessonType' }
      & Pick<GroupLessonType, 'id' | 'name' | 'date' | 'lessonNumber'>
    )>, chargeback?: Maybe<(
      { __typename?: 'ChargebackType' }
      & Pick<ChargebackType, 'id' | 'amountByn' | 'amountForeign' | 'currency'>
    )>, contributions: (
      { __typename?: 'ContributionTypeConnection' }
      & { edges: Array<Maybe<(
        { __typename?: 'ContributionTypeEdge' }
        & { node?: Maybe<(
          { __typename?: 'ContributionType' }
          & Pick<ContributionType, 'id' | 'date' | 'amountForeign' | 'amountByn'>
        )> }
      )>> }
    ), company?: Maybe<(
      { __typename?: 'CompanyType' }
      & Pick<CompanyType, 'id' | 'name'>
    )>, country?: Maybe<(
      { __typename?: 'CountryType' }
      & Pick<CountryType, 'id' | 'title'>
    )>, student: (
      { __typename?: 'StudentType' }
      & Pick<StudentType, 'id'>
      & { user?: Maybe<(
        { __typename?: 'CustomUserType' }
        & Pick<CustomUserType, 'id' | 'firstName' | 'middleName' | 'lastName' | 'phone' | 'email'>
      )> }
    ), group: (
      { __typename?: 'MethodistGroupType' }
      & Pick<MethodistGroupType, 'id' | 'name' | 'startDate'>
      & { course: (
        { __typename?: 'CourseFilterType' }
        & Pick<CourseFilterType, 'id' | 'name'>
      ) }
    ), startAccountsPayable?: Maybe<(
      { __typename?: 'AccountsPayableType' }
      & Pick<AccountsPayableType, 'id' | 'amountByn' | 'amountForeign' | 'repaid'>
    )>, accountsPayable: Array<(
      { __typename?: 'AccountsPayableType' }
      & Pick<AccountsPayableType, 'id' | 'amountForeign' | 'currency'>
      & { startedAgreement: (
        { __typename?: 'AgreementType' }
        & Pick<AgreementType, 'id' | 'agreementTitle'>
      ) }
    )> }
  )> }
);

export type MetaStudentsQueryVariables = Exact<{
  query?: Maybe<Scalars['String']>;
}>;


export type MetaStudentsQuery = (
  { __typename?: 'Query' }
  & { metaStudents?: Maybe<(
    { __typename?: 'MetaStudents' }
    & Pick<MetaStudents, 'totalCount' | 'agreementsCount'>
  )> }
);

export type CheckUserExistQueryVariables = Exact<{
  email: Scalars['String'];
  phone: Scalars['String'];
}>;


export type CheckUserExistQuery = (
  { __typename?: 'Query' }
  & { verifyStudent?: Maybe<(
    { __typename?: 'VerifiedStudent' }
    & Pick<VerifiedStudent, 'message' | 'code'>
    & { student?: Maybe<(
      { __typename?: 'StudentType' }
      & Pick<StudentType, 'id'>
      & { user?: Maybe<(
        { __typename?: 'CustomUserType' }
        & Pick<CustomUserType, 'id' | 'firstName' | 'lastName' | 'middleName' | 'phone' | 'email' | 'roles'>
      )> }
    )>, user?: Maybe<(
      { __typename?: 'CustomUserType' }
      & Pick<CustomUserType, 'id' | 'firstName' | 'lastName' | 'middleName' | 'phone' | 'email' | 'roles'>
    )>, customErrors?: Maybe<Array<Maybe<(
      { __typename?: 'CustomErrorType' }
      & Pick<CustomErrorType, 'field' | 'message' | 'code'>
    )>>> }
  )> }
);

export type LoadLessonsOptionsQueryVariables = Exact<{
  groupId: Scalars['ID'];
}>;


export type LoadLessonsOptionsQuery = (
  { __typename?: 'Query' }
  & { group?: Maybe<(
    { __typename?: 'GroupType' }
    & Pick<GroupType, 'id'>
    & { lessons?: Maybe<Array<(
      { __typename?: 'GroupLessonType' }
      & Pick<GroupLessonType, 'id' | 'name' | 'date' | 'lessonNumber'>
    )>> }
  )> }
);

export type AccountsPayablesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AccountsPayablesQuery = (
  { __typename?: 'Query' }
  & { student?: Maybe<(
    { __typename?: 'StudentType' }
    & Pick<StudentType, 'id'>
    & { accountsPayables?: Maybe<Array<Maybe<(
      { __typename?: 'AccountsPayableType' }
      & Pick<AccountsPayableType, 'id' | 'amountForeign' | 'currency' | 'repaid'>
      & { startedAgreement: (
        { __typename?: 'AgreementType' }
        & Pick<AgreementType, 'id' | 'agreementTitle'>
      ) }
    )>>> }
  )> }
);

export type AllTeachersQueryVariables = Exact<{
  query?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}>;


export type AllTeachersQuery = (
  { __typename?: 'Query' }
  & { allTeachers?: Maybe<(
    { __typename?: 'TeacherFilterTypeConnection' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ), edges: Array<Maybe<(
      { __typename?: 'TeacherFilterTypeEdge' }
      & { node?: Maybe<(
        { __typename?: 'TeacherType' }
        & Pick<TeacherType, 'id' | 'accessToLms' | 'fullName' | 'isDismissed' | 'courses'>
        & { user: (
          { __typename?: 'CustomUserType' }
          & Pick<CustomUserType, 'phone' | 'email'>
        ) }
      )> }
    )>> }
  )> }
);

export type VerifyTeacherQueryVariables = Exact<{
  firstName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  phone: Scalars['String'];
  email: Scalars['String'];
}>;


export type VerifyTeacherQuery = (
  { __typename?: 'Query' }
  & { verifyTeacher?: Maybe<(
    { __typename?: 'VerifiedTeacher' }
    & Pick<VerifiedTeacher, 'code'>
    & { customErrors?: Maybe<Array<Maybe<(
      { __typename?: 'CustomErrorType' }
      & Pick<CustomErrorType, 'field' | 'message' | 'code'>
    )>>>, user?: Maybe<(
      { __typename?: 'CustomUserType' }
      & Pick<CustomUserType, 'id' | 'firstName' | 'middleName' | 'lastName' | 'phone' | 'email' | 'roles'>
    )>, teacher?: Maybe<(
      { __typename?: 'TeacherType' }
      & Pick<TeacherType, 'id'>
      & { user: (
        { __typename?: 'CustomUserType' }
        & Pick<CustomUserType, 'firstName' | 'middleName' | 'lastName' | 'phone' | 'email'>
      ) }
    )> }
  )> }
);

export type GetTeacherDataQueryVariables = Exact<{
  teacherId?: Maybe<Scalars['ID']>;
}>;


export type GetTeacherDataQuery = (
  { __typename?: 'Query' }
  & { teacher?: Maybe<(
    { __typename?: 'TeacherType' }
    & { employmentTypes?: Maybe<Array<Maybe<{ __typename: 'IndividualEntrepreneurType' } | { __typename: 'LegalEntityType' } | { __typename: 'SelfEmployedType' } | { __typename: 'NaturalPersonType' }>>> }
  )> }
);

export type GetTeacherTypesQueryVariables = Exact<{
  teacherId: Scalars['ID'];
}>;


export type GetTeacherTypesQuery = (
  { __typename?: 'Query' }
  & { teacher?: Maybe<(
    { __typename?: 'TeacherType' }
    & { employmentTypes?: Maybe<Array<Maybe<(
      { __typename: 'IndividualEntrepreneurType' }
      & Pick<IndividualEntrepreneurType, 'id' | 'registrationAddress' | 'accountNumber' | 'bankTitle' | 'bic' | 'inn'>
    ) | (
      { __typename: 'LegalEntityType' }
      & Pick<LegalEntityType, 'id' | 'registrationAddress' | 'accountNumber' | 'bankTitle' | 'bic' | 'tittle' | 'inn'>
    ) | (
      { __typename: 'SelfEmployedType' }
      & Pick<SelfEmployedType, 'id' | 'registrationAddress' | 'accountNumber' | 'bankTitle' | 'bic' | 'passportNumber' | 'inn'>
      & { nationality: (
        { __typename?: 'CountryType' }
        & Pick<CountryType, 'id' | 'title'>
      ) }
    ) | (
      { __typename: 'NaturalPersonType' }
      & Pick<NaturalPersonType, 'id' | 'registrationAddress' | 'accountNumber' | 'bankTitle' | 'bic' | 'passportNumber'>
      & { nationality: (
        { __typename?: 'CountryType' }
        & Pick<CountryType, 'id' | 'title'>
      ) }
    )>>> }
  )> }
);

export type GetEditTeacherQueryVariables = Exact<{
  teacherId: Scalars['ID'];
}>;


export type GetEditTeacherQuery = (
  { __typename?: 'Query' }
  & { teacher?: Maybe<(
    { __typename?: 'TeacherType' }
    & Pick<TeacherType, 'id' | 'accessToLms' | 'siteDisplay'>
    & { user: (
      { __typename?: 'CustomUserType' }
      & Pick<CustomUserType, 'firstName' | 'lastName' | 'middleName' | 'email' | 'phone'>
    ), employmentTypes?: Maybe<Array<Maybe<(
      { __typename: 'IndividualEntrepreneurType' }
      & Pick<IndividualEntrepreneurType, 'id' | 'registrationAddress' | 'accountNumber' | 'bankTitle' | 'bic' | 'inn'>
    ) | (
      { __typename: 'LegalEntityType' }
      & Pick<LegalEntityType, 'id' | 'registrationAddress' | 'accountNumber' | 'bankTitle' | 'bic' | 'tittle' | 'inn'>
    ) | (
      { __typename: 'SelfEmployedType' }
      & Pick<SelfEmployedType, 'id' | 'registrationAddress' | 'accountNumber' | 'bankTitle' | 'bic' | 'passportNumber' | 'inn'>
      & { nationality: (
        { __typename?: 'CountryType' }
        & Pick<CountryType, 'id' | 'title'>
      ) }
    ) | (
      { __typename: 'NaturalPersonType' }
      & Pick<NaturalPersonType, 'id' | 'registrationAddress' | 'accountNumber' | 'bankTitle' | 'bic' | 'passportNumber'>
      & { nationality: (
        { __typename?: 'CountryType' }
        & Pick<CountryType, 'id' | 'title'>
      ) }
    )>>> }
  )> }
);

export type TeachersMetaQueryVariables = Exact<{
  query?: Maybe<Scalars['String']>;
}>;


export type TeachersMetaQuery = (
  { __typename?: 'Query' }
  & { metaTeachers?: Maybe<(
    { __typename?: 'MetaTeachers' }
    & Pick<MetaTeachers, 'totalCount' | 'applicationsCount'>
  )> }
);

export type GetUserByIdQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type GetUserByIdQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'UserNode' }
    & Pick<UserNode, 'pk' | 'firstName' | 'middleName' | 'lastName' | 'email' | 'phone' | 'roles'>
  )> }
);

export type FindUserDataQueryVariables = Exact<{
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
}>;


export type FindUserDataQuery = (
  { __typename?: 'Query' }
  & { users?: Maybe<(
    { __typename?: 'UserNodeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'UserNodeEdge' }
      & { node?: Maybe<(
        { __typename?: 'UserNode' }
        & Pick<UserNode, 'pk' | 'roles' | 'email' | 'phone' | 'firstName' | 'lastName' | 'middleName'>
        & { student?: Maybe<(
          { __typename?: 'StudentType' }
          & Pick<StudentType, 'id'>
        )> }
      )> }
    )>> }
  )> }
);

export type PaginatedUsersSettingsQueryVariables = Exact<{
  query?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
}>;


export type PaginatedUsersSettingsQuery = (
  { __typename?: 'Query' }
  & { allUsersSettings?: Maybe<(
    { __typename?: 'UsersSettingsConnection' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ), edges: Array<Maybe<(
      { __typename?: 'UsersSettingsEdge' }
      & Pick<UsersSettingsEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'UserNode' }
        & UserFieldsFragment
      )> }
    )>> }
  )> }
);

export const UserFieldsFragmentDoc = gql`
    fragment UserFields on UserNode {
  id
  username
  isActive
  firstName
  middleName
  lastName
  email
  roles
  verified
  isStaff
  dateJoined
  pk
  archived
  secondaryEmail
  lastLogin
  phone
}
    `;
export const AppendTeacherApplicationDocument = gql`
    mutation appendTeacherApplication($application: ApplicationInputType!, $teacherId: ID!) {
  addTeacherApplication(application: $application, teacherId: $teacherId) {
    success
    customErrors {
      field
      message
      code
    }
  }
}
    `;
export type AppendTeacherApplicationMutationFn = Apollo.MutationFunction<AppendTeacherApplicationMutation, AppendTeacherApplicationMutationVariables>;

/**
 * __useAppendTeacherApplicationMutation__
 *
 * To run a mutation, you first call `useAppendTeacherApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAppendTeacherApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [appendTeacherApplicationMutation, { data, loading, error }] = useAppendTeacherApplicationMutation({
 *   variables: {
 *      application: // value for 'application'
 *      teacherId: // value for 'teacherId'
 *   },
 * });
 */
export function useAppendTeacherApplicationMutation(baseOptions?: Apollo.MutationHookOptions<AppendTeacherApplicationMutation, AppendTeacherApplicationMutationVariables>) {
        return Apollo.useMutation<AppendTeacherApplicationMutation, AppendTeacherApplicationMutationVariables>(AppendTeacherApplicationDocument, baseOptions);
      }
export type AppendTeacherApplicationMutationHookResult = ReturnType<typeof useAppendTeacherApplicationMutation>;
export type AppendTeacherApplicationMutationResult = Apollo.MutationResult<AppendTeacherApplicationMutation>;
export type AppendTeacherApplicationMutationOptions = Apollo.BaseMutationOptions<AppendTeacherApplicationMutation, AppendTeacherApplicationMutationVariables>;
export const UpdateTeacherApplicationDocument = gql`
    mutation updateTeacherApplication($application: UpdateApplicationInputType!) {
  updateTeacherApplication(application: $application) {
    success
    customErrors {
      field
      message
      code
    }
  }
}
    `;
export type UpdateTeacherApplicationMutationFn = Apollo.MutationFunction<UpdateTeacherApplicationMutation, UpdateTeacherApplicationMutationVariables>;

/**
 * __useUpdateTeacherApplicationMutation__
 *
 * To run a mutation, you first call `useUpdateTeacherApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeacherApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeacherApplicationMutation, { data, loading, error }] = useUpdateTeacherApplicationMutation({
 *   variables: {
 *      application: // value for 'application'
 *   },
 * });
 */
export function useUpdateTeacherApplicationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTeacherApplicationMutation, UpdateTeacherApplicationMutationVariables>) {
        return Apollo.useMutation<UpdateTeacherApplicationMutation, UpdateTeacherApplicationMutationVariables>(UpdateTeacherApplicationDocument, baseOptions);
      }
export type UpdateTeacherApplicationMutationHookResult = ReturnType<typeof useUpdateTeacherApplicationMutation>;
export type UpdateTeacherApplicationMutationResult = Apollo.MutationResult<UpdateTeacherApplicationMutation>;
export type UpdateTeacherApplicationMutationOptions = Apollo.BaseMutationOptions<UpdateTeacherApplicationMutation, UpdateTeacherApplicationMutationVariables>;
export const RemoveApplicationDocument = gql`
    mutation removeApplication($id: ID!) {
  deleteTeacherApplication(applicationId: $id) {
    success
    errors
  }
}
    `;
export type RemoveApplicationMutationFn = Apollo.MutationFunction<RemoveApplicationMutation, RemoveApplicationMutationVariables>;

/**
 * __useRemoveApplicationMutation__
 *
 * To run a mutation, you first call `useRemoveApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeApplicationMutation, { data, loading, error }] = useRemoveApplicationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveApplicationMutation(baseOptions?: Apollo.MutationHookOptions<RemoveApplicationMutation, RemoveApplicationMutationVariables>) {
        return Apollo.useMutation<RemoveApplicationMutation, RemoveApplicationMutationVariables>(RemoveApplicationDocument, baseOptions);
      }
export type RemoveApplicationMutationHookResult = ReturnType<typeof useRemoveApplicationMutation>;
export type RemoveApplicationMutationResult = Apollo.MutationResult<RemoveApplicationMutation>;
export type RemoveApplicationMutationOptions = Apollo.BaseMutationOptions<RemoveApplicationMutation, RemoveApplicationMutationVariables>;
export const RefreshTokenDocument = gql`
    mutation refreshToken($refresh_token: String!, $scope: String!) {
  refreshToken(refreshToken: $refresh_token, scope: $scope) {
    token
    refreshToken
    payload
  }
}
    `;
export type RefreshTokenMutationFn = Apollo.MutationFunction<RefreshTokenMutation, RefreshTokenMutationVariables>;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *      refresh_token: // value for 'refresh_token'
 *      scope: // value for 'scope'
 *   },
 * });
 */
export function useRefreshTokenMutation(baseOptions?: Apollo.MutationHookOptions<RefreshTokenMutation, RefreshTokenMutationVariables>) {
        return Apollo.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(RefreshTokenDocument, baseOptions);
      }
export type RefreshTokenMutationHookResult = ReturnType<typeof useRefreshTokenMutation>;
export type RefreshTokenMutationResult = Apollo.MutationResult<RefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<RefreshTokenMutation, RefreshTokenMutationVariables>;
export const SendPasswordResetEmailDocument = gql`
    mutation sendPasswordResetEmail($email: String!) {
  sendPasswordResetEmail(email: $email) {
    success
    errors
  }
}
    `;
export type SendPasswordResetEmailMutationFn = Apollo.MutationFunction<SendPasswordResetEmailMutation, SendPasswordResetEmailMutationVariables>;

/**
 * __useSendPasswordResetEmailMutation__
 *
 * To run a mutation, you first call `useSendPasswordResetEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPasswordResetEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPasswordResetEmailMutation, { data, loading, error }] = useSendPasswordResetEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendPasswordResetEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendPasswordResetEmailMutation, SendPasswordResetEmailMutationVariables>) {
        return Apollo.useMutation<SendPasswordResetEmailMutation, SendPasswordResetEmailMutationVariables>(SendPasswordResetEmailDocument, baseOptions);
      }
export type SendPasswordResetEmailMutationHookResult = ReturnType<typeof useSendPasswordResetEmailMutation>;
export type SendPasswordResetEmailMutationResult = Apollo.MutationResult<SendPasswordResetEmailMutation>;
export type SendPasswordResetEmailMutationOptions = Apollo.BaseMutationOptions<SendPasswordResetEmailMutation, SendPasswordResetEmailMutationVariables>;
export const PasswordSetDocument = gql`
    mutation passwordSet($newPassword1: String!, $newPassword2: String!, $token: String!) {
  passwordSet(
    newPassword1: $newPassword1
    newPassword2: $newPassword2
    token: $token
  ) {
    success
    errors
  }
}
    `;
export type PasswordSetMutationFn = Apollo.MutationFunction<PasswordSetMutation, PasswordSetMutationVariables>;

/**
 * __usePasswordSetMutation__
 *
 * To run a mutation, you first call `usePasswordSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordSetMutation, { data, loading, error }] = usePasswordSetMutation({
 *   variables: {
 *      newPassword1: // value for 'newPassword1'
 *      newPassword2: // value for 'newPassword2'
 *      token: // value for 'token'
 *   },
 * });
 */
export function usePasswordSetMutation(baseOptions?: Apollo.MutationHookOptions<PasswordSetMutation, PasswordSetMutationVariables>) {
        return Apollo.useMutation<PasswordSetMutation, PasswordSetMutationVariables>(PasswordSetDocument, baseOptions);
      }
export type PasswordSetMutationHookResult = ReturnType<typeof usePasswordSetMutation>;
export type PasswordSetMutationResult = Apollo.MutationResult<PasswordSetMutation>;
export type PasswordSetMutationOptions = Apollo.BaseMutationOptions<PasswordSetMutation, PasswordSetMutationVariables>;
export const PasswordResetDocument = gql`
    mutation passwordReset($newPassword1: String!, $newPassword2: String!, $token: String!) {
  passwordReset(
    newPassword1: $newPassword1
    newPassword2: $newPassword2
    token: $token
  ) {
    success
    errors
  }
}
    `;
export type PasswordResetMutationFn = Apollo.MutationFunction<PasswordResetMutation, PasswordResetMutationVariables>;

/**
 * __usePasswordResetMutation__
 *
 * To run a mutation, you first call `usePasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordResetMutation, { data, loading, error }] = usePasswordResetMutation({
 *   variables: {
 *      newPassword1: // value for 'newPassword1'
 *      newPassword2: // value for 'newPassword2'
 *      token: // value for 'token'
 *   },
 * });
 */
export function usePasswordResetMutation(baseOptions?: Apollo.MutationHookOptions<PasswordResetMutation, PasswordResetMutationVariables>) {
        return Apollo.useMutation<PasswordResetMutation, PasswordResetMutationVariables>(PasswordResetDocument, baseOptions);
      }
export type PasswordResetMutationHookResult = ReturnType<typeof usePasswordResetMutation>;
export type PasswordResetMutationResult = Apollo.MutationResult<PasswordResetMutation>;
export type PasswordResetMutationOptions = Apollo.BaseMutationOptions<PasswordResetMutation, PasswordResetMutationVariables>;
export const VerifyTokenDocument = gql`
    mutation verifyToken($token: String!) {
  verifyToken(token: $token) {
    success
    errors
  }
}
    `;
export type VerifyTokenMutationFn = Apollo.MutationFunction<VerifyTokenMutation, VerifyTokenMutationVariables>;

/**
 * __useVerifyTokenMutation__
 *
 * To run a mutation, you first call `useVerifyTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyTokenMutation, { data, loading, error }] = useVerifyTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useVerifyTokenMutation(baseOptions?: Apollo.MutationHookOptions<VerifyTokenMutation, VerifyTokenMutationVariables>) {
        return Apollo.useMutation<VerifyTokenMutation, VerifyTokenMutationVariables>(VerifyTokenDocument, baseOptions);
      }
export type VerifyTokenMutationHookResult = ReturnType<typeof useVerifyTokenMutation>;
export type VerifyTokenMutationResult = Apollo.MutationResult<VerifyTokenMutation>;
export type VerifyTokenMutationOptions = Apollo.BaseMutationOptions<VerifyTokenMutation, VerifyTokenMutationVariables>;
export const LoginDocument = gql`
    mutation login($password: String!, $email: String!, $scope: String!) {
  customLogin(password: $password, email: $email, scope: $scope) {
    success
    errors
    token
    refreshToken
    user {
      ...UserFields
    }
  }
}
    ${UserFieldsFragmentDoc}`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      password: // value for 'password'
 *      email: // value for 'email'
 *      scope: // value for 'scope'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const AddCompanyDocument = gql`
    mutation addCompany($companyInput: CompanyInput!) {
  createCompany(companyInput: $companyInput) {
    success
    errors
    customErrors {
      field
      message
      code
    }
  }
}
    `;
export type AddCompanyMutationFn = Apollo.MutationFunction<AddCompanyMutation, AddCompanyMutationVariables>;

/**
 * __useAddCompanyMutation__
 *
 * To run a mutation, you first call `useAddCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCompanyMutation, { data, loading, error }] = useAddCompanyMutation({
 *   variables: {
 *      companyInput: // value for 'companyInput'
 *   },
 * });
 */
export function useAddCompanyMutation(baseOptions?: Apollo.MutationHookOptions<AddCompanyMutation, AddCompanyMutationVariables>) {
        return Apollo.useMutation<AddCompanyMutation, AddCompanyMutationVariables>(AddCompanyDocument, baseOptions);
      }
export type AddCompanyMutationHookResult = ReturnType<typeof useAddCompanyMutation>;
export type AddCompanyMutationResult = Apollo.MutationResult<AddCompanyMutation>;
export type AddCompanyMutationOptions = Apollo.BaseMutationOptions<AddCompanyMutation, AddCompanyMutationVariables>;
export const EditCompanyDocument = gql`
    mutation editCompany($companyId: ID!, $companyInput: UpdateCompanyInput!) {
  updateCompany(companyId: $companyId, companyInput: $companyInput) {
    company {
      id
      name
      responsiblePerson
      email
      phone
      registrationNumber
      comment
    }
    success
    customErrors {
      field
      message
      code
    }
  }
}
    `;
export type EditCompanyMutationFn = Apollo.MutationFunction<EditCompanyMutation, EditCompanyMutationVariables>;

/**
 * __useEditCompanyMutation__
 *
 * To run a mutation, you first call `useEditCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCompanyMutation, { data, loading, error }] = useEditCompanyMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      companyInput: // value for 'companyInput'
 *   },
 * });
 */
export function useEditCompanyMutation(baseOptions?: Apollo.MutationHookOptions<EditCompanyMutation, EditCompanyMutationVariables>) {
        return Apollo.useMutation<EditCompanyMutation, EditCompanyMutationVariables>(EditCompanyDocument, baseOptions);
      }
export type EditCompanyMutationHookResult = ReturnType<typeof useEditCompanyMutation>;
export type EditCompanyMutationResult = Apollo.MutationResult<EditCompanyMutation>;
export type EditCompanyMutationOptions = Apollo.BaseMutationOptions<EditCompanyMutation, EditCompanyMutationVariables>;
export const AddPaymentsDocument = gql`
    mutation AddPayments($payments: [PaymentInput]) {
  createPayments(paymentsInput: $payments) {
    success
    errors
  }
}
    `;
export type AddPaymentsMutationFn = Apollo.MutationFunction<AddPaymentsMutation, AddPaymentsMutationVariables>;

/**
 * __useAddPaymentsMutation__
 *
 * To run a mutation, you first call `useAddPaymentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPaymentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPaymentsMutation, { data, loading, error }] = useAddPaymentsMutation({
 *   variables: {
 *      payments: // value for 'payments'
 *   },
 * });
 */
export function useAddPaymentsMutation(baseOptions?: Apollo.MutationHookOptions<AddPaymentsMutation, AddPaymentsMutationVariables>) {
        return Apollo.useMutation<AddPaymentsMutation, AddPaymentsMutationVariables>(AddPaymentsDocument, baseOptions);
      }
export type AddPaymentsMutationHookResult = ReturnType<typeof useAddPaymentsMutation>;
export type AddPaymentsMutationResult = Apollo.MutationResult<AddPaymentsMutation>;
export type AddPaymentsMutationOptions = Apollo.BaseMutationOptions<AddPaymentsMutation, AddPaymentsMutationVariables>;
export const AddDeferredPaymentsDocument = gql`
    mutation AddDeferredPayments($payments: [DeferredPaymentInput]) {
  createDeferredPayments(deferredPaymentsInput: $payments) {
    success
    customErrors {
      field
      message
      code
    }
  }
}
    `;
export type AddDeferredPaymentsMutationFn = Apollo.MutationFunction<AddDeferredPaymentsMutation, AddDeferredPaymentsMutationVariables>;

/**
 * __useAddDeferredPaymentsMutation__
 *
 * To run a mutation, you first call `useAddDeferredPaymentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDeferredPaymentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDeferredPaymentsMutation, { data, loading, error }] = useAddDeferredPaymentsMutation({
 *   variables: {
 *      payments: // value for 'payments'
 *   },
 * });
 */
export function useAddDeferredPaymentsMutation(baseOptions?: Apollo.MutationHookOptions<AddDeferredPaymentsMutation, AddDeferredPaymentsMutationVariables>) {
        return Apollo.useMutation<AddDeferredPaymentsMutation, AddDeferredPaymentsMutationVariables>(AddDeferredPaymentsDocument, baseOptions);
      }
export type AddDeferredPaymentsMutationHookResult = ReturnType<typeof useAddDeferredPaymentsMutation>;
export type AddDeferredPaymentsMutationResult = Apollo.MutationResult<AddDeferredPaymentsMutation>;
export type AddDeferredPaymentsMutationOptions = Apollo.BaseMutationOptions<AddDeferredPaymentsMutation, AddDeferredPaymentsMutationVariables>;
export const CheckPaymentsDebtDocument = gql`
    mutation CheckPaymentsDebt($agreements: [String]!) {
  checkStudentDebt(agreementsInput: $agreements) {
    customErrors {
      code
      field
      message
    }
    success
  }
}
    `;
export type CheckPaymentsDebtMutationFn = Apollo.MutationFunction<CheckPaymentsDebtMutation, CheckPaymentsDebtMutationVariables>;

/**
 * __useCheckPaymentsDebtMutation__
 *
 * To run a mutation, you first call `useCheckPaymentsDebtMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckPaymentsDebtMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkPaymentsDebtMutation, { data, loading, error }] = useCheckPaymentsDebtMutation({
 *   variables: {
 *      agreements: // value for 'agreements'
 *   },
 * });
 */
export function useCheckPaymentsDebtMutation(baseOptions?: Apollo.MutationHookOptions<CheckPaymentsDebtMutation, CheckPaymentsDebtMutationVariables>) {
        return Apollo.useMutation<CheckPaymentsDebtMutation, CheckPaymentsDebtMutationVariables>(CheckPaymentsDebtDocument, baseOptions);
      }
export type CheckPaymentsDebtMutationHookResult = ReturnType<typeof useCheckPaymentsDebtMutation>;
export type CheckPaymentsDebtMutationResult = Apollo.MutationResult<CheckPaymentsDebtMutation>;
export type CheckPaymentsDebtMutationOptions = Apollo.BaseMutationOptions<CheckPaymentsDebtMutation, CheckPaymentsDebtMutationVariables>;
export const RemoveGroupDocument = gql`
    mutation removeGroup($groupId: Int!) {
  deleteGroup(groupId: $groupId) {
    success
    errors
  }
}
    `;
export type RemoveGroupMutationFn = Apollo.MutationFunction<RemoveGroupMutation, RemoveGroupMutationVariables>;

/**
 * __useRemoveGroupMutation__
 *
 * To run a mutation, you first call `useRemoveGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeGroupMutation, { data, loading, error }] = useRemoveGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useRemoveGroupMutation(baseOptions?: Apollo.MutationHookOptions<RemoveGroupMutation, RemoveGroupMutationVariables>) {
        return Apollo.useMutation<RemoveGroupMutation, RemoveGroupMutationVariables>(RemoveGroupDocument, baseOptions);
      }
export type RemoveGroupMutationHookResult = ReturnType<typeof useRemoveGroupMutation>;
export type RemoveGroupMutationResult = Apollo.MutationResult<RemoveGroupMutation>;
export type RemoveGroupMutationOptions = Apollo.BaseMutationOptions<RemoveGroupMutation, RemoveGroupMutationVariables>;
export const PublishToLmsDocument = gql`
    mutation publishToLMS($groupId: ID!, $publishedLms: Boolean!, $publishedForTeachers: Boolean, $publishedLmsForMethodist: Boolean) {
  publishGroup(
    groupId: $groupId
    publishedLms: $publishedLms
    publishedForTeachers: $publishedForTeachers
    publishedLmsForMethodist: $publishedLmsForMethodist
  ) {
    success
    errors
  }
}
    `;
export type PublishToLmsMutationFn = Apollo.MutationFunction<PublishToLmsMutation, PublishToLmsMutationVariables>;

/**
 * __usePublishToLmsMutation__
 *
 * To run a mutation, you first call `usePublishToLmsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishToLmsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishToLmsMutation, { data, loading, error }] = usePublishToLmsMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      publishedLms: // value for 'publishedLms'
 *      publishedForTeachers: // value for 'publishedForTeachers'
 *      publishedLmsForMethodist: // value for 'publishedLmsForMethodist'
 *   },
 * });
 */
export function usePublishToLmsMutation(baseOptions?: Apollo.MutationHookOptions<PublishToLmsMutation, PublishToLmsMutationVariables>) {
        return Apollo.useMutation<PublishToLmsMutation, PublishToLmsMutationVariables>(PublishToLmsDocument, baseOptions);
      }
export type PublishToLmsMutationHookResult = ReturnType<typeof usePublishToLmsMutation>;
export type PublishToLmsMutationResult = Apollo.MutationResult<PublishToLmsMutation>;
export type PublishToLmsMutationOptions = Apollo.BaseMutationOptions<PublishToLmsMutation, PublishToLmsMutationVariables>;
export const AddGroupDocument = gql`
    mutation AddGroup($groupInput: GroupInput!, $moduleInput: [ModuleInput]!) {
  createGroup(groupInput: $groupInput, moduleInput: $moduleInput) {
    success
    errors
    customErrors {
      field
      message
      code
    }
  }
}
    `;
export type AddGroupMutationFn = Apollo.MutationFunction<AddGroupMutation, AddGroupMutationVariables>;

/**
 * __useAddGroupMutation__
 *
 * To run a mutation, you first call `useAddGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGroupMutation, { data, loading, error }] = useAddGroupMutation({
 *   variables: {
 *      groupInput: // value for 'groupInput'
 *      moduleInput: // value for 'moduleInput'
 *   },
 * });
 */
export function useAddGroupMutation(baseOptions?: Apollo.MutationHookOptions<AddGroupMutation, AddGroupMutationVariables>) {
        return Apollo.useMutation<AddGroupMutation, AddGroupMutationVariables>(AddGroupDocument, baseOptions);
      }
export type AddGroupMutationHookResult = ReturnType<typeof useAddGroupMutation>;
export type AddGroupMutationResult = Apollo.MutationResult<AddGroupMutation>;
export type AddGroupMutationOptions = Apollo.BaseMutationOptions<AddGroupMutation, AddGroupMutationVariables>;
export const ChangeGroupDocument = gql`
    mutation changeGroup($groupId: Int!, $groupInput: UpdateGroupInput!) {
  updateGroup(groupId: $groupId, groupInput: $groupInput) {
    success
    errors
    customErrors {
      field
      message
      code
    }
  }
}
    `;
export type ChangeGroupMutationFn = Apollo.MutationFunction<ChangeGroupMutation, ChangeGroupMutationVariables>;

/**
 * __useChangeGroupMutation__
 *
 * To run a mutation, you first call `useChangeGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeGroupMutation, { data, loading, error }] = useChangeGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      groupInput: // value for 'groupInput'
 *   },
 * });
 */
export function useChangeGroupMutation(baseOptions?: Apollo.MutationHookOptions<ChangeGroupMutation, ChangeGroupMutationVariables>) {
        return Apollo.useMutation<ChangeGroupMutation, ChangeGroupMutationVariables>(ChangeGroupDocument, baseOptions);
      }
export type ChangeGroupMutationHookResult = ReturnType<typeof useChangeGroupMutation>;
export type ChangeGroupMutationResult = Apollo.MutationResult<ChangeGroupMutation>;
export type ChangeGroupMutationOptions = Apollo.BaseMutationOptions<ChangeGroupMutation, ChangeGroupMutationVariables>;
export const ChangeAttendanceDocument = gql`
    mutation changeAttendance($attendanceId: Int!, $attendance: Boolean!) {
  updateAttendance(attendanceId: $attendanceId, attendance: $attendance) {
    success
    customErrors {
      field
      message
      code
    }
  }
}
    `;
export type ChangeAttendanceMutationFn = Apollo.MutationFunction<ChangeAttendanceMutation, ChangeAttendanceMutationVariables>;

/**
 * __useChangeAttendanceMutation__
 *
 * To run a mutation, you first call `useChangeAttendanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeAttendanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeAttendanceMutation, { data, loading, error }] = useChangeAttendanceMutation({
 *   variables: {
 *      attendanceId: // value for 'attendanceId'
 *      attendance: // value for 'attendance'
 *   },
 * });
 */
export function useChangeAttendanceMutation(baseOptions?: Apollo.MutationHookOptions<ChangeAttendanceMutation, ChangeAttendanceMutationVariables>) {
        return Apollo.useMutation<ChangeAttendanceMutation, ChangeAttendanceMutationVariables>(ChangeAttendanceDocument, baseOptions);
      }
export type ChangeAttendanceMutationHookResult = ReturnType<typeof useChangeAttendanceMutation>;
export type ChangeAttendanceMutationResult = Apollo.MutationResult<ChangeAttendanceMutation>;
export type ChangeAttendanceMutationOptions = Apollo.BaseMutationOptions<ChangeAttendanceMutation, ChangeAttendanceMutationVariables>;
export const ChangeDateOfLessonDocument = gql`
    mutation changeDateOfLesson($groupId: Int!, $newDate: Date!, $oldDate: Date!) {
  changeLessonDates(groupId: $groupId, newDate: $newDate, oldDate: $oldDate) {
    success
    customErrors {
      field
      message
      code
    }
  }
}
    `;
export type ChangeDateOfLessonMutationFn = Apollo.MutationFunction<ChangeDateOfLessonMutation, ChangeDateOfLessonMutationVariables>;

/**
 * __useChangeDateOfLessonMutation__
 *
 * To run a mutation, you first call `useChangeDateOfLessonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeDateOfLessonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeDateOfLessonMutation, { data, loading, error }] = useChangeDateOfLessonMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      newDate: // value for 'newDate'
 *      oldDate: // value for 'oldDate'
 *   },
 * });
 */
export function useChangeDateOfLessonMutation(baseOptions?: Apollo.MutationHookOptions<ChangeDateOfLessonMutation, ChangeDateOfLessonMutationVariables>) {
        return Apollo.useMutation<ChangeDateOfLessonMutation, ChangeDateOfLessonMutationVariables>(ChangeDateOfLessonDocument, baseOptions);
      }
export type ChangeDateOfLessonMutationHookResult = ReturnType<typeof useChangeDateOfLessonMutation>;
export type ChangeDateOfLessonMutationResult = Apollo.MutationResult<ChangeDateOfLessonMutation>;
export type ChangeDateOfLessonMutationOptions = Apollo.BaseMutationOptions<ChangeDateOfLessonMutation, ChangeDateOfLessonMutationVariables>;
export const ChangeJournalDocument = gql`
    mutation changeJournal($groupId: Int!, $daysOfClass: [DayOfClassInput!]!, $modules: [UpdateModuleInput]!, $comment: String!) {
  updateJournal(
    groupId: $groupId
    daysOfClass: $daysOfClass
    modules: $modules
    comment: $comment
  ) {
    success
    customErrors {
      field
      message
      code
    }
  }
}
    `;
export type ChangeJournalMutationFn = Apollo.MutationFunction<ChangeJournalMutation, ChangeJournalMutationVariables>;

/**
 * __useChangeJournalMutation__
 *
 * To run a mutation, you first call `useChangeJournalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeJournalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeJournalMutation, { data, loading, error }] = useChangeJournalMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      daysOfClass: // value for 'daysOfClass'
 *      modules: // value for 'modules'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useChangeJournalMutation(baseOptions?: Apollo.MutationHookOptions<ChangeJournalMutation, ChangeJournalMutationVariables>) {
        return Apollo.useMutation<ChangeJournalMutation, ChangeJournalMutationVariables>(ChangeJournalDocument, baseOptions);
      }
export type ChangeJournalMutationHookResult = ReturnType<typeof useChangeJournalMutation>;
export type ChangeJournalMutationResult = Apollo.MutationResult<ChangeJournalMutation>;
export type ChangeJournalMutationOptions = Apollo.BaseMutationOptions<ChangeJournalMutation, ChangeJournalMutationVariables>;
export const AddAgreementDocument = gql`
    mutation addAgreement($agreementInput: CreateAgreementInput!) {
  createAgreement(agreementInput: $agreementInput) {
    success
    customErrors {
      field
      message
      code
    }
  }
}
    `;
export type AddAgreementMutationFn = Apollo.MutationFunction<AddAgreementMutation, AddAgreementMutationVariables>;

/**
 * __useAddAgreementMutation__
 *
 * To run a mutation, you first call `useAddAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAgreementMutation, { data, loading, error }] = useAddAgreementMutation({
 *   variables: {
 *      agreementInput: // value for 'agreementInput'
 *   },
 * });
 */
export function useAddAgreementMutation(baseOptions?: Apollo.MutationHookOptions<AddAgreementMutation, AddAgreementMutationVariables>) {
        return Apollo.useMutation<AddAgreementMutation, AddAgreementMutationVariables>(AddAgreementDocument, baseOptions);
      }
export type AddAgreementMutationHookResult = ReturnType<typeof useAddAgreementMutation>;
export type AddAgreementMutationResult = Apollo.MutationResult<AddAgreementMutation>;
export type AddAgreementMutationOptions = Apollo.BaseMutationOptions<AddAgreementMutation, AddAgreementMutationVariables>;
export const EditStudentWithAgreementDocument = gql`
    mutation editStudentWithAgreement($agreementId: ID!, $updateStudentInput: UpdateStudentInput!) {
  updateStudentWithAgreement(
    agreementId: $agreementId
    updateStudentInput: $updateStudentInput
  ) {
    success
    customErrors {
      field
      message
      code
    }
  }
}
    `;
export type EditStudentWithAgreementMutationFn = Apollo.MutationFunction<EditStudentWithAgreementMutation, EditStudentWithAgreementMutationVariables>;

/**
 * __useEditStudentWithAgreementMutation__
 *
 * To run a mutation, you first call `useEditStudentWithAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditStudentWithAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editStudentWithAgreementMutation, { data, loading, error }] = useEditStudentWithAgreementMutation({
 *   variables: {
 *      agreementId: // value for 'agreementId'
 *      updateStudentInput: // value for 'updateStudentInput'
 *   },
 * });
 */
export function useEditStudentWithAgreementMutation(baseOptions?: Apollo.MutationHookOptions<EditStudentWithAgreementMutation, EditStudentWithAgreementMutationVariables>) {
        return Apollo.useMutation<EditStudentWithAgreementMutation, EditStudentWithAgreementMutationVariables>(EditStudentWithAgreementDocument, baseOptions);
      }
export type EditStudentWithAgreementMutationHookResult = ReturnType<typeof useEditStudentWithAgreementMutation>;
export type EditStudentWithAgreementMutationResult = Apollo.MutationResult<EditStudentWithAgreementMutation>;
export type EditStudentWithAgreementMutationOptions = Apollo.BaseMutationOptions<EditStudentWithAgreementMutation, EditStudentWithAgreementMutationVariables>;
export const AddRoleAndAgreementDocument = gql`
    mutation addRoleAndAgreement($agreementInput: CreateAgreementInputBase!, $userId: ID!) {
  appendStudentRoleAndAgreement(agreementInput: $agreementInput, userId: $userId) {
    success
    customErrors {
      field
      message
      code
    }
  }
}
    `;
export type AddRoleAndAgreementMutationFn = Apollo.MutationFunction<AddRoleAndAgreementMutation, AddRoleAndAgreementMutationVariables>;

/**
 * __useAddRoleAndAgreementMutation__
 *
 * To run a mutation, you first call `useAddRoleAndAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRoleAndAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRoleAndAgreementMutation, { data, loading, error }] = useAddRoleAndAgreementMutation({
 *   variables: {
 *      agreementInput: // value for 'agreementInput'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAddRoleAndAgreementMutation(baseOptions?: Apollo.MutationHookOptions<AddRoleAndAgreementMutation, AddRoleAndAgreementMutationVariables>) {
        return Apollo.useMutation<AddRoleAndAgreementMutation, AddRoleAndAgreementMutationVariables>(AddRoleAndAgreementDocument, baseOptions);
      }
export type AddRoleAndAgreementMutationHookResult = ReturnType<typeof useAddRoleAndAgreementMutation>;
export type AddRoleAndAgreementMutationResult = Apollo.MutationResult<AddRoleAndAgreementMutation>;
export type AddRoleAndAgreementMutationOptions = Apollo.BaseMutationOptions<AddRoleAndAgreementMutation, AddRoleAndAgreementMutationVariables>;
export const AddStudentWithAgreementDocument = gql`
    mutation addStudentWithAgreement($agreementInput: CreateAgreementInputBase!, $studentInput: StudentInput!) {
  createStudentWithAgreement(
    agreementInput: $agreementInput
    studentInput: $studentInput
  ) {
    success
    customErrors {
      field
      message
      code
    }
  }
}
    `;
export type AddStudentWithAgreementMutationFn = Apollo.MutationFunction<AddStudentWithAgreementMutation, AddStudentWithAgreementMutationVariables>;

/**
 * __useAddStudentWithAgreementMutation__
 *
 * To run a mutation, you first call `useAddStudentWithAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddStudentWithAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addStudentWithAgreementMutation, { data, loading, error }] = useAddStudentWithAgreementMutation({
 *   variables: {
 *      agreementInput: // value for 'agreementInput'
 *      studentInput: // value for 'studentInput'
 *   },
 * });
 */
export function useAddStudentWithAgreementMutation(baseOptions?: Apollo.MutationHookOptions<AddStudentWithAgreementMutation, AddStudentWithAgreementMutationVariables>) {
        return Apollo.useMutation<AddStudentWithAgreementMutation, AddStudentWithAgreementMutationVariables>(AddStudentWithAgreementDocument, baseOptions);
      }
export type AddStudentWithAgreementMutationHookResult = ReturnType<typeof useAddStudentWithAgreementMutation>;
export type AddStudentWithAgreementMutationResult = Apollo.MutationResult<AddStudentWithAgreementMutation>;
export type AddStudentWithAgreementMutationOptions = Apollo.BaseMutationOptions<AddStudentWithAgreementMutation, AddStudentWithAgreementMutationVariables>;
export const AddUniqueTeacherDocument = gql`
    mutation addUniqueTeacher($application: ApplicationInputType!, $employmentTypes: EmploymentInputType!, $teacher: NewTeacherInput!) {
  createUniqueTeacher(
    application: $application
    employmentTypes: $employmentTypes
    teacher: $teacher
  ) {
    success
    customErrors {
      field
      message
      code
    }
  }
}
    `;
export type AddUniqueTeacherMutationFn = Apollo.MutationFunction<AddUniqueTeacherMutation, AddUniqueTeacherMutationVariables>;

/**
 * __useAddUniqueTeacherMutation__
 *
 * To run a mutation, you first call `useAddUniqueTeacherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUniqueTeacherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUniqueTeacherMutation, { data, loading, error }] = useAddUniqueTeacherMutation({
 *   variables: {
 *      application: // value for 'application'
 *      employmentTypes: // value for 'employmentTypes'
 *      teacher: // value for 'teacher'
 *   },
 * });
 */
export function useAddUniqueTeacherMutation(baseOptions?: Apollo.MutationHookOptions<AddUniqueTeacherMutation, AddUniqueTeacherMutationVariables>) {
        return Apollo.useMutation<AddUniqueTeacherMutation, AddUniqueTeacherMutationVariables>(AddUniqueTeacherDocument, baseOptions);
      }
export type AddUniqueTeacherMutationHookResult = ReturnType<typeof useAddUniqueTeacherMutation>;
export type AddUniqueTeacherMutationResult = Apollo.MutationResult<AddUniqueTeacherMutation>;
export type AddUniqueTeacherMutationOptions = Apollo.BaseMutationOptions<AddUniqueTeacherMutation, AddUniqueTeacherMutationVariables>;
export const AddNewTeacherRoleDocument = gql`
    mutation addNewTeacherRole($application: ApplicationInputType!, $employmentTypes: EmploymentInputType!, $siteDisplay: Boolean, $userId: ID!) {
  addTeacherRole(
    application: $application
    employmentTypes: $employmentTypes
    siteDisplay: $siteDisplay
    userId: $userId
  ) {
    success
    customErrors {
      field
      message
      code
    }
  }
}
    `;
export type AddNewTeacherRoleMutationFn = Apollo.MutationFunction<AddNewTeacherRoleMutation, AddNewTeacherRoleMutationVariables>;

/**
 * __useAddNewTeacherRoleMutation__
 *
 * To run a mutation, you first call `useAddNewTeacherRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNewTeacherRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNewTeacherRoleMutation, { data, loading, error }] = useAddNewTeacherRoleMutation({
 *   variables: {
 *      application: // value for 'application'
 *      employmentTypes: // value for 'employmentTypes'
 *      siteDisplay: // value for 'siteDisplay'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAddNewTeacherRoleMutation(baseOptions?: Apollo.MutationHookOptions<AddNewTeacherRoleMutation, AddNewTeacherRoleMutationVariables>) {
        return Apollo.useMutation<AddNewTeacherRoleMutation, AddNewTeacherRoleMutationVariables>(AddNewTeacherRoleDocument, baseOptions);
      }
export type AddNewTeacherRoleMutationHookResult = ReturnType<typeof useAddNewTeacherRoleMutation>;
export type AddNewTeacherRoleMutationResult = Apollo.MutationResult<AddNewTeacherRoleMutation>;
export type AddNewTeacherRoleMutationOptions = Apollo.BaseMutationOptions<AddNewTeacherRoleMutation, AddNewTeacherRoleMutationVariables>;
export const AddTeacherExistDocument = gql`
    mutation AddTeacherExist($application: ApplicationInputType!, $employmentTypes: ExistEmploymentInputType!, $teacherId: ID!) {
  addTeacherExist(
    application: $application
    employmentTypes: $employmentTypes
    teacherId: $teacherId
  ) {
    success
    customErrors {
      field
      message
      code
    }
  }
}
    `;
export type AddTeacherExistMutationFn = Apollo.MutationFunction<AddTeacherExistMutation, AddTeacherExistMutationVariables>;

/**
 * __useAddTeacherExistMutation__
 *
 * To run a mutation, you first call `useAddTeacherExistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTeacherExistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTeacherExistMutation, { data, loading, error }] = useAddTeacherExistMutation({
 *   variables: {
 *      application: // value for 'application'
 *      employmentTypes: // value for 'employmentTypes'
 *      teacherId: // value for 'teacherId'
 *   },
 * });
 */
export function useAddTeacherExistMutation(baseOptions?: Apollo.MutationHookOptions<AddTeacherExistMutation, AddTeacherExistMutationVariables>) {
        return Apollo.useMutation<AddTeacherExistMutation, AddTeacherExistMutationVariables>(AddTeacherExistDocument, baseOptions);
      }
export type AddTeacherExistMutationHookResult = ReturnType<typeof useAddTeacherExistMutation>;
export type AddTeacherExistMutationResult = Apollo.MutationResult<AddTeacherExistMutation>;
export type AddTeacherExistMutationOptions = Apollo.BaseMutationOptions<AddTeacherExistMutation, AddTeacherExistMutationVariables>;
export const EditTeacherWithTypeDocument = gql`
    mutation editTeacherWithType($employmentInput: ExistEmploymentInputType!, $teacherId: ID, $teacherInput: TeacherWithTypeInput!) {
  updateTeacherWithType(
    teacherId: $teacherId
    employmentInput: $employmentInput
    teacherInput: $teacherInput
  ) {
    success
    errors
    customErrors {
      field
      message
      code
    }
  }
}
    `;
export type EditTeacherWithTypeMutationFn = Apollo.MutationFunction<EditTeacherWithTypeMutation, EditTeacherWithTypeMutationVariables>;

/**
 * __useEditTeacherWithTypeMutation__
 *
 * To run a mutation, you first call `useEditTeacherWithTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTeacherWithTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTeacherWithTypeMutation, { data, loading, error }] = useEditTeacherWithTypeMutation({
 *   variables: {
 *      employmentInput: // value for 'employmentInput'
 *      teacherId: // value for 'teacherId'
 *      teacherInput: // value for 'teacherInput'
 *   },
 * });
 */
export function useEditTeacherWithTypeMutation(baseOptions?: Apollo.MutationHookOptions<EditTeacherWithTypeMutation, EditTeacherWithTypeMutationVariables>) {
        return Apollo.useMutation<EditTeacherWithTypeMutation, EditTeacherWithTypeMutationVariables>(EditTeacherWithTypeDocument, baseOptions);
      }
export type EditTeacherWithTypeMutationHookResult = ReturnType<typeof useEditTeacherWithTypeMutation>;
export type EditTeacherWithTypeMutationResult = Apollo.MutationResult<EditTeacherWithTypeMutation>;
export type EditTeacherWithTypeMutationOptions = Apollo.BaseMutationOptions<EditTeacherWithTypeMutation, EditTeacherWithTypeMutationVariables>;
export const CreateUserDocument = gql`
    mutation createUser($firstName: String!, $lastName: String!, $middleName: String!, $email: String!, $roles: String!, $phone: String!) {
  createUser(
    firstName: $firstName
    lastName: $lastName
    middleName: $middleName
    email: $email
    roles: $roles
    phone: $phone
  ) {
    success
    errors
    customErrors {
      field
      message
      code
    }
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      middleName: // value for 'middleName'
 *      email: // value for 'email'
 *      roles: // value for 'roles'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, baseOptions);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateUserSettingsDocument = gql`
    mutation updateUserSettings($pk: ID!, $firstName: String!, $lastName: String!, $middleName: String!, $email: String!, $roles: String!, $phone: String!) {
  updateUserSettings(
    userId: $pk
    userSettingsInput: {firstName: $firstName, lastName: $lastName, middleName: $middleName, email: $email, roles: $roles, phone: $phone}
  ) {
    user {
      id
    }
    customErrors {
      field
      message
      code
    }
  }
}
    `;
export type UpdateUserSettingsMutationFn = Apollo.MutationFunction<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>;

/**
 * __useUpdateUserSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateUserSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserSettingsMutation, { data, loading, error }] = useUpdateUserSettingsMutation({
 *   variables: {
 *      pk: // value for 'pk'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      middleName: // value for 'middleName'
 *      email: // value for 'email'
 *      roles: // value for 'roles'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useUpdateUserSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>) {
        return Apollo.useMutation<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>(UpdateUserSettingsDocument, baseOptions);
      }
export type UpdateUserSettingsMutationHookResult = ReturnType<typeof useUpdateUserSettingsMutation>;
export type UpdateUserSettingsMutationResult = Apollo.MutationResult<UpdateUserSettingsMutation>;
export type UpdateUserSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser($pk: ID!) {
  deleteUserSettings(userId: $pk) {
    success
    errors
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      pk: // value for 'pk'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, baseOptions);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const AddUserRolesDocument = gql`
    mutation addUserRoles($roles: String!, $userId: ID!) {
  appendRolesUserSettings(roles: $roles, userId: $userId) {
    user {
      id
    }
  }
}
    `;
export type AddUserRolesMutationFn = Apollo.MutationFunction<AddUserRolesMutation, AddUserRolesMutationVariables>;

/**
 * __useAddUserRolesMutation__
 *
 * To run a mutation, you first call `useAddUserRolesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserRolesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserRolesMutation, { data, loading, error }] = useAddUserRolesMutation({
 *   variables: {
 *      roles: // value for 'roles'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAddUserRolesMutation(baseOptions?: Apollo.MutationHookOptions<AddUserRolesMutation, AddUserRolesMutationVariables>) {
        return Apollo.useMutation<AddUserRolesMutation, AddUserRolesMutationVariables>(AddUserRolesDocument, baseOptions);
      }
export type AddUserRolesMutationHookResult = ReturnType<typeof useAddUserRolesMutation>;
export type AddUserRolesMutationResult = Apollo.MutationResult<AddUserRolesMutation>;
export type AddUserRolesMutationOptions = Apollo.BaseMutationOptions<AddUserRolesMutation, AddUserRolesMutationVariables>;
export const PaginatedTeachersApplicationsDocument = gql`
    query PaginatedTeachersApplications($query: String, $before: String, $after: String, $first: Int, $last: Int) {
  allTeachersApplications(
    query: $query
    before: $before
    after: $after
    first: $first
    last: $last
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        id
        title
        lessonsCount
        servicePrice
        currency
        isTerminated
        isCompleted
        appointedLessonsCount
        teacher {
          fullName
        }
        group {
          name
        }
        employmentType {
          __typename
        }
      }
    }
  }
}
    `;

/**
 * __usePaginatedTeachersApplicationsQuery__
 *
 * To run a query within a React component, call `usePaginatedTeachersApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedTeachersApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedTeachersApplicationsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function usePaginatedTeachersApplicationsQuery(baseOptions?: Apollo.QueryHookOptions<PaginatedTeachersApplicationsQuery, PaginatedTeachersApplicationsQueryVariables>) {
        return Apollo.useQuery<PaginatedTeachersApplicationsQuery, PaginatedTeachersApplicationsQueryVariables>(PaginatedTeachersApplicationsDocument, baseOptions);
      }
export function usePaginatedTeachersApplicationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaginatedTeachersApplicationsQuery, PaginatedTeachersApplicationsQueryVariables>) {
          return Apollo.useLazyQuery<PaginatedTeachersApplicationsQuery, PaginatedTeachersApplicationsQueryVariables>(PaginatedTeachersApplicationsDocument, baseOptions);
        }
export type PaginatedTeachersApplicationsQueryHookResult = ReturnType<typeof usePaginatedTeachersApplicationsQuery>;
export type PaginatedTeachersApplicationsLazyQueryHookResult = ReturnType<typeof usePaginatedTeachersApplicationsLazyQuery>;
export type PaginatedTeachersApplicationsQueryResult = Apollo.QueryResult<PaginatedTeachersApplicationsQuery, PaginatedTeachersApplicationsQueryVariables>;
export const GetApplicationsForGroupDocument = gql`
    query getApplicationsForGroup($byGroupId: Decimal, $isTerminated: Boolean, $groupsFilter: Boolean) {
  allTeachersApplications(
    byGroupId: $byGroupId
    isTerminated: $isTerminated
    groupsFilter: $groupsFilter
  ) {
    edges {
      node {
        id
        title
        lessonsCount
        appointedLessonsCount
        teacher {
          fullName
        }
      }
    }
  }
}
    `;

/**
 * __useGetApplicationsForGroupQuery__
 *
 * To run a query within a React component, call `useGetApplicationsForGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationsForGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicationsForGroupQuery({
 *   variables: {
 *      byGroupId: // value for 'byGroupId'
 *      isTerminated: // value for 'isTerminated'
 *      groupsFilter: // value for 'groupsFilter'
 *   },
 * });
 */
export function useGetApplicationsForGroupQuery(baseOptions?: Apollo.QueryHookOptions<GetApplicationsForGroupQuery, GetApplicationsForGroupQueryVariables>) {
        return Apollo.useQuery<GetApplicationsForGroupQuery, GetApplicationsForGroupQueryVariables>(GetApplicationsForGroupDocument, baseOptions);
      }
export function useGetApplicationsForGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApplicationsForGroupQuery, GetApplicationsForGroupQueryVariables>) {
          return Apollo.useLazyQuery<GetApplicationsForGroupQuery, GetApplicationsForGroupQueryVariables>(GetApplicationsForGroupDocument, baseOptions);
        }
export type GetApplicationsForGroupQueryHookResult = ReturnType<typeof useGetApplicationsForGroupQuery>;
export type GetApplicationsForGroupLazyQueryHookResult = ReturnType<typeof useGetApplicationsForGroupLazyQuery>;
export type GetApplicationsForGroupQueryResult = Apollo.QueryResult<GetApplicationsForGroupQuery, GetApplicationsForGroupQueryVariables>;
export const LoadEditingApplicationDocument = gql`
    query loadEditingApplication($applicationId: ID!, $first: Int, $orderBy: String) {
  teacherApplication(applicationId: $applicationId) {
    id
    title
    date
    lessonsCount
    servicePrice
    riaPrice
    currency
    comment
    isTerminated
    lastLessonDate
    isCompleted
    deleteAvailable
    appointedLessonsCount
    country {
      id
      title
    }
    teacher {
      fullName
      employmentTypes {
        __typename
      }
    }
    group {
      id
      name
      freeLessonsCount
      startDate
      course {
        id
        name
      }
    }
    lessons(first: $first, orderBy: $orderBy) {
      edges {
        node {
          date
        }
      }
    }
    employmentType {
      __typename
    }
  }
}
    `;

/**
 * __useLoadEditingApplicationQuery__
 *
 * To run a query within a React component, call `useLoadEditingApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadEditingApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadEditingApplicationQuery({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *      first: // value for 'first'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useLoadEditingApplicationQuery(baseOptions: Apollo.QueryHookOptions<LoadEditingApplicationQuery, LoadEditingApplicationQueryVariables>) {
        return Apollo.useQuery<LoadEditingApplicationQuery, LoadEditingApplicationQueryVariables>(LoadEditingApplicationDocument, baseOptions);
      }
export function useLoadEditingApplicationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoadEditingApplicationQuery, LoadEditingApplicationQueryVariables>) {
          return Apollo.useLazyQuery<LoadEditingApplicationQuery, LoadEditingApplicationQueryVariables>(LoadEditingApplicationDocument, baseOptions);
        }
export type LoadEditingApplicationQueryHookResult = ReturnType<typeof useLoadEditingApplicationQuery>;
export type LoadEditingApplicationLazyQueryHookResult = ReturnType<typeof useLoadEditingApplicationLazyQuery>;
export type LoadEditingApplicationQueryResult = Apollo.QueryResult<LoadEditingApplicationQuery, LoadEditingApplicationQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const GetAllCompaniesDocument = gql`
    query getAllCompanies {
  allCompanies {
    edges {
      node {
        id
        name
        comment
      }
    }
  }
}
    `;

/**
 * __useGetAllCompaniesQuery__
 *
 * To run a query within a React component, call `useGetAllCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllCompaniesQuery, GetAllCompaniesQueryVariables>) {
        return Apollo.useQuery<GetAllCompaniesQuery, GetAllCompaniesQueryVariables>(GetAllCompaniesDocument, baseOptions);
      }
export function useGetAllCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllCompaniesQuery, GetAllCompaniesQueryVariables>) {
          return Apollo.useLazyQuery<GetAllCompaniesQuery, GetAllCompaniesQueryVariables>(GetAllCompaniesDocument, baseOptions);
        }
export type GetAllCompaniesQueryHookResult = ReturnType<typeof useGetAllCompaniesQuery>;
export type GetAllCompaniesLazyQueryHookResult = ReturnType<typeof useGetAllCompaniesLazyQuery>;
export type GetAllCompaniesQueryResult = Apollo.QueryResult<GetAllCompaniesQuery, GetAllCompaniesQueryVariables>;
export const GetCompanyByIdDocument = gql`
    query getCompanyById($id: ID!) {
  company(id: $id) {
    id
    name
    responsiblePerson
    email
    phone
    registrationNumber
    comment
  }
}
    `;

/**
 * __useGetCompanyByIdQuery__
 *
 * To run a query within a React component, call `useGetCompanyByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompanyByIdQuery(baseOptions: Apollo.QueryHookOptions<GetCompanyByIdQuery, GetCompanyByIdQueryVariables>) {
        return Apollo.useQuery<GetCompanyByIdQuery, GetCompanyByIdQueryVariables>(GetCompanyByIdDocument, baseOptions);
      }
export function useGetCompanyByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyByIdQuery, GetCompanyByIdQueryVariables>) {
          return Apollo.useLazyQuery<GetCompanyByIdQuery, GetCompanyByIdQueryVariables>(GetCompanyByIdDocument, baseOptions);
        }
export type GetCompanyByIdQueryHookResult = ReturnType<typeof useGetCompanyByIdQuery>;
export type GetCompanyByIdLazyQueryHookResult = ReturnType<typeof useGetCompanyByIdLazyQuery>;
export type GetCompanyByIdQueryResult = Apollo.QueryResult<GetCompanyByIdQuery, GetCompanyByIdQueryVariables>;
export const GetCountriesForTeachersDocument = gql`
    query GetCountriesForTeachers($before: String, $after: String, $first: Int, $last: Int) {
  allCountries(before: $before, after: $after, first: $first, last: $last) {
    edges {
      node {
        id
        title
      }
    }
  }
}
    `;

/**
 * __useGetCountriesForTeachersQuery__
 *
 * To run a query within a React component, call `useGetCountriesForTeachersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountriesForTeachersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountriesForTeachersQuery({
 *   variables: {
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useGetCountriesForTeachersQuery(baseOptions?: Apollo.QueryHookOptions<GetCountriesForTeachersQuery, GetCountriesForTeachersQueryVariables>) {
        return Apollo.useQuery<GetCountriesForTeachersQuery, GetCountriesForTeachersQueryVariables>(GetCountriesForTeachersDocument, baseOptions);
      }
export function useGetCountriesForTeachersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCountriesForTeachersQuery, GetCountriesForTeachersQueryVariables>) {
          return Apollo.useLazyQuery<GetCountriesForTeachersQuery, GetCountriesForTeachersQueryVariables>(GetCountriesForTeachersDocument, baseOptions);
        }
export type GetCountriesForTeachersQueryHookResult = ReturnType<typeof useGetCountriesForTeachersQuery>;
export type GetCountriesForTeachersLazyQueryHookResult = ReturnType<typeof useGetCountriesForTeachersLazyQuery>;
export type GetCountriesForTeachersQueryResult = Apollo.QueryResult<GetCountriesForTeachersQuery, GetCountriesForTeachersQueryVariables>;
export const PaginatedCoursesDocument = gql`
    query PaginatedCourses($query: String, $first: Int, $after: String) {
  allCourses(query: $query, first: $first, after: $after) {
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node {
        id
        name
        educationForm
        category {
          name
        }
      }
    }
  }
}
    `;

/**
 * __usePaginatedCoursesQuery__
 *
 * To run a query within a React component, call `usePaginatedCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedCoursesQuery({
 *   variables: {
 *      query: // value for 'query'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function usePaginatedCoursesQuery(baseOptions?: Apollo.QueryHookOptions<PaginatedCoursesQuery, PaginatedCoursesQueryVariables>) {
        return Apollo.useQuery<PaginatedCoursesQuery, PaginatedCoursesQueryVariables>(PaginatedCoursesDocument, baseOptions);
      }
export function usePaginatedCoursesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaginatedCoursesQuery, PaginatedCoursesQueryVariables>) {
          return Apollo.useLazyQuery<PaginatedCoursesQuery, PaginatedCoursesQueryVariables>(PaginatedCoursesDocument, baseOptions);
        }
export type PaginatedCoursesQueryHookResult = ReturnType<typeof usePaginatedCoursesQuery>;
export type PaginatedCoursesLazyQueryHookResult = ReturnType<typeof usePaginatedCoursesLazyQuery>;
export type PaginatedCoursesQueryResult = Apollo.QueryResult<PaginatedCoursesQuery, PaginatedCoursesQueryVariables>;
export const GetCoursesForOptionsDocument = gql`
    query GetCoursesForOptions($agreementDate: Date!) {
  allCourses {
    edges {
      node {
        id
        name
        groups(hideCompleted: $agreementDate) {
          edges {
            node {
              id
              name
              price
              currency
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetCoursesForOptionsQuery__
 *
 * To run a query within a React component, call `useGetCoursesForOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoursesForOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoursesForOptionsQuery({
 *   variables: {
 *      agreementDate: // value for 'agreementDate'
 *   },
 * });
 */
export function useGetCoursesForOptionsQuery(baseOptions: Apollo.QueryHookOptions<GetCoursesForOptionsQuery, GetCoursesForOptionsQueryVariables>) {
        return Apollo.useQuery<GetCoursesForOptionsQuery, GetCoursesForOptionsQueryVariables>(GetCoursesForOptionsDocument, baseOptions);
      }
export function useGetCoursesForOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCoursesForOptionsQuery, GetCoursesForOptionsQueryVariables>) {
          return Apollo.useLazyQuery<GetCoursesForOptionsQuery, GetCoursesForOptionsQueryVariables>(GetCoursesForOptionsDocument, baseOptions);
        }
export type GetCoursesForOptionsQueryHookResult = ReturnType<typeof useGetCoursesForOptionsQuery>;
export type GetCoursesForOptionsLazyQueryHookResult = ReturnType<typeof useGetCoursesForOptionsLazyQuery>;
export type GetCoursesForOptionsQueryResult = Apollo.QueryResult<GetCoursesForOptionsQuery, GetCoursesForOptionsQueryVariables>;
export const GetCoursesForTeacherDocument = gql`
    query GetCoursesForTeacher($first: Int, $query: String) {
  allCourses(first: $first, query: $query) {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetCoursesForTeacherQuery__
 *
 * To run a query within a React component, call `useGetCoursesForTeacherQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoursesForTeacherQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoursesForTeacherQuery({
 *   variables: {
 *      first: // value for 'first'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useGetCoursesForTeacherQuery(baseOptions?: Apollo.QueryHookOptions<GetCoursesForTeacherQuery, GetCoursesForTeacherQueryVariables>) {
        return Apollo.useQuery<GetCoursesForTeacherQuery, GetCoursesForTeacherQueryVariables>(GetCoursesForTeacherDocument, baseOptions);
      }
export function useGetCoursesForTeacherLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCoursesForTeacherQuery, GetCoursesForTeacherQueryVariables>) {
          return Apollo.useLazyQuery<GetCoursesForTeacherQuery, GetCoursesForTeacherQueryVariables>(GetCoursesForTeacherDocument, baseOptions);
        }
export type GetCoursesForTeacherQueryHookResult = ReturnType<typeof useGetCoursesForTeacherQuery>;
export type GetCoursesForTeacherLazyQueryHookResult = ReturnType<typeof useGetCoursesForTeacherLazyQuery>;
export type GetCoursesForTeacherQueryResult = Apollo.QueryResult<GetCoursesForTeacherQuery, GetCoursesForTeacherQueryVariables>;
export const GetCourseDataForGroupDocument = gql`
    query getCourseDataForGroup($id: ID!) {
  course(courseId: $id) {
    id
    name
    price
    currency
    teachers {
      edges {
        node {
          id
          fullName
        }
      }
    }
    modules {
      id
      name
      lessons {
        edges {
          node {
            id
            index
            name
            lmsDescription
            taskTypes
            teacherAttachments {
              edges {
                node {
                  id
                }
              }
            }
            studentAttachments {
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetCourseDataForGroupQuery__
 *
 * To run a query within a React component, call `useGetCourseDataForGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseDataForGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseDataForGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCourseDataForGroupQuery(baseOptions: Apollo.QueryHookOptions<GetCourseDataForGroupQuery, GetCourseDataForGroupQueryVariables>) {
        return Apollo.useQuery<GetCourseDataForGroupQuery, GetCourseDataForGroupQueryVariables>(GetCourseDataForGroupDocument, baseOptions);
      }
export function useGetCourseDataForGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCourseDataForGroupQuery, GetCourseDataForGroupQueryVariables>) {
          return Apollo.useLazyQuery<GetCourseDataForGroupQuery, GetCourseDataForGroupQueryVariables>(GetCourseDataForGroupDocument, baseOptions);
        }
export type GetCourseDataForGroupQueryHookResult = ReturnType<typeof useGetCourseDataForGroupQuery>;
export type GetCourseDataForGroupLazyQueryHookResult = ReturnType<typeof useGetCourseDataForGroupLazyQuery>;
export type GetCourseDataForGroupQueryResult = Apollo.QueryResult<GetCourseDataForGroupQuery, GetCourseDataForGroupQueryVariables>;
export const GetCourseDataForApplicationDocument = gql`
    query getCourseDataForApplication($id: ID!, $groupsDate: Date!) {
  course(courseId: $id) {
    groups(hideCompleted: $groupsDate) {
      edges {
        node {
          id
          name
          freeLessonsCount
        }
      }
    }
  }
}
    `;

/**
 * __useGetCourseDataForApplicationQuery__
 *
 * To run a query within a React component, call `useGetCourseDataForApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseDataForApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseDataForApplicationQuery({
 *   variables: {
 *      id: // value for 'id'
 *      groupsDate: // value for 'groupsDate'
 *   },
 * });
 */
export function useGetCourseDataForApplicationQuery(baseOptions: Apollo.QueryHookOptions<GetCourseDataForApplicationQuery, GetCourseDataForApplicationQueryVariables>) {
        return Apollo.useQuery<GetCourseDataForApplicationQuery, GetCourseDataForApplicationQueryVariables>(GetCourseDataForApplicationDocument, baseOptions);
      }
export function useGetCourseDataForApplicationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCourseDataForApplicationQuery, GetCourseDataForApplicationQueryVariables>) {
          return Apollo.useLazyQuery<GetCourseDataForApplicationQuery, GetCourseDataForApplicationQueryVariables>(GetCourseDataForApplicationDocument, baseOptions);
        }
export type GetCourseDataForApplicationQueryHookResult = ReturnType<typeof useGetCourseDataForApplicationQuery>;
export type GetCourseDataForApplicationLazyQueryHookResult = ReturnType<typeof useGetCourseDataForApplicationLazyQuery>;
export type GetCourseDataForApplicationQueryResult = Apollo.QueryResult<GetCourseDataForApplicationQuery, GetCourseDataForApplicationQueryVariables>;
export const PaginatedContributionsDocument = gql`
    query PaginatedContributions($contributionNum: Int, $first: Int, $query: String, $after: String) {
  allContributions(
    first: $first
    contributionNum: $contributionNum
    query: $query
    after: $after
  ) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node {
        id
        date
        complete
        delay
        remainder
        amountByn
        amountForeign
        person {
          firstName
          lastName
          middleName
          phone
        }
        agreementTitle
        agreement {
          currency
        }
        group {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __usePaginatedContributionsQuery__
 *
 * To run a query within a React component, call `usePaginatedContributionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedContributionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedContributionsQuery({
 *   variables: {
 *      contributionNum: // value for 'contributionNum'
 *      first: // value for 'first'
 *      query: // value for 'query'
 *      after: // value for 'after'
 *   },
 * });
 */
export function usePaginatedContributionsQuery(baseOptions?: Apollo.QueryHookOptions<PaginatedContributionsQuery, PaginatedContributionsQueryVariables>) {
        return Apollo.useQuery<PaginatedContributionsQuery, PaginatedContributionsQueryVariables>(PaginatedContributionsDocument, baseOptions);
      }
export function usePaginatedContributionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaginatedContributionsQuery, PaginatedContributionsQueryVariables>) {
          return Apollo.useLazyQuery<PaginatedContributionsQuery, PaginatedContributionsQueryVariables>(PaginatedContributionsDocument, baseOptions);
        }
export type PaginatedContributionsQueryHookResult = ReturnType<typeof usePaginatedContributionsQuery>;
export type PaginatedContributionsLazyQueryHookResult = ReturnType<typeof usePaginatedContributionsLazyQuery>;
export type PaginatedContributionsQueryResult = Apollo.QueryResult<PaginatedContributionsQuery, PaginatedContributionsQueryVariables>;
export const DebtorsMetaDocument = gql`
    query DebtorsMeta($contributionNum: Int, $query: String) {
  metaContributions(contributionNum: $contributionNum, query: $query) {
    totalCount
  }
}
    `;

/**
 * __useDebtorsMetaQuery__
 *
 * To run a query within a React component, call `useDebtorsMetaQuery` and pass it any options that fit your needs.
 * When your component renders, `useDebtorsMetaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDebtorsMetaQuery({
 *   variables: {
 *      contributionNum: // value for 'contributionNum'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useDebtorsMetaQuery(baseOptions?: Apollo.QueryHookOptions<DebtorsMetaQuery, DebtorsMetaQueryVariables>) {
        return Apollo.useQuery<DebtorsMetaQuery, DebtorsMetaQueryVariables>(DebtorsMetaDocument, baseOptions);
      }
export function useDebtorsMetaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DebtorsMetaQuery, DebtorsMetaQueryVariables>) {
          return Apollo.useLazyQuery<DebtorsMetaQuery, DebtorsMetaQueryVariables>(DebtorsMetaDocument, baseOptions);
        }
export type DebtorsMetaQueryHookResult = ReturnType<typeof useDebtorsMetaQuery>;
export type DebtorsMetaLazyQueryHookResult = ReturnType<typeof useDebtorsMetaLazyQuery>;
export type DebtorsMetaQueryResult = Apollo.QueryResult<DebtorsMetaQuery, DebtorsMetaQueryVariables>;
export const GetContributionsMetaDocument = gql`
    query GetContributionsMeta {
  metaContributions {
    contributionsNums
  }
}
    `;

/**
 * __useGetContributionsMetaQuery__
 *
 * To run a query within a React component, call `useGetContributionsMetaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContributionsMetaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContributionsMetaQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetContributionsMetaQuery(baseOptions?: Apollo.QueryHookOptions<GetContributionsMetaQuery, GetContributionsMetaQueryVariables>) {
        return Apollo.useQuery<GetContributionsMetaQuery, GetContributionsMetaQueryVariables>(GetContributionsMetaDocument, baseOptions);
      }
export function useGetContributionsMetaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContributionsMetaQuery, GetContributionsMetaQueryVariables>) {
          return Apollo.useLazyQuery<GetContributionsMetaQuery, GetContributionsMetaQueryVariables>(GetContributionsMetaDocument, baseOptions);
        }
export type GetContributionsMetaQueryHookResult = ReturnType<typeof useGetContributionsMetaQuery>;
export type GetContributionsMetaLazyQueryHookResult = ReturnType<typeof useGetContributionsMetaLazyQuery>;
export type GetContributionsMetaQueryResult = Apollo.QueryResult<GetContributionsMetaQuery, GetContributionsMetaQueryVariables>;
export const PaymentsVerificationDocument = gql`
    query PaymentsVerification($contracts: [String]!) {
  paymentsVerification(query: $contracts) {
    fullname
    agreement_id
    agreementTitle
    currency
  }
}
    `;

/**
 * __usePaymentsVerificationQuery__
 *
 * To run a query within a React component, call `usePaymentsVerificationQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentsVerificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentsVerificationQuery({
 *   variables: {
 *      contracts: // value for 'contracts'
 *   },
 * });
 */
export function usePaymentsVerificationQuery(baseOptions: Apollo.QueryHookOptions<PaymentsVerificationQuery, PaymentsVerificationQueryVariables>) {
        return Apollo.useQuery<PaymentsVerificationQuery, PaymentsVerificationQueryVariables>(PaymentsVerificationDocument, baseOptions);
      }
export function usePaymentsVerificationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentsVerificationQuery, PaymentsVerificationQueryVariables>) {
          return Apollo.useLazyQuery<PaymentsVerificationQuery, PaymentsVerificationQueryVariables>(PaymentsVerificationDocument, baseOptions);
        }
export type PaymentsVerificationQueryHookResult = ReturnType<typeof usePaymentsVerificationQuery>;
export type PaymentsVerificationLazyQueryHookResult = ReturnType<typeof usePaymentsVerificationLazyQuery>;
export type PaymentsVerificationQueryResult = Apollo.QueryResult<PaymentsVerificationQuery, PaymentsVerificationQueryVariables>;
export const PaymentsDeferredDocument = gql`
    query PaymentsDeferred($first: Int, $after: String, $query: String) {
  allDeferredPayments(first: $first, after: $after, query: $query) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        date
        studentFullname
        agreementTitle
        currency
        sum
        userInput
        agreementCurrency
      }
    }
  }
}
    `;

/**
 * __usePaymentsDeferredQuery__
 *
 * To run a query within a React component, call `usePaymentsDeferredQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentsDeferredQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentsDeferredQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      query: // value for 'query'
 *   },
 * });
 */
export function usePaymentsDeferredQuery(baseOptions?: Apollo.QueryHookOptions<PaymentsDeferredQuery, PaymentsDeferredQueryVariables>) {
        return Apollo.useQuery<PaymentsDeferredQuery, PaymentsDeferredQueryVariables>(PaymentsDeferredDocument, baseOptions);
      }
export function usePaymentsDeferredLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentsDeferredQuery, PaymentsDeferredQueryVariables>) {
          return Apollo.useLazyQuery<PaymentsDeferredQuery, PaymentsDeferredQueryVariables>(PaymentsDeferredDocument, baseOptions);
        }
export type PaymentsDeferredQueryHookResult = ReturnType<typeof usePaymentsDeferredQuery>;
export type PaymentsDeferredLazyQueryHookResult = ReturnType<typeof usePaymentsDeferredLazyQuery>;
export type PaymentsDeferredQueryResult = Apollo.QueryResult<PaymentsDeferredQuery, PaymentsDeferredQueryVariables>;
export const PlannedYearContributionsDocument = gql`
    query PlannedYearContributions($year: Int!) {
  financePlan(year: $year) {
    month
    USD
    EUR
    BYN
    RUB
    PLN
  }
  financeBudget(year: $year) {
    month
    USD
    EUR
    BYN
    RUB
    PLN
  }
}
    `;

/**
 * __usePlannedYearContributionsQuery__
 *
 * To run a query within a React component, call `usePlannedYearContributionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlannedYearContributionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlannedYearContributionsQuery({
 *   variables: {
 *      year: // value for 'year'
 *   },
 * });
 */
export function usePlannedYearContributionsQuery(baseOptions: Apollo.QueryHookOptions<PlannedYearContributionsQuery, PlannedYearContributionsQueryVariables>) {
        return Apollo.useQuery<PlannedYearContributionsQuery, PlannedYearContributionsQueryVariables>(PlannedYearContributionsDocument, baseOptions);
      }
export function usePlannedYearContributionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlannedYearContributionsQuery, PlannedYearContributionsQueryVariables>) {
          return Apollo.useLazyQuery<PlannedYearContributionsQuery, PlannedYearContributionsQueryVariables>(PlannedYearContributionsDocument, baseOptions);
        }
export type PlannedYearContributionsQueryHookResult = ReturnType<typeof usePlannedYearContributionsQuery>;
export type PlannedYearContributionsLazyQueryHookResult = ReturnType<typeof usePlannedYearContributionsLazyQuery>;
export type PlannedYearContributionsQueryResult = Apollo.QueryResult<PlannedYearContributionsQuery, PlannedYearContributionsQueryVariables>;
export const PlannedMonthContributionsDocument = gql`
    query PlannedMonthContributions($year: Int!, $month: Int!) {
  financeMonthBudget(year: $year, month: $month) {
    plan {
      week
      USD
      EUR
      BYN
      RUB
      PLN
    }
    budget {
      week
      USD
      EUR
      BYN
      RUB
      PLN
    }
  }
}
    `;

/**
 * __usePlannedMonthContributionsQuery__
 *
 * To run a query within a React component, call `usePlannedMonthContributionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlannedMonthContributionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlannedMonthContributionsQuery({
 *   variables: {
 *      year: // value for 'year'
 *      month: // value for 'month'
 *   },
 * });
 */
export function usePlannedMonthContributionsQuery(baseOptions: Apollo.QueryHookOptions<PlannedMonthContributionsQuery, PlannedMonthContributionsQueryVariables>) {
        return Apollo.useQuery<PlannedMonthContributionsQuery, PlannedMonthContributionsQueryVariables>(PlannedMonthContributionsDocument, baseOptions);
      }
export function usePlannedMonthContributionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlannedMonthContributionsQuery, PlannedMonthContributionsQueryVariables>) {
          return Apollo.useLazyQuery<PlannedMonthContributionsQuery, PlannedMonthContributionsQueryVariables>(PlannedMonthContributionsDocument, baseOptions);
        }
export type PlannedMonthContributionsQueryHookResult = ReturnType<typeof usePlannedMonthContributionsQuery>;
export type PlannedMonthContributionsLazyQueryHookResult = ReturnType<typeof usePlannedMonthContributionsLazyQuery>;
export type PlannedMonthContributionsQueryResult = Apollo.QueryResult<PlannedMonthContributionsQuery, PlannedMonthContributionsQueryVariables>;
export const FinanceYearsDocument = gql`
    query FinanceYears {
  financeYears
}
    `;

/**
 * __useFinanceYearsQuery__
 *
 * To run a query within a React component, call `useFinanceYearsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinanceYearsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinanceYearsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFinanceYearsQuery(baseOptions?: Apollo.QueryHookOptions<FinanceYearsQuery, FinanceYearsQueryVariables>) {
        return Apollo.useQuery<FinanceYearsQuery, FinanceYearsQueryVariables>(FinanceYearsDocument, baseOptions);
      }
export function useFinanceYearsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FinanceYearsQuery, FinanceYearsQueryVariables>) {
          return Apollo.useLazyQuery<FinanceYearsQuery, FinanceYearsQueryVariables>(FinanceYearsDocument, baseOptions);
        }
export type FinanceYearsQueryHookResult = ReturnType<typeof useFinanceYearsQuery>;
export type FinanceYearsLazyQueryHookResult = ReturnType<typeof useFinanceYearsLazyQuery>;
export type FinanceYearsQueryResult = Apollo.QueryResult<FinanceYearsQuery, FinanceYearsQueryVariables>;
export const PaginatedGroupsDocument = gql`
    query PaginatedGroups($query: String, $first: Int, $after: String) {
  allGroups(query: $query, first: $first, after: $after) {
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node {
        id
        name
        startDate
        lessonsCount
        studentsCount
        classesDays {
          weekDay
        }
        publishedLms
        publishedLmsForTeacher
        publishedLmsForMethodist
        price
        currency
        contributionsSum
      }
    }
  }
}
    `;

/**
 * __usePaginatedGroupsQuery__
 *
 * To run a query within a React component, call `usePaginatedGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedGroupsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function usePaginatedGroupsQuery(baseOptions?: Apollo.QueryHookOptions<PaginatedGroupsQuery, PaginatedGroupsQueryVariables>) {
        return Apollo.useQuery<PaginatedGroupsQuery, PaginatedGroupsQueryVariables>(PaginatedGroupsDocument, baseOptions);
      }
export function usePaginatedGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaginatedGroupsQuery, PaginatedGroupsQueryVariables>) {
          return Apollo.useLazyQuery<PaginatedGroupsQuery, PaginatedGroupsQueryVariables>(PaginatedGroupsDocument, baseOptions);
        }
export type PaginatedGroupsQueryHookResult = ReturnType<typeof usePaginatedGroupsQuery>;
export type PaginatedGroupsLazyQueryHookResult = ReturnType<typeof usePaginatedGroupsLazyQuery>;
export type PaginatedGroupsQueryResult = Apollo.QueryResult<PaginatedGroupsQuery, PaginatedGroupsQueryVariables>;
export const LoadEditingGroupDocument = gql`
    query loadEditingGroup($groupId: ID!) {
  group(groupId: $groupId) {
    id
    name
    price
    currency
    startDate
    deleteAvailable
    course {
      id
      name
    }
    classesDays {
      id
      weekDay
      startTime
      endTime
    }
    modules {
      id
      name
      lessons {
        edges {
          node {
            id
            name
            lessonNumber
            additional
            date
            lmsDescription
            teacherApplication {
              id
              teacher {
                fullName
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useLoadEditingGroupQuery__
 *
 * To run a query within a React component, call `useLoadEditingGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadEditingGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadEditingGroupQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useLoadEditingGroupQuery(baseOptions: Apollo.QueryHookOptions<LoadEditingGroupQuery, LoadEditingGroupQueryVariables>) {
        return Apollo.useQuery<LoadEditingGroupQuery, LoadEditingGroupQueryVariables>(LoadEditingGroupDocument, baseOptions);
      }
export function useLoadEditingGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoadEditingGroupQuery, LoadEditingGroupQueryVariables>) {
          return Apollo.useLazyQuery<LoadEditingGroupQuery, LoadEditingGroupQueryVariables>(LoadEditingGroupDocument, baseOptions);
        }
export type LoadEditingGroupQueryHookResult = ReturnType<typeof useLoadEditingGroupQuery>;
export type LoadEditingGroupLazyQueryHookResult = ReturnType<typeof useLoadEditingGroupLazyQuery>;
export type LoadEditingGroupQueryResult = Apollo.QueryResult<LoadEditingGroupQuery, LoadEditingGroupQueryVariables>;
export const PaginatedJournalsDocument = gql`
    query PaginatedJournals($query: String, $first: Int, $after: String) {
  allGroups(query: $query, first: $first, after: $after) {
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node {
        id
        name
        course {
          name
        }
        teachers {
          fullName
        }
      }
    }
  }
}
    `;

/**
 * __usePaginatedJournalsQuery__
 *
 * To run a query within a React component, call `usePaginatedJournalsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedJournalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedJournalsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function usePaginatedJournalsQuery(baseOptions?: Apollo.QueryHookOptions<PaginatedJournalsQuery, PaginatedJournalsQueryVariables>) {
        return Apollo.useQuery<PaginatedJournalsQuery, PaginatedJournalsQueryVariables>(PaginatedJournalsDocument, baseOptions);
      }
export function usePaginatedJournalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaginatedJournalsQuery, PaginatedJournalsQueryVariables>) {
          return Apollo.useLazyQuery<PaginatedJournalsQuery, PaginatedJournalsQueryVariables>(PaginatedJournalsDocument, baseOptions);
        }
export type PaginatedJournalsQueryHookResult = ReturnType<typeof usePaginatedJournalsQuery>;
export type PaginatedJournalsLazyQueryHookResult = ReturnType<typeof usePaginatedJournalsLazyQuery>;
export type PaginatedJournalsQueryResult = Apollo.QueryResult<PaginatedJournalsQuery, PaginatedJournalsQueryVariables>;
export const PaginatedJournalDocument = gql`
    query PaginatedJournal($groupId: ID!) {
  journal(groupId: $groupId) {
    id
    groupName
    comment
    edges {
      node {
        id
        date
        lessonNumber
      }
    }
  }
}
    `;

/**
 * __usePaginatedJournalQuery__
 *
 * To run a query within a React component, call `usePaginatedJournalQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedJournalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedJournalQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function usePaginatedJournalQuery(baseOptions: Apollo.QueryHookOptions<PaginatedJournalQuery, PaginatedJournalQueryVariables>) {
        return Apollo.useQuery<PaginatedJournalQuery, PaginatedJournalQueryVariables>(PaginatedJournalDocument, baseOptions);
      }
export function usePaginatedJournalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaginatedJournalQuery, PaginatedJournalQueryVariables>) {
          return Apollo.useLazyQuery<PaginatedJournalQuery, PaginatedJournalQueryVariables>(PaginatedJournalDocument, baseOptions);
        }
export type PaginatedJournalQueryHookResult = ReturnType<typeof usePaginatedJournalQuery>;
export type PaginatedJournalLazyQueryHookResult = ReturnType<typeof usePaginatedJournalLazyQuery>;
export type PaginatedJournalQueryResult = Apollo.QueryResult<PaginatedJournalQuery, PaginatedJournalQueryVariables>;
export const StudentsForJournalDocument = gql`
    query studentsForJournal($lessonsId: [Int]!, $first: Int, $after: String, $groupId: Int!) {
  studentsForJournal(
    lessonsId: $lessonsId
    first: $first
    after: $after
    groupId: $groupId
  ) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      cursor
      node {
        id
        agreementIsActive
        user {
          id
          firstName
          lastName
          middleName
        }
        attendances {
          edges {
            node {
              id
              attendance
              lesson {
                id
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useStudentsForJournalQuery__
 *
 * To run a query within a React component, call `useStudentsForJournalQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentsForJournalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentsForJournalQuery({
 *   variables: {
 *      lessonsId: // value for 'lessonsId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useStudentsForJournalQuery(baseOptions: Apollo.QueryHookOptions<StudentsForJournalQuery, StudentsForJournalQueryVariables>) {
        return Apollo.useQuery<StudentsForJournalQuery, StudentsForJournalQueryVariables>(StudentsForJournalDocument, baseOptions);
      }
export function useStudentsForJournalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentsForJournalQuery, StudentsForJournalQueryVariables>) {
          return Apollo.useLazyQuery<StudentsForJournalQuery, StudentsForJournalQueryVariables>(StudentsForJournalDocument, baseOptions);
        }
export type StudentsForJournalQueryHookResult = ReturnType<typeof useStudentsForJournalQuery>;
export type StudentsForJournalLazyQueryHookResult = ReturnType<typeof useStudentsForJournalLazyQuery>;
export type StudentsForJournalQueryResult = Apollo.QueryResult<StudentsForJournalQuery, StudentsForJournalQueryVariables>;
export const LoadEditingJournalDocument = gql`
    query loadEditingJournal($groupId: ID!) {
  group(groupId: $groupId) {
    id
    comment
    classesDays {
      id
      weekDay
      startTime
      endTime
    }
    modules {
      id
      name
      lessons {
        edges {
          node {
            id
            name
            lessonNumber
            additional
            date
            lmsDescription
            teacherApplication {
              id
              teacher {
                fullName
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useLoadEditingJournalQuery__
 *
 * To run a query within a React component, call `useLoadEditingJournalQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadEditingJournalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadEditingJournalQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useLoadEditingJournalQuery(baseOptions: Apollo.QueryHookOptions<LoadEditingJournalQuery, LoadEditingJournalQueryVariables>) {
        return Apollo.useQuery<LoadEditingJournalQuery, LoadEditingJournalQueryVariables>(LoadEditingJournalDocument, baseOptions);
      }
export function useLoadEditingJournalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoadEditingJournalQuery, LoadEditingJournalQueryVariables>) {
          return Apollo.useLazyQuery<LoadEditingJournalQuery, LoadEditingJournalQueryVariables>(LoadEditingJournalDocument, baseOptions);
        }
export type LoadEditingJournalQueryHookResult = ReturnType<typeof useLoadEditingJournalQuery>;
export type LoadEditingJournalLazyQueryHookResult = ReturnType<typeof useLoadEditingJournalLazyQuery>;
export type LoadEditingJournalQueryResult = Apollo.QueryResult<LoadEditingJournalQuery, LoadEditingJournalQueryVariables>;
export const PaginatedPerformancesDocument = gql`
    query PaginatedPerformances($query: String, $before: String, $after: String, $first: Int, $last: Int) {
  allStudentsAchievement(
    query: $query
    before: $before
    after: $after
    first: $first
    last: $last
  ) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node {
        id
        student {
          user {
            firstName
            lastName
            middleName
          }
        }
        group {
          id
          name
          course {
            educationForm
          }
        }
        attendancesInfo
        courseWorksAverage
        hwSummary
        graduationProjectMark
      }
    }
  }
}
    `;

/**
 * __usePaginatedPerformancesQuery__
 *
 * To run a query within a React component, call `usePaginatedPerformancesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedPerformancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedPerformancesQuery({
 *   variables: {
 *      query: // value for 'query'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function usePaginatedPerformancesQuery(baseOptions?: Apollo.QueryHookOptions<PaginatedPerformancesQuery, PaginatedPerformancesQueryVariables>) {
        return Apollo.useQuery<PaginatedPerformancesQuery, PaginatedPerformancesQueryVariables>(PaginatedPerformancesDocument, baseOptions);
      }
export function usePaginatedPerformancesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaginatedPerformancesQuery, PaginatedPerformancesQueryVariables>) {
          return Apollo.useLazyQuery<PaginatedPerformancesQuery, PaginatedPerformancesQueryVariables>(PaginatedPerformancesDocument, baseOptions);
        }
export type PaginatedPerformancesQueryHookResult = ReturnType<typeof usePaginatedPerformancesQuery>;
export type PaginatedPerformancesLazyQueryHookResult = ReturnType<typeof usePaginatedPerformancesLazyQuery>;
export type PaginatedPerformancesQueryResult = Apollo.QueryResult<PaginatedPerformancesQuery, PaginatedPerformancesQueryVariables>;
export const SettingsMetaDocument = gql`
    query settingsMeta {
  metaCourses {
    totalCount
  }
  metaGroups {
    totalCount
  }
}
    `;

/**
 * __useSettingsMetaQuery__
 *
 * To run a query within a React component, call `useSettingsMetaQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingsMetaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingsMetaQuery({
 *   variables: {
 *   },
 * });
 */
export function useSettingsMetaQuery(baseOptions?: Apollo.QueryHookOptions<SettingsMetaQuery, SettingsMetaQueryVariables>) {
        return Apollo.useQuery<SettingsMetaQuery, SettingsMetaQueryVariables>(SettingsMetaDocument, baseOptions);
      }
export function useSettingsMetaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SettingsMetaQuery, SettingsMetaQueryVariables>) {
          return Apollo.useLazyQuery<SettingsMetaQuery, SettingsMetaQueryVariables>(SettingsMetaDocument, baseOptions);
        }
export type SettingsMetaQueryHookResult = ReturnType<typeof useSettingsMetaQuery>;
export type SettingsMetaLazyQueryHookResult = ReturnType<typeof useSettingsMetaLazyQuery>;
export type SettingsMetaQueryResult = Apollo.QueryResult<SettingsMetaQuery, SettingsMetaQueryVariables>;
export const GetAppStateDocument = gql`
    query GetAppState {
  appState @client {
    bootstrapped
  }
}
    `;

/**
 * __useGetAppStateQuery__
 *
 * To run a query within a React component, call `useGetAppStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppStateQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAppStateQuery(baseOptions?: Apollo.QueryHookOptions<GetAppStateQuery, GetAppStateQueryVariables>) {
        return Apollo.useQuery<GetAppStateQuery, GetAppStateQueryVariables>(GetAppStateDocument, baseOptions);
      }
export function useGetAppStateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAppStateQuery, GetAppStateQueryVariables>) {
          return Apollo.useLazyQuery<GetAppStateQuery, GetAppStateQueryVariables>(GetAppStateDocument, baseOptions);
        }
export type GetAppStateQueryHookResult = ReturnType<typeof useGetAppStateQuery>;
export type GetAppStateLazyQueryHookResult = ReturnType<typeof useGetAppStateLazyQuery>;
export type GetAppStateQueryResult = Apollo.QueryResult<GetAppStateQuery, GetAppStateQueryVariables>;
export const GetAuthStateDocument = gql`
    query GetAuthState {
  authState @client {
    isLoggedIn
    access_token
  }
}
    `;

/**
 * __useGetAuthStateQuery__
 *
 * To run a query within a React component, call `useGetAuthStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuthStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuthStateQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAuthStateQuery(baseOptions?: Apollo.QueryHookOptions<GetAuthStateQuery, GetAuthStateQueryVariables>) {
        return Apollo.useQuery<GetAuthStateQuery, GetAuthStateQueryVariables>(GetAuthStateDocument, baseOptions);
      }
export function useGetAuthStateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAuthStateQuery, GetAuthStateQueryVariables>) {
          return Apollo.useLazyQuery<GetAuthStateQuery, GetAuthStateQueryVariables>(GetAuthStateDocument, baseOptions);
        }
export type GetAuthStateQueryHookResult = ReturnType<typeof useGetAuthStateQuery>;
export type GetAuthStateLazyQueryHookResult = ReturnType<typeof useGetAuthStateLazyQuery>;
export type GetAuthStateQueryResult = Apollo.QueryResult<GetAuthStateQuery, GetAuthStateQueryVariables>;
export const PaginatedStudentsDocument = gql`
    query PaginatedStudents($query: String, $first: Int, $after: String) {
  allStudents(query: $query, first: $first, after: $after) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node {
        id
        user {
          lastName
          firstName
          middleName
          phone
          email
        }
        agreements {
          id
          agreementTitle
          currency
          budgetAmount
          paidAmount
          terminated
          group {
            id
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __usePaginatedStudentsQuery__
 *
 * To run a query within a React component, call `usePaginatedStudentsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedStudentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedStudentsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function usePaginatedStudentsQuery(baseOptions?: Apollo.QueryHookOptions<PaginatedStudentsQuery, PaginatedStudentsQueryVariables>) {
        return Apollo.useQuery<PaginatedStudentsQuery, PaginatedStudentsQueryVariables>(PaginatedStudentsDocument, baseOptions);
      }
export function usePaginatedStudentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaginatedStudentsQuery, PaginatedStudentsQueryVariables>) {
          return Apollo.useLazyQuery<PaginatedStudentsQuery, PaginatedStudentsQueryVariables>(PaginatedStudentsDocument, baseOptions);
        }
export type PaginatedStudentsQueryHookResult = ReturnType<typeof usePaginatedStudentsQuery>;
export type PaginatedStudentsLazyQueryHookResult = ReturnType<typeof usePaginatedStudentsLazyQuery>;
export type PaginatedStudentsQueryResult = Apollo.QueryResult<PaginatedStudentsQuery, PaginatedStudentsQueryVariables>;
export const LoadAgreementByIdDocument = gql`
    query loadAgreementById($agreementId: ID!) {
  agreement(agreementId: $agreementId) {
    id
    agreementTitle
    currency
    terminated
    paymentByCompany
    vat
    date
    comment
    budgetAmount
    paidAmount
    partialAttendance
    terminatedDate
    firstLesson {
      id
      name
      lessonNumber
    }
    lastLesson {
      id
      name
      date
      lessonNumber
    }
    chargeback {
      id
      amountByn
      amountForeign
      currency
    }
    contributions(orderByDateFilter: true) {
      edges {
        node {
          id
          date
          amountForeign
          amountByn
        }
      }
    }
    promocode
    company {
      id
      name
    }
    country {
      id
      title
    }
    resident
    student {
      id
      user {
        id
        firstName
        middleName
        lastName
        phone
        email
      }
    }
    group {
      id
      name
      startDate
      course {
        id
        name
      }
    }
    startAccountsPayable {
      id
      amountByn
      amountForeign
      repaid
    }
    accountsPayable {
      id
      amountForeign
      currency
      startedAgreement {
        id
        agreementTitle
      }
    }
  }
}
    `;

/**
 * __useLoadAgreementByIdQuery__
 *
 * To run a query within a React component, call `useLoadAgreementByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAgreementByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAgreementByIdQuery({
 *   variables: {
 *      agreementId: // value for 'agreementId'
 *   },
 * });
 */
export function useLoadAgreementByIdQuery(baseOptions: Apollo.QueryHookOptions<LoadAgreementByIdQuery, LoadAgreementByIdQueryVariables>) {
        return Apollo.useQuery<LoadAgreementByIdQuery, LoadAgreementByIdQueryVariables>(LoadAgreementByIdDocument, baseOptions);
      }
export function useLoadAgreementByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoadAgreementByIdQuery, LoadAgreementByIdQueryVariables>) {
          return Apollo.useLazyQuery<LoadAgreementByIdQuery, LoadAgreementByIdQueryVariables>(LoadAgreementByIdDocument, baseOptions);
        }
export type LoadAgreementByIdQueryHookResult = ReturnType<typeof useLoadAgreementByIdQuery>;
export type LoadAgreementByIdLazyQueryHookResult = ReturnType<typeof useLoadAgreementByIdLazyQuery>;
export type LoadAgreementByIdQueryResult = Apollo.QueryResult<LoadAgreementByIdQuery, LoadAgreementByIdQueryVariables>;
export const MetaStudentsDocument = gql`
    query MetaStudents($query: String) {
  metaStudents(query: $query) {
    totalCount
    agreementsCount
  }
}
    `;

/**
 * __useMetaStudentsQuery__
 *
 * To run a query within a React component, call `useMetaStudentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMetaStudentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMetaStudentsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useMetaStudentsQuery(baseOptions?: Apollo.QueryHookOptions<MetaStudentsQuery, MetaStudentsQueryVariables>) {
        return Apollo.useQuery<MetaStudentsQuery, MetaStudentsQueryVariables>(MetaStudentsDocument, baseOptions);
      }
export function useMetaStudentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MetaStudentsQuery, MetaStudentsQueryVariables>) {
          return Apollo.useLazyQuery<MetaStudentsQuery, MetaStudentsQueryVariables>(MetaStudentsDocument, baseOptions);
        }
export type MetaStudentsQueryHookResult = ReturnType<typeof useMetaStudentsQuery>;
export type MetaStudentsLazyQueryHookResult = ReturnType<typeof useMetaStudentsLazyQuery>;
export type MetaStudentsQueryResult = Apollo.QueryResult<MetaStudentsQuery, MetaStudentsQueryVariables>;
export const CheckUserExistDocument = gql`
    query CheckUserExist($email: String!, $phone: String!) {
  verifyStudent(email: $email, phone: $phone) {
    student {
      id
      user {
        id
        firstName
        lastName
        middleName
        phone
        email
        roles
      }
    }
    user {
      id
      firstName
      lastName
      middleName
      phone
      email
      roles
    }
    customErrors {
      field
      message
      code
    }
    message
    code
  }
}
    `;

/**
 * __useCheckUserExistQuery__
 *
 * To run a query within a React component, call `useCheckUserExistQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckUserExistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckUserExistQuery({
 *   variables: {
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useCheckUserExistQuery(baseOptions: Apollo.QueryHookOptions<CheckUserExistQuery, CheckUserExistQueryVariables>) {
        return Apollo.useQuery<CheckUserExistQuery, CheckUserExistQueryVariables>(CheckUserExistDocument, baseOptions);
      }
export function useCheckUserExistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckUserExistQuery, CheckUserExistQueryVariables>) {
          return Apollo.useLazyQuery<CheckUserExistQuery, CheckUserExistQueryVariables>(CheckUserExistDocument, baseOptions);
        }
export type CheckUserExistQueryHookResult = ReturnType<typeof useCheckUserExistQuery>;
export type CheckUserExistLazyQueryHookResult = ReturnType<typeof useCheckUserExistLazyQuery>;
export type CheckUserExistQueryResult = Apollo.QueryResult<CheckUserExistQuery, CheckUserExistQueryVariables>;
export const LoadLessonsOptionsDocument = gql`
    query loadLessonsOptions($groupId: ID!) {
  group(groupId: $groupId) {
    id
    lessons {
      id
      name
      date
      lessonNumber
    }
  }
}
    `;

/**
 * __useLoadLessonsOptionsQuery__
 *
 * To run a query within a React component, call `useLoadLessonsOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadLessonsOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadLessonsOptionsQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useLoadLessonsOptionsQuery(baseOptions: Apollo.QueryHookOptions<LoadLessonsOptionsQuery, LoadLessonsOptionsQueryVariables>) {
        return Apollo.useQuery<LoadLessonsOptionsQuery, LoadLessonsOptionsQueryVariables>(LoadLessonsOptionsDocument, baseOptions);
      }
export function useLoadLessonsOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoadLessonsOptionsQuery, LoadLessonsOptionsQueryVariables>) {
          return Apollo.useLazyQuery<LoadLessonsOptionsQuery, LoadLessonsOptionsQueryVariables>(LoadLessonsOptionsDocument, baseOptions);
        }
export type LoadLessonsOptionsQueryHookResult = ReturnType<typeof useLoadLessonsOptionsQuery>;
export type LoadLessonsOptionsLazyQueryHookResult = ReturnType<typeof useLoadLessonsOptionsLazyQuery>;
export type LoadLessonsOptionsQueryResult = Apollo.QueryResult<LoadLessonsOptionsQuery, LoadLessonsOptionsQueryVariables>;
export const AccountsPayablesDocument = gql`
    query accountsPayables($id: ID!) {
  student(id: $id) {
    id
    accountsPayables {
      id
      amountForeign
      currency
      repaid
      startedAgreement {
        id
        agreementTitle
      }
    }
  }
}
    `;

/**
 * __useAccountsPayablesQuery__
 *
 * To run a query within a React component, call `useAccountsPayablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountsPayablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountsPayablesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAccountsPayablesQuery(baseOptions: Apollo.QueryHookOptions<AccountsPayablesQuery, AccountsPayablesQueryVariables>) {
        return Apollo.useQuery<AccountsPayablesQuery, AccountsPayablesQueryVariables>(AccountsPayablesDocument, baseOptions);
      }
export function useAccountsPayablesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountsPayablesQuery, AccountsPayablesQueryVariables>) {
          return Apollo.useLazyQuery<AccountsPayablesQuery, AccountsPayablesQueryVariables>(AccountsPayablesDocument, baseOptions);
        }
export type AccountsPayablesQueryHookResult = ReturnType<typeof useAccountsPayablesQuery>;
export type AccountsPayablesLazyQueryHookResult = ReturnType<typeof useAccountsPayablesLazyQuery>;
export type AccountsPayablesQueryResult = Apollo.QueryResult<AccountsPayablesQuery, AccountsPayablesQueryVariables>;
export const AllTeachersDocument = gql`
    query allTeachers($query: String, $before: String, $after: String, $first: Int, $last: Int) {
  allTeachers(
    query: $query
    before: $before
    after: $after
    first: $first
    last: $last
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        id
        accessToLms
        fullName
        isDismissed
        user {
          phone
          email
        }
        courses
      }
    }
  }
}
    `;

/**
 * __useAllTeachersQuery__
 *
 * To run a query within a React component, call `useAllTeachersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTeachersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTeachersQuery({
 *   variables: {
 *      query: // value for 'query'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useAllTeachersQuery(baseOptions?: Apollo.QueryHookOptions<AllTeachersQuery, AllTeachersQueryVariables>) {
        return Apollo.useQuery<AllTeachersQuery, AllTeachersQueryVariables>(AllTeachersDocument, baseOptions);
      }
export function useAllTeachersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllTeachersQuery, AllTeachersQueryVariables>) {
          return Apollo.useLazyQuery<AllTeachersQuery, AllTeachersQueryVariables>(AllTeachersDocument, baseOptions);
        }
export type AllTeachersQueryHookResult = ReturnType<typeof useAllTeachersQuery>;
export type AllTeachersLazyQueryHookResult = ReturnType<typeof useAllTeachersLazyQuery>;
export type AllTeachersQueryResult = Apollo.QueryResult<AllTeachersQuery, AllTeachersQueryVariables>;
export const VerifyTeacherDocument = gql`
    query verifyTeacher($firstName: String!, $middleName: String, $lastName: String!, $phone: String!, $email: String!) {
  verifyTeacher(
    teacherInput: {firstName: $firstName, middleName: $middleName, lastName: $lastName, phone: $phone, email: $email}
  ) {
    code
    customErrors {
      field
      message
      code
    }
    user {
      id
      firstName
      middleName
      lastName
      phone
      email
      roles
    }
    teacher {
      id
      user {
        firstName
        middleName
        lastName
        phone
        email
      }
    }
  }
}
    `;

/**
 * __useVerifyTeacherQuery__
 *
 * To run a query within a React component, call `useVerifyTeacherQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyTeacherQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyTeacherQuery({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      middleName: // value for 'middleName'
 *      lastName: // value for 'lastName'
 *      phone: // value for 'phone'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useVerifyTeacherQuery(baseOptions: Apollo.QueryHookOptions<VerifyTeacherQuery, VerifyTeacherQueryVariables>) {
        return Apollo.useQuery<VerifyTeacherQuery, VerifyTeacherQueryVariables>(VerifyTeacherDocument, baseOptions);
      }
export function useVerifyTeacherLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VerifyTeacherQuery, VerifyTeacherQueryVariables>) {
          return Apollo.useLazyQuery<VerifyTeacherQuery, VerifyTeacherQueryVariables>(VerifyTeacherDocument, baseOptions);
        }
export type VerifyTeacherQueryHookResult = ReturnType<typeof useVerifyTeacherQuery>;
export type VerifyTeacherLazyQueryHookResult = ReturnType<typeof useVerifyTeacherLazyQuery>;
export type VerifyTeacherQueryResult = Apollo.QueryResult<VerifyTeacherQuery, VerifyTeacherQueryVariables>;
export const GetTeacherDataDocument = gql`
    query getTeacherData($teacherId: ID) {
  teacher(teacherId: $teacherId) {
    employmentTypes {
      __typename
    }
  }
}
    `;

/**
 * __useGetTeacherDataQuery__
 *
 * To run a query within a React component, call `useGetTeacherDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeacherDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeacherDataQuery({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *   },
 * });
 */
export function useGetTeacherDataQuery(baseOptions?: Apollo.QueryHookOptions<GetTeacherDataQuery, GetTeacherDataQueryVariables>) {
        return Apollo.useQuery<GetTeacherDataQuery, GetTeacherDataQueryVariables>(GetTeacherDataDocument, baseOptions);
      }
export function useGetTeacherDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeacherDataQuery, GetTeacherDataQueryVariables>) {
          return Apollo.useLazyQuery<GetTeacherDataQuery, GetTeacherDataQueryVariables>(GetTeacherDataDocument, baseOptions);
        }
export type GetTeacherDataQueryHookResult = ReturnType<typeof useGetTeacherDataQuery>;
export type GetTeacherDataLazyQueryHookResult = ReturnType<typeof useGetTeacherDataLazyQuery>;
export type GetTeacherDataQueryResult = Apollo.QueryResult<GetTeacherDataQuery, GetTeacherDataQueryVariables>;
export const GetTeacherTypesDocument = gql`
    query GetTeacherTypes($teacherId: ID!) {
  teacher(teacherId: $teacherId) {
    employmentTypes {
      ... on IndividualEntrepreneurType {
        __typename
        id
        registrationAddress
        accountNumber
        bankTitle
        bic
        inn
      }
      ... on LegalEntityType {
        __typename
        id
        registrationAddress
        accountNumber
        bankTitle
        bic
        tittle
        inn
      }
      ... on SelfEmployedType {
        __typename
        id
        registrationAddress
        accountNumber
        bankTitle
        bic
        nationality {
          id
          title
        }
        passportNumber
        inn
      }
      ... on NaturalPersonType {
        __typename
        id
        registrationAddress
        accountNumber
        bankTitle
        bic
        nationality {
          id
          title
        }
        passportNumber
      }
    }
  }
}
    `;

/**
 * __useGetTeacherTypesQuery__
 *
 * To run a query within a React component, call `useGetTeacherTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeacherTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeacherTypesQuery({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *   },
 * });
 */
export function useGetTeacherTypesQuery(baseOptions: Apollo.QueryHookOptions<GetTeacherTypesQuery, GetTeacherTypesQueryVariables>) {
        return Apollo.useQuery<GetTeacherTypesQuery, GetTeacherTypesQueryVariables>(GetTeacherTypesDocument, baseOptions);
      }
export function useGetTeacherTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeacherTypesQuery, GetTeacherTypesQueryVariables>) {
          return Apollo.useLazyQuery<GetTeacherTypesQuery, GetTeacherTypesQueryVariables>(GetTeacherTypesDocument, baseOptions);
        }
export type GetTeacherTypesQueryHookResult = ReturnType<typeof useGetTeacherTypesQuery>;
export type GetTeacherTypesLazyQueryHookResult = ReturnType<typeof useGetTeacherTypesLazyQuery>;
export type GetTeacherTypesQueryResult = Apollo.QueryResult<GetTeacherTypesQuery, GetTeacherTypesQueryVariables>;
export const GetEditTeacherDocument = gql`
    query GetEditTeacher($teacherId: ID!) {
  teacher(teacherId: $teacherId) {
    id
    user {
      firstName
      lastName
      middleName
      email
      phone
    }
    accessToLms
    siteDisplay
    employmentTypes {
      ... on IndividualEntrepreneurType {
        __typename
        id
        registrationAddress
        accountNumber
        bankTitle
        bic
        inn
      }
      ... on LegalEntityType {
        __typename
        id
        registrationAddress
        accountNumber
        bankTitle
        bic
        tittle
        inn
      }
      ... on SelfEmployedType {
        __typename
        id
        registrationAddress
        accountNumber
        bankTitle
        bic
        nationality {
          id
          title
        }
        passportNumber
        inn
      }
      ... on NaturalPersonType {
        __typename
        id
        registrationAddress
        accountNumber
        bankTitle
        bic
        nationality {
          id
          title
        }
        passportNumber
      }
    }
  }
}
    `;

/**
 * __useGetEditTeacherQuery__
 *
 * To run a query within a React component, call `useGetEditTeacherQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEditTeacherQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEditTeacherQuery({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *   },
 * });
 */
export function useGetEditTeacherQuery(baseOptions: Apollo.QueryHookOptions<GetEditTeacherQuery, GetEditTeacherQueryVariables>) {
        return Apollo.useQuery<GetEditTeacherQuery, GetEditTeacherQueryVariables>(GetEditTeacherDocument, baseOptions);
      }
export function useGetEditTeacherLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEditTeacherQuery, GetEditTeacherQueryVariables>) {
          return Apollo.useLazyQuery<GetEditTeacherQuery, GetEditTeacherQueryVariables>(GetEditTeacherDocument, baseOptions);
        }
export type GetEditTeacherQueryHookResult = ReturnType<typeof useGetEditTeacherQuery>;
export type GetEditTeacherLazyQueryHookResult = ReturnType<typeof useGetEditTeacherLazyQuery>;
export type GetEditTeacherQueryResult = Apollo.QueryResult<GetEditTeacherQuery, GetEditTeacherQueryVariables>;
export const TeachersMetaDocument = gql`
    query teachersMeta($query: String) {
  metaTeachers(query: $query) {
    totalCount
    applicationsCount
  }
}
    `;

/**
 * __useTeachersMetaQuery__
 *
 * To run a query within a React component, call `useTeachersMetaQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeachersMetaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeachersMetaQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useTeachersMetaQuery(baseOptions?: Apollo.QueryHookOptions<TeachersMetaQuery, TeachersMetaQueryVariables>) {
        return Apollo.useQuery<TeachersMetaQuery, TeachersMetaQueryVariables>(TeachersMetaDocument, baseOptions);
      }
export function useTeachersMetaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeachersMetaQuery, TeachersMetaQueryVariables>) {
          return Apollo.useLazyQuery<TeachersMetaQuery, TeachersMetaQueryVariables>(TeachersMetaDocument, baseOptions);
        }
export type TeachersMetaQueryHookResult = ReturnType<typeof useTeachersMetaQuery>;
export type TeachersMetaLazyQueryHookResult = ReturnType<typeof useTeachersMetaLazyQuery>;
export type TeachersMetaQueryResult = Apollo.QueryResult<TeachersMetaQuery, TeachersMetaQueryVariables>;
export const GetUserByIdDocument = gql`
    query getUserById($userId: ID!) {
  user(id: $userId) {
    pk
    firstName
    middleName
    lastName
    email
    phone
    roles
  }
}
    `;

/**
 * __useGetUserByIdQuery__
 *
 * To run a query within a React component, call `useGetUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserByIdQuery(baseOptions: Apollo.QueryHookOptions<GetUserByIdQuery, GetUserByIdQueryVariables>) {
        return Apollo.useQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(GetUserByIdDocument, baseOptions);
      }
export function useGetUserByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserByIdQuery, GetUserByIdQueryVariables>) {
          return Apollo.useLazyQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(GetUserByIdDocument, baseOptions);
        }
export type GetUserByIdQueryHookResult = ReturnType<typeof useGetUserByIdQuery>;
export type GetUserByIdLazyQueryHookResult = ReturnType<typeof useGetUserByIdLazyQuery>;
export type GetUserByIdQueryResult = Apollo.QueryResult<GetUserByIdQuery, GetUserByIdQueryVariables>;
export const FindUserDataDocument = gql`
    query findUserData($email: String, $phone: String) {
  users(email_Icontains: $email, phone: $phone) {
    edges {
      node {
        pk
        roles
        email
        phone
        firstName
        lastName
        middleName
        student {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useFindUserDataQuery__
 *
 * To run a query within a React component, call `useFindUserDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUserDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUserDataQuery({
 *   variables: {
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useFindUserDataQuery(baseOptions?: Apollo.QueryHookOptions<FindUserDataQuery, FindUserDataQueryVariables>) {
        return Apollo.useQuery<FindUserDataQuery, FindUserDataQueryVariables>(FindUserDataDocument, baseOptions);
      }
export function useFindUserDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindUserDataQuery, FindUserDataQueryVariables>) {
          return Apollo.useLazyQuery<FindUserDataQuery, FindUserDataQueryVariables>(FindUserDataDocument, baseOptions);
        }
export type FindUserDataQueryHookResult = ReturnType<typeof useFindUserDataQuery>;
export type FindUserDataLazyQueryHookResult = ReturnType<typeof useFindUserDataLazyQuery>;
export type FindUserDataQueryResult = Apollo.QueryResult<FindUserDataQuery, FindUserDataQueryVariables>;
export const PaginatedUsersSettingsDocument = gql`
    query PaginatedUsersSettings($query: String, $first: Int, $after: String) {
  allUsersSettings(first: $first, query: $query, after: $after) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node {
        ...UserFields
      }
    }
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __usePaginatedUsersSettingsQuery__
 *
 * To run a query within a React component, call `usePaginatedUsersSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedUsersSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedUsersSettingsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function usePaginatedUsersSettingsQuery(baseOptions?: Apollo.QueryHookOptions<PaginatedUsersSettingsQuery, PaginatedUsersSettingsQueryVariables>) {
        return Apollo.useQuery<PaginatedUsersSettingsQuery, PaginatedUsersSettingsQueryVariables>(PaginatedUsersSettingsDocument, baseOptions);
      }
export function usePaginatedUsersSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaginatedUsersSettingsQuery, PaginatedUsersSettingsQueryVariables>) {
          return Apollo.useLazyQuery<PaginatedUsersSettingsQuery, PaginatedUsersSettingsQueryVariables>(PaginatedUsersSettingsDocument, baseOptions);
        }
export type PaginatedUsersSettingsQueryHookResult = ReturnType<typeof usePaginatedUsersSettingsQuery>;
export type PaginatedUsersSettingsLazyQueryHookResult = ReturnType<typeof usePaginatedUsersSettingsLazyQuery>;
export type PaginatedUsersSettingsQueryResult = Apollo.QueryResult<PaginatedUsersSettingsQuery, PaginatedUsersSettingsQueryVariables>;