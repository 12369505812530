import React from 'react';

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { ErrorBoundary } from '@sentry/react';

import { client } from 'core/graphql/client';

import { ConfigProvider } from 'antd';
import locale from 'antd/es/locale/ru_RU';
import localePL from 'antd/es/locale/pl_PL';

import moment from 'moment';

import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';

import './i18n';

import 'moment/locale/ru';
import 'moment/locale/pl';

moment.locale(process.env.REACT_APP_LANG || 'ru');

if (process.env.REACT_APP_THEME !== 'HACKERU') {
  Sentry.init({
    dsn:
      process.env.SENTRY_DSN ||
      'https://96cc8251550145c6a919b90098669dc2@o1081788.ingest.sentry.io/6168870',
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: process.env.SENTRY_ENVIRONMENT || 'development',
  });
}
if (process.env.REACT_APP_THEME === 'HACKERU') {
  Sentry.init({
    dsn:
      'https://e5416de83211b48c8a1af6d88ee86e04@o4507724167249920.ingest.de.sentry.io/4507724170592336',
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: process.env.SENTRY_ENVIRONMENT || 'development',
  });
}

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <ErrorBoundary>
        <BrowserRouter>
          <ConfigProvider
            locale={process.env.REACT_APP_LANG === 'pl' ? localePL : locale}
          >
            <App />
          </ConfigProvider>
        </BrowserRouter>
      </ErrorBoundary>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
